import { Theme } from '@mui/material';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { createStyles } from '@mui/styles';
import { dyeColors, ModeOptions } from '~styles';
import { PageHeaderProps } from './types';

export const styles = ({ breakpoints, constructs, palette, spacing, typography, mode }: Theme) =>
	createStyles({
		children: ({ left }: Partial<PageHeaderProps>) => {
			return {
				color: ifFeature('encore', constructs.text.neutral.strong),
				gridColumn: '1 / 3',
				gridRow: '4 / 5',
				marginBottom: ifFeature('encore', spacing(2.5)),
				marginTop: ifFeature('encore', left ? spacing(2.5) : spacing(1)),

				/* Mobile Styles for Small Screens */
				[breakpoints.down('sm')]: ifFeature('encore', {
					marginBottom: spacing(2),
					marginTop: 0,
				}),
			};
		},
		icon: {
			alignItems: 'center',
			display: 'flex',
			fill: palette.primary.main,
			lineHeight: 0,
			marginRight: spacing(1),
			maxHeight: 36,
		},
		left: ({ children }) => {
			return {
				alignSelf: 'end',
				gridColumn: '1 / 3',
				gridRow: '3 / 4',
				marginBottom: ifFeature('encore', children ? 0 : spacing(3)),
				marginTop: ifFeature('encore', 0),
				whiteSpace: 'nowrap',

				/* Mobile Styles for Small Screens */
				[breakpoints.down('sm')]: ifFeature('encore', {
					marginBottom: spacing(2),
					marginTop: 0,
				}),
			};
		},
		right: ({ children, left }) => {
			return {
				alignSelf: ifFeature('encore', 'start', 'center'),
				gridColumn: '3 / 4',
				gridRow: children || left ? ifFeature('encore', '2/3', '3 / 4') : '2 / 3',
				marginTop: ifFeature('encore', spacing(1.5), undefined),
				whiteSpace: 'nowrap',

				/* Mobile Styles for Small Screens */
				[breakpoints.down('sm')]: ifFeature('encore', {
					gridColumn: '1',
					gridRow: 'auto',
					marginBottom: spacing(2),
					marginTop: 0,
				}),
			};
		},
		/*
		TODO: Currently, PageHeader has a margin by default when there is no divider and PageHeader has no margin by default when there is a divider.
		This should be fixed. The margin should always be there regardless of the divider, but to not break the current behavior in encore, we will set
		the noMargin prop based on the divider. In the future, noMargin should always be set to false by default, but that will require us to update all 
		the uses of PageHeader that have a divider in other repos to set noMargin to true.
		*/
		root: ({ divider = true, noMargin = divider }) => {
			const dividerSpacing = divider ? spacing(3) : spacing(4);
			const mobileDividerSpacing = divider ? spacing(2) : spacing(1);

			return {
				/* @startCleanup encore */
				backgroundColor: ifFeature('encore', undefined, palette.common.white),
				/* @endCleanup encore */
				borderBottom: divider
					? ifFeature('encore', `1px solid ${constructs.border.neutral.xWeak}`, `1px solid ${palette.gray[400]}`)
					: undefined,
				boxSizing: 'border-box',
				display: 'grid',
				gridTemplateColumns: 'min-content 1fr min-content',
				gridTemplateRows: 'min-content 1fr min-content min-content',
				marginBottom: ifFeature('encore', noMargin ? spacing(0) : dividerSpacing), // This is the margin after the content
				/* @startCleanup encore */
				minHeight: ifFeature('encore', undefined, 89),
				/* @endCleanup encore */
				outline: mode === ModeOptions.Dye ? `2px solid ${dyeColors.main}` : undefined,
				/* @startCleanup encore */
				paddingBottom: ifFeature('encore', undefined, spacing(2)),
				paddingTop: ifFeature('encore', undefined, spacing(1)),
				/* @endCleanup encore */
				/*
				 * This reset is necessary to prevent the inherited alignment from other repositories from
				 * taking over and positioning grid items incorrectly (looking at you `.BhrPage-wrap` 😡).
				 */
				textAlign: 'left',

				/* Mobile Styles for Small Screens */
				[breakpoints.down('sm')]: {
					gridTemplateColumns: '1fr',
					gridTemplateRows: '1fr',
					marginBottom: ifFeature('encore', noMargin ? spacing(0) : mobileDividerSpacing),
				},
			};
		},
		subtitle: {
			alignSelf: 'flex-end',
			color: ifFeature('encore', constructs.text.neutral.weak, palette.gray[700]),
			fontSize: typography.fabricFontSize('biggie'),
			fontWeight: typography.fontWeightMedium,
			lineHeight: '28px',
			marginLeft: ifFeature('encore', undefined, spacing(1)),
			textWrap: ifFeature('encore', 'wrap', undefined),
			whiteSpace: ifFeature('encore', 'normal'),
		},
		title: {
			alignSelf: ifFeature('encore', 'center', 'flex-end'),
			color: palette.primary.main,
			margin: 0,
			overflow: ifFeature('encore', undefined, 'hidden'),
			textOverflow: ifFeature('encore', undefined, 'ellipsis'),
			textWrap: ifFeature('encore', 'nowrap', undefined),
			whiteSpace: ifFeature('encore', 'normal', 'nowrap'),
		},
		titlesInner: {
			alignItems: ifFeature('encore', 'stretch', 'center'),
			display: 'flex',
		},
		titlesOuter: ({ back, children, divider = true, left, noMargin = divider, right }: Partial<PageHeaderProps>) => {
			let marginBottom = ifFeature('encore', divider ? spacing(4) : spacing(0), 3); // This is the margin between the title and the divider
			let marginTop = 0;

			if (children || left) {
				marginBottom = ifFeature('encore', spacing(1.5), back ? 18 : 28);
				marginTop = back ? 0 : 21;
			}

			return {
				alignSelf: 'end',
				flex: 1,
				gridColumn: left ? ifFeature('encore', '1 / 3', '1 / 4') : '1 / 3',
				gridRow: '2 / 3',
				lineHeight: 36,
				marginBottom,
				marginRight: ifFeature('encore', spacing(4), 36),
				marginTop,
				minWidth: 0,
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',

				/* Mobile Styles for Small Screens */
				[breakpoints.down('sm')]: {
					marginBottom: ifFeature('encore', noMargin && !left && !right && !children ? spacing(0) : spacing(2)),
				},
			};
		},
	});
