import { ifFeature } from '@bamboohr/utils/lib/feature';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ palette, spacing, typography }) => ({
	root: {
		alignItems: ifFeature('encore', 'flex-start', 'center'),
		height: '100%',
		padding: ifFeature('encore', `${spacing(2.5)} ${spacing(2.5)}`, `${spacing(0.5)} ${spacing(2.5)}`),
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		position: 'relative',
		width: '100%',
		boxSizing: 'border-box',
		'&:hover, &:focus-within': {
			'& $actionContainer': {
				opacity: 1,
				pointerEvents: 'auto',
			},
		},
	},
	iconContainer: {
		alignItems: 'flex-end',
		display: 'flex',
		height: ifFeature('encore', undefined, 54),
		justifyContent: 'center',
		paddingBottom: ifFeature('encore', spacing(2), spacing(0.5)),
		fill: palette.primary.main,
	},
	iconContainer__secondary: {
		'&$iconContainer': {
			fill: palette.grey[600],
		},
	},
	actionContainer: {
		transition: 'opacity 0.15s ease-in-out',
		pointerEvents: 'none',
		opacity: 0,
		fontSize: ifFeature('encore', typography.small.fontSize),
		lineHeight: ifFeature('encore', typography.small.lineHeight),
		maxHeight: ifFeature('encore', 20),
	},
	typographyContainer: {
		textAlign: ifFeature('encore', 'left', 'center'),
		display: 'block',
		width: '100%',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
	},
	active: {
		'& $actionContainer': {
			opacity: 1,
			pointerEvents: 'auto',
		},
	},
}));
