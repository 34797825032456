import { makeStyles } from '~styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const useFooterStyles = makeStyles()(theme => {
	const { breakpoints, palette, spacing } = theme;

	return {
		footer: {
			borderBottomLeftRadius: ifFeature('encore', '16px'),
			borderBottomRightRadius: ifFeature('encore', '16px'),
			backgroundColor: ifFeature('encore', palette.common.white, palette.gray[100]),
			borderTop: ifFeature('encore', `1px solid ${palette.gray[200]}`, `1px solid ${palette.gray[300]}`),
			flexShrink: 0,
			padding: ifFeature('encore', spacing(3, 5), '24px 32px 32px'),
			[breakpoints.down('sm')]: ifFeature('encore', {
				padding: spacing(2),
			}),
			/* @startCleanup encore */
			[breakpoints.down('md')]: {
				padding: ifFeature('encore', undefined, '12px 24px 32px'),
			},
			[breakpoints.down(500)]: {
				padding: ifFeature('encore', undefined, '8px 16px 20px'),
			},
			/* @endCleanup encore */
		},
	};
});
