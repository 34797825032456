import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = ({ palette, typography }: Theme) =>
	createStyles({
		root: {
			color: palette.gray[1000],
			lineHeight: typography.medium.lineHeight,
		},
		noMargin: {
			marginBottom: 0,
		},
		icon: {
			display: 'inline-flex',
			flexDirection: 'column',
			justifyContent: 'center',
			minHeight: '22px',
			fill: palette.primary.main,
			marginRight: '8px',
		},
		label: {
			display: 'flex',
			fontSize: typography.large.fontSize,
			fontWeight: typography.fontWeightSemibold,
			alignItems: 'center',
		},
		labelLarge: {
			fontSize: typography.biggie.fontSize,
		},
		subtitle: {
			fontSize: typography.small.fontSize,
			fontWeight: typography.fontWeightRegular,
			color: palette.grey[800],
		},
		subtitleLarge: {
			fontSize: typography.medium.fontSize,
		},
	});
