// eslint-disable-next-line no-use-before-define
import React from 'react';
import { clsx } from '~styles';
import { useJadeStyles, useEncoreStyles } from './gridlet.styles';
import { Body } from './body';
import { Footer } from './footer';
import { Header } from './header';
import { ShowOnHover } from './show-on-hover';
import { GridletProps } from './types';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Card } from '~components/card';

export const Gridlet = ({ children, className, hasHover, header, ...rest }: GridletProps) => {
	const { classes: jadeClasses } = useJadeStyles({ hasHover });
	const { classes: encoreClasses } = useEncoreStyles({ hasHover });

	const jadeContent = (
		<section className={clsx(jadeClasses.root, className)} {...rest}>
			{header && <header className={jadeClasses.header}>{header}</header>}
			<div className={jadeClasses.children}>{children}</div>
		</section>
	);

	const encoreContent = (
		<section className={encoreClasses.root}>
			<Card classes={{ root: encoreClasses.card }} encoreOnly={true} hasHover={true}>
				{header && <header className={encoreClasses.header}>{header}</header>}
				<div className={encoreClasses.children}>{children}</div>
			</Card>
		</section>
	);

	return ifFeature('encore', encoreContent, jadeContent);
};

Gridlet.Body = Body;
Gridlet.Footer = Footer;
Gridlet.Header = Header;
Gridlet.ShowOnHover = ShowOnHover;
