import { makeStyles } from '@mui/styles';
import React, { FC, PropsWithChildren } from 'react';

import { GENERIC_ERROR_TITLE, SPECIFIC_ERROR_TITLE } from './constants';
import { getReasons } from './util';
import { FileUploadFailureMessageProps } from '../../../../types';

const useStyles = makeStyles(() => ({
	reasons: {
		listStyleType: 'disc',
		marginLeft: '25px',
		marginTop: '4px',
	},
}));

export const MultipleMessage: FC<PropsWithChildren<FileUploadFailureMessageProps>> = props => {
	const { failedFileIds = [], failuresByFileId = {} } = props;

	const reasons = getReasons(failedFileIds, failuresByFileId);

	const classes = useStyles();

	return (
		<div>
			<div>{reasons.length > 0 ? SPECIFIC_ERROR_TITLE : GENERIC_ERROR_TITLE}</div>
			{reasons.length ? (
				<ul className={classes.reasons}>
					{reasons.map(reason => (
						<li key={reason}>{reason}</li>
					))}
				</ul>
			) : null}
		</div>
	);
};
