/* eslint-disable jsx-a11y/mouse-events-have-key-events */
// eslint-disable-next-line no-use-before-define
import { Box } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { Group } from '@visx/group';
import { MotionConfig } from 'framer-motion';
import React, { ReactElement } from 'react';
import { defaultTransition, useChartColors } from '../common';
import { PieCore } from '../pie';
import { ResponsiveParentSize } from '../responsive-parent-size';
import { DonutChartProps } from '../types';
import { Center } from './center';
import { CenteredLabel } from './centered-label';
import { useLabel, useMouseInteraction, useTotal } from './hooks';
import { SafeArea } from './safe-area';
import { useDebounce } from '@fabric/utils/hooks';

const useStyles = makeStyles({
	wrapper: {
		borderRadius: '50%',
		overflow: 'hidden',
		zIndex: 0,
	},
});

/**
 * Arc length is equal to the radius multiplied by the central angle of the arc in radians
 * Using this we can compute the central angle required to achieve an arc length by dividing the desired arc length by the radius
 * Note: `radius` and `desiredArcLength` must use the same units for calculation to be correct
 * https://www.cuemath.com/geometry/arc-length/
 * @param desiredArcLength desired arc length for arc
 * @param radius radius of arc
 * @returns central angle for arc that has the desired arc length
 */
const getCentralAngleForDesiredArcLength = (desiredArcLength: number, radius: number): number => {
	return desiredArcLength / radius;
};

export const DonutChart = <Datum = unknown,>({
	chartTitle = window.jQuery ? $.__('Donut chart') : 'Donut chart',
	data,
	LabelProps,
	pieKey,
	pieValue,
	onMouseMove,
	onMouseOut,
	getColor: onGetColor,
	padAngle: padAngleProp,
	pointerEvents = 'visible',
	transition = defaultTransition,
	showCenteredLabel = true,
	renderCenter,
	enableMouseInteraction = true,
	showBevel = true,
	debounceDelay,
	...rest
}: DonutChartProps<Datum>): ReactElement => {
	const {
		mixins: { darken },
	} = useTheme();

	const { getColor } = useChartColors({ onGetColor });
	const handleGetColor = (index: number): string => darken(getColor(index), 0.2);
	const classes = useStyles();

	const debouncedData = useDebounce(data, debounceDelay);
	const total = useTotal<Datum>({ data: debouncedData, pieValue });
	const { activeData, activeIndex, handleMouseOut, handleActive, onSvgMouseEnter, onSvgMouseLeave } = useMouseInteraction<Datum>({
		onMouseMove,
		onMouseOut,
		enableMouseInteraction,
	});
	const { label, labelContent } = useLabel<Datum>({ LabelProps, pieKey, pieValue, activeData, total });

	return (
		<ResponsiveParentSize>
			{({ width, height }) => {
				if (height === 0 || width === 0) return;

				const size = Math.min(width, height);
				const growthFactor = 1 / 12;
				const growthDelta = size * growthFactor;
				const growthMargin = growthDelta >> 1;
				const radius = (size >> 1) - growthMargin;
				const innerRadius = radius - size * 0.1358;
				const bevelWidth = (radius - innerRadius) * 0.29;
				const bevelOuterRadius = innerRadius + bevelWidth;
				const bevelGrowthMargin = bevelOuterRadius * 2 * growthFactor;

				const defaultPadAngle = getCentralAngleForDesiredArcLength(2, radius);
				const padAngle = typeof padAngleProp === 'number' ? Math.max(padAngleProp, defaultPadAngle) : defaultPadAngle;

				return (
					<MotionConfig transition={transition}>
						<Box className={classes.wrapper} height={size} position="absolute" width={size}>
							<SafeArea bevelWidth={bevelWidth} innerRadius={(rest.innerRadius as number) ?? innerRadius}>
								{showCenteredLabel && <CenteredLabel label={label}>{labelContent}</CenteredLabel>}
								<Center>{renderCenter}</Center>
							</SafeArea>
							<svg
								height={size}
								onMouseEnter={onSvgMouseEnter}
								onMouseLeave={onSvgMouseLeave}
								width={size}
								{...{ title: chartTitle }}
							>
								<Group left={radius + growthMargin} top={radius + growthMargin}>
									<PieCore<Datum>
										activeGrowthMargin={growthDelta}
										activeIndex={activeIndex}
										data={debouncedData}
										getColor={getColor}
										innerRadius={innerRadius}
										onMouseMove={handleActive}
										onMouseOut={handleMouseOut}
										outerRadius={radius}
										padAngle={padAngle}
										pieKey={pieKey}
										pieValue={pieValue}
										pointerEvents={pointerEvents}
										total={total}
										{...rest}
									/>
									{showBevel && (
										<PieCore<Datum>
											activeGrowthMargin={bevelGrowthMargin}
											activeIndex={activeIndex}
											ariaHidden={true}
											data={debouncedData}
											getColor={handleGetColor}
											innerRadius={innerRadius}
											outerRadius={bevelOuterRadius}
											padAngle={padAngle}
											pieKey={pieKey}
											pieValue={pieValue}
											pointerEvents="none"
											{...rest}
										/>
									)}
								</Group>
							</svg>
						</Box>
					</MotionConfig>
				);
			}}
		</ResponsiveParentSize>
	);
};
