// eslint-disable-next-line no-use-before-define
import React, { FC, PropsWithChildren } from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ClassNames from 'classnames';
import { GridRowProps } from './types';
import { dyeColors, ModeOptions } from '~styles';

const useStyles = makeStyles(({ spacing, mode }) => ({
	root: {
		alignItems: 'flex-end',
		display: 'flex',
		marginBottom: spacing(2),
		outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
		'&>:not(:last-child)': {
			marginRight: spacing(1),
		},
	},
	marginNone: {
		marginBottom: 0,
	},
	marginDense: {
		marginBottom: spacing(1),
	},
}));

/**
 * @deprecated GridRow should no longer be used. Instead, Grid is the preferred option.
 */
export const GridRow: FC<PropsWithChildren<GridRowProps>> = ({ children, className, margin = 'normal', ...rest }) => {
	const classes = useStyles();

	return (
		<Grid
			className={ClassNames(className, classes.root, {
				[classes.marginNone]: margin === 'none',
				[classes.marginDense]: margin === 'dense',
			})}
			item
			xs={12}
			{...rest}
		>
			{children}
		</Grid>
	);
};
