/* @startCleanup encore */
import classNames from 'classnames';
import { Icon } from '@fabric/icon';
import { DEFAULT_ICON_JADE, DEFAULT_TITLE_JADE } from './blank-state.domain';
import { Typography } from '@mui/material';
/* @endCleanup encore */
import {
	/* @startCleanup encore */
	useJadeStyles,
	/* @endCleanup encore */
	useStyles,
} from './blank-state.styles';
import { isString, uniqueId } from 'lodash';
import React, { cloneElement, type ReactElement } from 'react';
import { BlankStateProps } from './types';
import { Headline } from '~components/headline';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { BodyText } from '~components/body-text';
import { IconV2, type IconV2Name } from '~components/icon-v2';

function getActions(actions: ReactElement[], level: 'page' | 'widget') {
	try {
		return actions.map(action => cloneElement(action, { size: level === 'page' ? 'medium' : 'small' }));
	} catch (err) {
		// TODO: throw in dev
		console.error(err);
	}
}

export function BlankState({
	actions,
	/* @startCleanup encore */
	children,
	classes: classesProp = {},
	className,
	/* @endCleanup encore */
	icon,
	level = 'page',
	subtitle,
	title,
	titleComponent,
}: BlankStateProps): JSX.Element {
	const titleId = `${uniqueId()}_title`;
	const { classes } = useStyles({ level });

	/* @startCleanup encore */
	icon = ifFeature('encore', icon, icon === undefined ? DEFAULT_ICON_JADE : icon);
	title = ifFeature('encore', title, title === undefined ? DEFAULT_TITLE_JADE : title);

	const jadeClasses = useJadeStyles();

	function renderTitle() {
		const component = isString(title) ? {} : { component: 'div' };
		return (
			<Typography className={classNames(jadeClasses.title, classesProp.title)} id={titleId} {...component} variant="h3">
				{title}
			</Typography>
		);
	}

	function renderSubtitle() {
		if (subtitle) {
			const component = isString(subtitle) ? {} : { component: 'div' };
			return (
				<Typography className={classNames(jadeClasses.subtitle, classesProp.subtitle)} {...component} variant="body1">
					{subtitle}
				</Typography>
			);
		}
	}
	/* @endCleanup encore */

	return (
		<div
			aria-labelledby={titleId}
			className={ifFeature('encore', classes.root, classNames(jadeClasses.root, className, classesProp.root))}
			role="complementary"
		>
			{ifFeature(
				'encore',
				icon && isString(icon) ? (
					<IconV2
						color={level === 'page' ? 'neutral-weak' : 'neutral-medium'}
						name={icon as IconV2Name}
						size={level === 'page' ? 128 : 60}
					/>
				) : null,
				<div className={classNames(jadeClasses.icon, classesProp.icon)}>
					{typeof icon === 'string' ? <Icon name={icon} /> : icon}
				</div>
			)}
			{ifFeature(
				'encore',
				<div className={classes.text}>
					<Headline
						color="neutral-weak"
						component={titleComponent}
						id={titleId}
						justify="center"
						size={level === 'page' ? 'medium' : 'extra-small'}
					>
						{title}
					</Headline>
					<BodyText color="neutral-weak" justify="center" size={level === 'page' ? 'medium' : 'extra-small'}>
						{subtitle}
					</BodyText>
				</div>,
				<>
					{renderTitle()}
					{renderSubtitle()}
				</>
			)}
			{actions
				? ifFeature(
						'encore',
						<div className={classes.actions}>{getActions(actions, level)}</div>,
						<div className={classNames(jadeClasses.footer, classesProp.footer)}>{actions}</div>
					)
				: null}
			{ifFeature(
				'encore',
				undefined,
				children && <div className={classNames(jadeClasses.footer, classesProp.footer)}>{children}</div>
			)}
		</div>
	);
}
