/* eslint-disable react/forbid-component-props */
import classNames from 'classnames';
import { Checkbox as MuiCheckbox, FormControlLabel, Typography } from '@mui/material';
import { isString } from 'lodash';
import React, { forwardRef } from 'react';
import { Minus12x3, Check14x14 } from '@bamboohr/grim';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { IconV2 } from '~components/icon-v2';

import { getBiId, getStatusIcon, getEncoreStatusIcon, ENCORE_SIZE_MAP } from './checkbox.domain';
import { useStyles } from './checkbox.styles';
import { CheckboxProps, CheckboxStyleProps } from './types';

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
	(
		{
			biId,
			checked,
			classes = {},
			className,
			disabled = false,
			form,
			id,
			indeterminate = false,
			inputProps,
			label,
			name,
			note,
			onChange,
			required = false,
			size = 'medium',
			status = 'default',
			tabIndex,
			value,
		},
		ref
	): JSX.Element => {
		disabled = status === 'pending' || disabled;

		const styleProps: CheckboxStyleProps = {
			checked,
			disabled,
			indeterminate,
			required,
			size: ifFeature('encore', ENCORE_SIZE_MAP[size], size),
			status,
		};
		const styles = useStyles(styleProps);

		const checkboxControl = renderCheckbox();
		const statusIcon = ifFeature('encore', getEncoreStatusIcon(status), getStatusIcon(status, size));

		return (
			<div
				className={classNames(styles.root, className, classes.root, {
					[styles.warning]: status === 'warning',
					[styles.error]: status === 'error',
				})}
			>
				{label ? (
					<React.Fragment>
						<div className={classNames(styles.labelContainer, classes.labelContainer)}>
							<FormControlLabel
								classes={{
									label: classNames(styles.label, classes.label),
									root: classNames(styles.labelRoot, classes.labelRoot),
								}}
								control={checkboxControl}
								label={
									<React.Fragment>
										{statusIcon && (
											<div className={classNames(styles.statusIcon, classes.statusIcon)}>{statusIcon}</div>
										)}
										<div className={classNames(styles.labelContent, classes.labelContent)}>{label}</div>
									</React.Fragment>
								}
								value={value}
							/>
							{required && <div className={classNames(styles.asterisk, classes.asterisk)}>*</div>}
						</div>
						{renderNote()}
					</React.Fragment>
				) : (
					checkboxControl
				)}
			</div>
		);

		function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
			const { checked, name } = e.target;

			onChange(
				{
					checked,
					name,
					value,
				},
				e
			);
		}

		function renderCheckbox() {
			const defaultInputProps = {
				'data-bi-id': getBiId(biId, name, value),
			} as typeof inputProps;

			return (
				<MuiCheckbox
					checked={checked}
					checkedIcon={renderCheckboxIcon()}
					disabled={disabled}
					form={form}
					icon={renderCheckboxIcon()}
					id={id}
					indeterminate={indeterminate}
					indeterminateIcon={renderCheckboxIcon()}
					inputProps={{ ...defaultInputProps, ...inputProps }}
					inputRef={ref}
					name={name}
					onChange={handleChange}
					required={required}
					tabIndex={tabIndex}
					value={value}
				/>
			);
		}

		function renderCheckboxIcon() {
			return (
				<div className={classNames(styles.unselectedBox, classes.unselectedBox)}>
					<div className={classNames(styles.selectedBox, classes.selectedBox)} />
					<div className={classNames(styles.selectedIcon, classes.selectedIcon)}>
						{indeterminate
							? ifFeature('encore', <IconV2 name="dash-solid" size={16} />, <Minus12x3 aria-hidden="true" />)
							: ifFeature('encore', <IconV2 name="check-solid" size={16} />, <Check14x14 aria-hidden="true" />)}
					</div>
				</div>
			);
		}

		function renderNote() {
			if (note) {
				const component = isString(note) ? {} : { component: 'div' };
				return (
					<Typography className={classNames(styles.note, classes.note)} {...component} variant="body1">
						{note}
					</Typography>
				);
			}
		}
	}
);
