/* eslint-disable react/forbid-component-props */
import classNames from 'classnames';
import { Calendar15x16 } from '@bamboohr/grim';
import { ButtonBase, InputAdornment } from '@mui/material';
import React, { forwardRef, useLayoutEffect, useRef, useState, KeyboardEvent } from 'react';
import { TextField } from '~components/text-field'; // must be defined before 'useStyles' so classes override as expected.
import { getCalendarButtonLabel, getDisplayValue, getInputDateOnChangeParam, getParsedDate } from './input-date.domain';
import { useStyles } from './input-date.styles';
import { useDatePickerUtils } from '../../hooks';
import { getMaskConfig } from '../../shared.domain';
import { DatePickerDate, InputDateProps, InputDateStyleProps } from '../../types';
import { getFullMaskStr, getUpdatedMask } from '../../../../utils/mask';
import { PickerButton } from '~components/picker-button';
import composeRefs from '@seznam/compose-react-refs';

// @startCleanup encore
import { ifFeature } from '@bamboohr/utils/lib/feature';
// @endCleanup encore

export const InputDate = forwardRef<HTMLDivElement, InputDateProps>(
	(
		{
			biId,
			className,
			calendarButtonRef,
			calendarId,
			classes = {},
			disabled = false,
			id,
			inputProps = {},
			isPopoverOpen,
			label,
			note,
			noteStatus,
			onChange,
			onInputChange,
			onPopupToggle,
			popupToggleButtonProps,
			required = false,
			size,
			status = 'default',
			value,
			variant = 'form',
			viewMode = false,
			width = ifFeature('encore', 6, 5),
		},
		ref
	): JSX.Element => {
		noteStatus = noteStatus || status;
		const styleProps: InputDateStyleProps = {
			disabled,
			noteStatus,
			size,
		};
		const styles = useStyles(styleProps);
		const utils = useDatePickerUtils();
		const inputRef = useRef<HTMLInputElement>(null);
		const refKeydownStartPosition = useRef(0);
		const refKeydownEndPosition = useRef(0);
		const refKeydownInputValue = useRef('');

		const maskConfig = getMaskConfig(utils);

		const [isEditing, setIsEditing] = useState(false);
		const [editedInputValue, setEditedInputValue] = useState('');
		const [position, setPosition] = useState(0);
		const [forcedPositionCount, setForcedPositionCount] = useState(0);
		const [inputFocused, setInputFocused] = useState(true);

		useLayoutEffect(() => {
			inputRef.current?.setSelectionRange(position, position);
		}, [position, forcedPositionCount]);

		return (
			<div className={classNames(styles.root, className, classes.root)} ref={ref}>
				<TextField
					biId={biId ? `${biId}_input` : biId}
					classes={{
						note: styles.note,
					}}
					disabled={disabled}
					focusRing={inputFocused}
					id={id}
					inputProps={{
						...inputProps,
						onKeyDown: handleInputKeyDown,
					}}
					// eslint-disable-next-line react/jsx-no-duplicate-props
					InputProps={{
						denseAdornment: true,
						endAdornment:
							!viewMode &&
							ifFeature(
								'encore',
								<PickerButton
									ariaControls={isPopoverOpen ? `date-picker-control-${calendarId}` : undefined}
									ariaExpanded={isPopoverOpen ? 'true' : 'false'}
									ariaHaspopup="dialog"
									ariaLabel={getCalendarButtonLabel(utils, value)}
									containerRef={calendarButtonRef}
									disabled={disabled}
									icon="calendar-regular"
									onBlur={() => setInputFocused(true)}
									onClick={onPopupToggle}
									onFocus={() => {
										setInputFocused(false);
									}}
									// @ts-expect-error size type includes deprecated values that can be removed post-Encore
									size={size}
									status={status === 'default' ? undefined : status}
									variant={variant}
								/>,
								<InputAdornment position="end">
									<ButtonBase
										aria-controls={isPopoverOpen ? `date-picker-control-${calendarId}` : undefined}
										aria-expanded={isPopoverOpen ? 'true' : 'false'}
										aria-haspopup="dialog"
										aria-label={getCalendarButtonLabel(utils, value)}
										{...popupToggleButtonProps}
										className={styles.iconButton}
										disabled={disabled}
										onBlur={() => setInputFocused(true)}
										onClick={onPopupToggle}
										onFocus={() => setInputFocused(false)}
										ref={calendarButtonRef}
									>
										<Calendar15x16 />
									</ButtonBase>
								</InputAdornment>
							),
					}}
					label={label}
					note={ifFeature('encore', note)}
					onBlur={handleBlur}
					onChange={handleInputChange}
					placeholder={getFullMaskStr(maskConfig)}
					ref={composeRefs(inputRef, inputProps.ref)}
					required={required}
					size={size}
					status={status === 'default' ? undefined : status}
					value={getDisplayValue(utils, value, editedInputValue, isEditing)}
					variant={variant}
					viewMode={viewMode}
					width={width}
				/>
				{/* @startCleanup encore */}
				{ifFeature(
					'encore',
					null,
					note && (
						<div className={styles.note} data-test-id="note">
							{note}
						</div>
					)
				)}
				{/* @endCleanup encore */}
			</div>
		);

		function handleBlur(e: React.FocusEvent<HTMLInputElement>): void {
			if (isEditing) {
				handleChange(editedInputValue);
			}
			setIsEditing(false);

			if (inputProps.onBlur) {
				inputProps.onBlur(e);
			}
		}

		function handleChange(inputValue: string): void {
			const date = getParsedDate(utils, inputValue) as DatePickerDate;
			if (date) {
				setEditedInputValue('');
			}

			const param = getInputDateOnChangeParam(date, inputValue);
			onChange(param);
		}

		function handleInputChange(e: React.ChangeEvent<HTMLInputElement>): void {
			if (!isEditing) {
				setIsEditing(true);
			}

			const { position, value } = getUpdatedMask(
				maskConfig,
				refKeydownStartPosition.current,
				refKeydownEndPosition.current,
				refKeydownInputValue.current,
				e.target.selectionStart as number,
				e.target.selectionEnd as number,
				e.target.value
			);

			const date = getParsedDate(utils, value) as DatePickerDate;

			setEditedInputValue(value);
			setPosition(position);
			setForcedPositionCount(count => count + 1);

			if (typeof onInputChange === 'function') {
				onInputChange(getInputDateOnChangeParam(date, value));
			}

			if (inputProps.onChange) {
				inputProps.onChange(e);
			}
		}

		function handleInputKeyDown(e: KeyboardEvent<HTMLInputElement>): void {
			refKeydownStartPosition.current = e.currentTarget.selectionStart as number;
			refKeydownEndPosition.current = e.currentTarget.selectionEnd as number;
			refKeydownInputValue.current = e.currentTarget.value;

			if (inputProps.onKeyDown) {
				inputProps.onKeyDown(e);
			}
		}
	}
);
