import { useCallback, useEffect, useRef, useState } from 'react';
import IntroSection from '../../components/IntroSection';
import NavContent from '../../components/NavContent';
import { ChatLogRow } from '../../components/chatLogRow.react';
import { FooterMessage } from '../../components/footerMessage.react/footerMessage';

import config from '../../config';
import api from '../../services/api';

import { reportError } from '../../utils/rollbar';
import { ChatLog, ChatSessionNames, ResponseError } from './home.types';
import { buildChatRequest, getChatSessions, hasCustomLLMConfig } from './utils';
import FooterInput from 'src/components/FooterInput';
import useAutosizeTextArea from 'src/hooks/useAutosizeTextArea';
import { IconV2 } from '@bamboohr/fabric';

// Configs
const CHAT_ENDPOINT = config.VERSION_ENDPOINT + 'chat';
const CHAT_SESSIONS_ENDPOINT = config.VERSION_ENDPOINT + 'chat-session';
const GENERATE_NAME_ENDPOINT =
	config.VERSION_ENDPOINT + 'chat-session/generate-name';

const Home = ({ currentUser, setCurrentUser }) => {
	// UI State
	const [showMenu, setShowMenu] = useState(false);
	const [activeSessionId, setActiveSessionId] = useState('');

	// Chat Info & Data
	const [currentChatSessionId, setCurrentChatSessionId] = useState('');
	// TODO (AIPL-437): Fix warnings, remove this eslint-disable
	// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
	const [currentChatSessionName, setCurrentChatSessionName] = useState('');
	const [chatSessionNames, setChatSessionNames] = useState<ChatSessionNames[]>(
		[],
	);
	const [chatLog, setChatLog] = useState<ChatLog[]>([]);

	// Input & Feedback State
	const [inputPrompt, setInputPrompt] = useState('');
	const [responseFromAPI, setReponseFromAPI] = useState(false);
	const [errorOccurred, setErrorOccurred] = useState(false);
	const [hasPrinted, setHasPrinted] = useState(false);

	const [warningVariant, setWarningVariant] = useState('');
	const [mostRecentWarningVariant, setMostRecentWarningVariant] = useState('');
	const [llmId, setLlmId] = useState(config.DEFAULT_FUNCTION_ID);
	const [llmLabel, setLlmLabel] = useState(config.DEFAULT_FUNCTION_LABEL);
	const [llmConfig, setLlmConfig] = useState(config.DEFAULT_LLM_CONFIG);

	const [form, setForm] = useState(null);

	const [modes, setModes] = useState({});

	// Refs
	const chatLogRef = useRef<HTMLDivElement>(null);
	const textAreaRef = useRef<HTMLTextAreaElement>(null);
	useAutosizeTextArea(textAreaRef.current, inputPrompt);

	useEffect(() => {
		if (chatSessionNames.length === 0) {
			async function fetchData() {
				const newChatSessions = await getChatSessions(CHAT_SESSIONS_ENDPOINT);
				setChatSessionNames(newChatSessions);
			}
			fetchData();
		}
	}, [chatSessionNames.length, modes]);

	const removeMostRecentPrompt = useCallback(() => {
		const newChatLog = [...chatLog];
		newChatLog.pop(); // Remove the last item
		setChatLog(newChatLog);
	}, [chatLog]);

	useEffect(() => {
		if (errorOccurred) {
			removeMostRecentPrompt();
			setErrorOccurred(false);
			if (chatLog.length === 1) {
				api.delete(CHAT_SESSIONS_ENDPOINT + '/' + currentChatSessionId);
			}
		}
	}, [
		chatLog,
		chatLog.length,
		currentChatSessionId,
		errorOccurred,
		removeMostRecentPrompt,
	]);

	useEffect(() => {
		if (!activeSessionId) return;
		const thisChat = chatSessionNames.find((x) => x.id === activeSessionId);
		setLlmId(thisChat?.llmId ? thisChat.llmId : config.DEFAULT_FUNCTION_ID);
		setLlmLabel(
			thisChat?.llmLabel ? thisChat.llmLabel : config.DEFAULT_FUNCTION_LABEL,
		);
	}, [activeSessionId, chatSessionNames]);

	useEffect(() => {
		setTimeout(() => {
			scrollToBottom();
		}, 400);
	}, [chatLog]);

	const handleKeyDown = (e) => {
		// Check if the Enter key is pressed without any modifiers
		if (
			e.key === 'Enter' &&
			!e.shiftKey &&
			!e.altKey &&
			!(e.ctrlKey || e.metaKey)
		) {
			e.preventDefault();
			handleSubmit(e);
		}
		// Check if Command + Enter or Control + Enter is pressed
		else if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
			e.preventDefault();
			const start = e.target.selectionStart;
			const end = e.target.selectionEnd;
			const newValue =
				inputPrompt.substring(0, start) + '\n' + inputPrompt.substring(end);
			setInputPrompt(newValue);
			// This ensures the caret position stays correct after adding the newline
			setTimeout(() => {
				e.target.selectionStart = start + 1;
				e.target.selectionEnd = start + 1;
			});
		}
	};

	const callAPI = async () => {
		try {
			let response;

			if (chatLog.length > 0) {
				const request = buildRequest(currentChatSessionId);
				response = await api.post(CHAT_ENDPOINT, request);
			} else {
				// Create new Chat Session document
				const newChatSession = await api.post(CHAT_SESSIONS_ENDPOINT);
				setCurrentChatSessionId(newChatSession.data['id']);

				if (llmId === 'mini-rag') {
					response = await api.put(
						CHAT_SESSIONS_ENDPOINT +
							'/' +
							newChatSession.data['id'] +
							'/update-context',
						form,
						{
							headers: {
								'Content-Type': 'multipart/form-data', // Need to include this to upload file
							},
						},
					);
				} else {
					// Make new Chat document
					const request = buildRequest(newChatSession.data['id']);
					response = await api.post(CHAT_ENDPOINT, request);
				}

				let status = response.data.status;
				if (status === 'llm-service-error' || status === 'pdf-parse-error') {
					const error = new Error('LLM service provider unavailable');
					(error as ResponseError).status = response.data.status;
					throw error;
				}

				const sessionName = await api.post(GENERATE_NAME_ENDPOINT, {
					question: inputPrompt,
					answer: response.data.observation,
				});

				setChatSessionNames([
					...chatSessionNames,
					{
						name: sessionName.data,
						id: newChatSession.data['id'],
						llmId: llmId,
						llmLabel: llmLabel,
						llmConfig: llmConfig,
						time_created: newChatSession.data['time_created'],
					},
				]);

				await api.put(
					CHAT_SESSIONS_ENDPOINT + '/' + newChatSession.data['id'],
					{
						session_name: sessionName.data,
						function_id: llmId,
						llm_config: llmConfig,
						llm_label: llmLabel,
					},
				);
			}

			const data = response.data;
			const observation = response.data.observation;
			const status = response.data.status;

			setChatLog([
				...chatLog,
				{
					chatPrompt: inputPrompt,
					botMessage: observation,
					id: data.id,
					llmId: llmId,
					llmLabel: llmLabel,
					rating: data.rating,
					unhelpful: data.unhelpful,
					incorrect: data.incorrect,
					harmful: data.harmful,
					comment: data.comment,
					sources: data.sources,
				},
			]);

			if (
				mostRecentWarningVariant !== status ||
				status === 'token-limit-exceeded'
			) {
				setWarningVariant(status);
			}
		} catch (error) {
			console.log(error);
			let status =
				error.status || error?.response?.data?.status || 'generic-error';
			if (error.response?.status === 401) {
				status = 'cookies-expired';
			}
			if (textAreaRef.current) textAreaRef.current.value = inputPrompt;
			textAreaRef?.current?.focus();
			setErrorOccurred(true);
			setWarningVariant(status);

			// Report the error to Rollbar
			reportError('Error in AI Sandbox', error);
		}

		//  Set responseFromAPI back to false after the fetch request is complete
		setReponseFromAPI(false);
	};

	const handleSubmit = (e: any) => {
		e.preventDefault();
		if (!llmId) {
			return null;
		}
		if (llmId === 'mini-rag' && form === null && chatLog.length === 0) {
			alert('You must upload a file to use this mode');
			return null;
		}

		if (!responseFromAPI) {
			if (inputPrompt && inputPrompt?.trim() !== '') {
				setReponseFromAPI(true);
				setChatLog([...chatLog, { chatPrompt: inputPrompt }]);
				callAPI();

				// hide the keyboard in mobile devices
				e.target.blur();
			}
		}

		setInputPrompt('');
		textAreaRef.current?.focus();
	};

	function buildRequest(chatSessionId) {
		let context = { prompt: inputPrompt };
		let params = { context };

		if (hasCustomLLMConfig(config, llmId)) {
			context['messages'] = buildChatRequest(chatLog, inputPrompt);
			params['llm-config'] = llmConfig;
		} else {
			context['question'] = inputPrompt;
		}

		return {
			function_id: llmId,
			'chat-session-id': chatSessionId,
			params,
		};
	}

	const setNewLlmValue = (newLlmValue) => {
		setLlmId(newLlmValue.value);
		setLlmLabel(newLlmValue.label);
	};

	const scrollToBottom = () => {
		chatLogRef.current?.scrollIntoView({
			behavior: 'smooth',
			block: 'end',
		});
	};

	return (
		<>
			<header>
				<div className="menu">
					<button onClick={() => setShowMenu(true)}>
						<IconV2 color="info-weak" name="bars-solid" size={24} />
					</button>
				</div>
				<h1>AI Sandbox</h1>
			</header>

			{showMenu && (
				<nav>
					<div className="navItems">
						<NavContent
							chatNames={chatSessionNames}
							setChatNames={setChatSessionNames}
							setChatLog={setChatLog}
							setChatID={setCurrentChatSessionId}
							setShowMenu={setShowMenu}
							setHasPrinted={setHasPrinted}
							setChatName={setCurrentChatSessionName}
							setCurrentUser={setCurrentUser}
							activeId={activeSessionId}
							setActiveId={setActiveSessionId}
							textAreaRef={textAreaRef}
							setLlmLabel={setLlmLabel}
							setLlmId={setLlmId}
							setLlmConfig={setLlmConfig}
							setForm={setForm}
						/>
					</div>
					<div className="navCloseIcon">
						<svg
							fill="#fff"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 100 100"
							xmlSpace="preserve"
							stroke="#fff"
							width={42}
							height={42}
							onClick={() => setShowMenu(false)}
						>
							<path d="m53.691 50.609 13.467-13.467a2 2 0 1 0-2.828-2.828L50.863 47.781 37.398 34.314a2 2 0 1 0-2.828 2.828l13.465 13.467-14.293 14.293a2 2 0 1 0 2.828 2.828l14.293-14.293L65.156 67.73c.391.391.902.586 1.414.586s1.023-.195 1.414-.586a2 2 0 0 0 0-2.828L53.691 50.609z" />
						</svg>
					</div>
				</nav>
			)}

			<aside className="sideMenu">
				<NavContent
					chatNames={chatSessionNames}
					setChatNames={setChatSessionNames}
					setChatLog={setChatLog}
					setChatID={setCurrentChatSessionId}
					setShowMenu={setShowMenu}
					setHasPrinted={setHasPrinted}
					setChatName={setCurrentChatSessionName}
					setCurrentUser={setCurrentUser}
					activeId={activeSessionId}
					setActiveId={setActiveSessionId}
					textAreaRef={textAreaRef}
					setLlmLabel={setLlmLabel}
					setLlmId={setLlmId}
					setLlmConfig={setLlmConfig}
					setForm={setForm}
				/>
			</aside>

			<section className="chatBox">
				{chatLog.length > 0 ? (
					<div className="chatLogWrapper">
						{chatLog.length > 0 &&
							chatLog.map((chat, idx) => (
								<ChatLogRow
									chat={chat}
									chatId={chat.id}
									llmId={llmId}
									chatLogRef={chatLogRef}
									currentUser={currentUser}
									hasPrinted={hasPrinted}
									id={`navPrompt-${chat.chatPrompt.replace(
										/[^a-zA-Z0-9]/g,
										'-',
									)}`}
									index={idx}
									key={idx}
									llmLabel={llmLabel ? llmLabel : config.DEFAULT_FUNCTION_LABEL}
									setHasPrinted={setHasPrinted}
									rating={chat.rating}
									unhelpful={chat.unhelpful}
									incorrect={chat.incorrect}
									harmful={chat.harmful}
									comment={chat.comment}
								/>
							))}
					</div>
				) : (
					<IntroSection
						handleSubmit={handleSubmit}
						setNewLlmValue={setNewLlmValue}
						setLlmConfig={setLlmConfig}
						setModes={setModes}
						setForm={setForm}
						setInputPrompt={setInputPrompt}
						llmId={llmId}
					/>
				)}
				<div className="inputPromptContainer">
					{
						<FooterMessage
							variant={warningVariant}
							hideAlert={() => {
								setMostRecentWarningVariant(warningVariant);
								setWarningVariant('');
							}}
						/>
					}
					<FooterInput
						llmId={llmId}
						handleSubmit={handleSubmit}
						handleKeyDown={handleKeyDown}
						textAreaRef={textAreaRef}
						inputPrompt={inputPrompt}
						setInputPrompt={setInputPrompt}
					/>
				</div>
			</section>
		</>
	);
};

export default Home;
