import React from 'react';
import { styled } from '@mui/material';
import { CalendarPicker, CalendarPickerProps, CalendarPickerClasses } from '@mui/x-date-pickers';
import { dyeColors, excludeProps, ModeOptions } from '~styles';
import { DatePickerDate } from '~components/date-picker/types';
import { ifFeature } from '@bamboohr/utils/lib/feature';

const StyledCalendar = styled(CalendarPicker, { shouldForwardProp: excludeProps(['weeks']) })<{ weeks: number }>(
	({ weeks, theme: { constructs, mode, spacing, palette } }) => ({
		// @startCleanup encore
		width: ifFeature('encore', undefined, 306),
		// @endCleanup encore
		transition: 'max-height .3s',
		// The days in the calendar have specific sizes and this class allows the height to adjust and animate when the month transistions to a month with less or more weeks
		maxHeight: ifFeature('encore', weeks * 43 + 79, weeks * 36 + 30),
		margin: ifFeature('encore', 0, '-3px auto 0'),
		'&:focus-within': {
			'.Mui-active': {
				boxShadow: ifFeature('encore', `0px 0px 0px 2px ${constructs.surface.primary.weak}`),
				'&.Mui-error': {
					boxShadow: ifFeature('encore', `0px 0px 0px 2px ${constructs.surface.error.weak}`),
				},
			},
		},
		'&>div:first-of-type': {
			display: 'none',
		},
		'& > .MuiCalendarPicker-viewTransitionContainer > div > div > div:first-of-type': {
			// the container for the week letters is inaccessible via a class tag without modifiying a larger piece of the datepicker
			// this drill down allows for the flex of the week letters to match the spacing applied to the actual days in the grid below it
			justifyContent: ifFeature('encore', 'space-evenly'),
			padding: ifFeature('encore', spacing(1.5, 1, 0, 1)),
			marginBottom: ifFeature('encore', spacing(1)),
		},
		'& .MuiCalendarPicker-viewTransitionContainer .MuiTypography-root': {
			// weekday labels
			height: spacing(3.5),
			// @startCleanup encore
			marginBottom: ifFeature('encore', undefined, spacing(0.5)),
			// @endCleanup encore
			color: ifFeature('encore', constructs.text.neutral.weak, palette.gray[600]),
			fontFamily: ifFeature('encore', 'Inter, sans-serif'),
			fontSize: ifFeature('encore', '13px'),
			fontWeight: ifFeature('encore', 600),
		},
		'& .MuiCalendarPicker-viewTransitionContainer': {
			// weekday and days container
			overflow: 'hidden',
		},
		'& .PrivatePickersSlideTransition-root': {
			// days container
			backgroundColor: mode === ModeOptions.Dye ? dyeColors.main : palette.common.white,
			minHeight: ifFeature('encore', weeks * 46, 214),
			transition: ifFeature('encore', 'min-height .3s'),
			display: ifFeature('encore', 'flex'),
			flexDirection: ifFeature('encore', 'column'),
			'&>div:first-of-type': {
				flex: ifFeature('encore', 1),
				// @startCleanup encore
				paddingTop: ifFeature('encore', undefined, 4),
				// @endCleanup encore
				margin: ifFeature('encore', spacing(1, 0.75, 0, 0)),
				paddingLeft: ifFeature('encore', spacing(0.75)),
				// The border applied to the right calendar on datepicker ranges requires the full height when the left calendar is bigger (due to more weeks)
				// This allows the border to adjust to the spacing and prevent touching the edge of the calenda
				height: ifFeature('encore', `calc(100% - ${spacing(1.5)})`),
			},
			'[role=row]': {
				'&:first-of-type': {
					// This removes top padding from the first row to allow the row to remain flush with the border rules on Datepicker ranges
					paddingTop: ifFeature('encore', 0),
				},
				justifyContent: ifFeature('encore', 'space-evenly'),
				margin: ifFeature('encore', 0),
				padding: ifFeature('encore', '2px 0'),
			},
		},
	})
);

interface CalendarProps extends Omit<CalendarPickerProps<DatePickerDate>, 'classes' | 'className'> {
	/**
	 * classes should no longer be passed to Fabric components. Reach out to the Fabric team if the component needs to be enhanced to accommodate your feature.
	 * @deprecated
	 */
	classes?: Partial<CalendarPickerClasses>;
	/**
	 * className should no longer be passed to Fabric components. Reach out to the Fabric team if the component needs to be enhanced to accommodate your feature.
	 * @deprecated
	 */
	className?: string;
	weeksToShow: number;
}

export const Calendar = ({ componentsProps, weeksToShow, components, ...props }: CalendarProps) => {
	return (
		<StyledCalendar
			{...props}
			components={{
				LeftArrowButton: () => null,
				RightArrowButton: () => null,
				SwitchViewButton: () => null,
			}}
			weeks={weeksToShow}
		/>
	);
};
