import React, { FC, forwardRef, PropsWithChildren } from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { WithStyles, withStyles } from '@mui/styles';
import clsx from 'classnames';
import { BaseButton } from '../base-button';
import { TextButtonProps } from '../../types';
import { styles } from './styles';
import { BaseButtonSize } from '../../types/base-button.types';

export const TextButton = withStyles(styles)(
	forwardRef<HTMLElement, TextButtonProps & WithStyles<typeof styles>>(
		({ classes, component, dark, inline, status: statusProp, condition, maxWidth, muted, style, size, ...rest }, ref) => {
			const status = statusProp || condition;

			if (inline && !component && !rest.href) {
				component = ifFeature('encore', 'span');
			}

			const {
				root,
				altStyling: altStylingClass,
				content: contentClass,
				endIcon: endIconClass,
				error: errorClass,
				info: infoClass,
				inline: inlineClass,
				inlineIcon: inlineIconClass,
				startIcon: startIconClass,
				warning: warningClass,
				...restClasses
			} = classes;

			return (
				<BaseButton
					classes={{
						content: ifFeature('encore', inline ? contentClass : undefined, contentClass),
						endIcon: ifFeature('encore', inline ? clsx(inlineIconClass, endIconClass) : endIconClass, endIconClass),
						root: clsx(root, {
							[altStylingClass]: dark,
							[errorClass]: status === 'error',
							[infoClass]: status === 'info',
							[inlineClass]: inline,
							[warningClass]: status === 'warning',
						}),
						startIcon: ifFeature(
							'encore',
							inline ? clsx(inlineIconClass, startIconClass) : startIconClass,
							startIconClass
						),
						...restClasses,
					}}
					color={muted ? 'secondary' : undefined}
					component={component}
					dark={dark}
					ref={ref}
					size={size as BaseButtonSize}
					// eslint-disable-next-line react/forbid-component-props
					style={{ maxWidth, minWidth: ifFeature('encore', 0), ...style }}
					{...rest}
					variant="text"
				/>
			);
		}
	)
);

export const TextButtonStorybook = TextButton as FC<PropsWithChildren<TextButtonProps>>;
