import { createStyles, makeStyles } from '@mui/styles';
import { heights } from '~definitions/json/sizes.json';

import { InputDateRangeStyleProps } from '../../types';

export const useStyles = makeStyles(theme => {
	const { palette } = theme;

	return createStyles({
		endDate: {},
		rangeSeparator: {
			alignItems: 'center',
			display: 'flex',
			// The default size is set to medium
			// outlineInputHeight is the height of the input field on both sides of the range separator
			// we set the height equal to those so the separator is vertically centered
			height: (props: InputDateRangeStyleProps) => heights[props.size || 'medium'],
			margin: '0 8px',
		},
		root: {
			alignItems: 'flex-end',
			color: (props: InputDateRangeStyleProps) => palette.grey[props.disabled ? 600 : 1000],
			display: 'flex',
			flexWrap: 'nowrap',
		},
		startDate: {},
	});
});
