import { PopperProps } from '@mui/material';
import { ReactNode } from 'react';
export * from './capitalize';
import * as Charts from './charts';
import * as Inputs from './inputs';
export * from './format-aria-props';
export * from './format-base-props';
export * from './encore';
export * from './hooks';
export { allUnionValues } from './all-union-values';
export { enhanceAnchorProps } from './enhance-anchor-props';
export { hasKey } from './has-key';
export { isNotNullOrUndefined } from './is-not-null-or-undefined';
export { isExternalUrl } from './is-external-url';
export { isRecord } from './is-record';
export { makeUid } from './makeUid';
export * from './overlay';
export * from './types';
export { Inputs };
export { Charts };

const EXTERNAL_URL_PATTERN = /^https?:\/\//i;

/**
 * Assumes that provided url is intended to be external to the given site if it begins with http(s)
 */
export function isUrlExternal(url: string): boolean {
	return EXTERNAL_URL_PATTERN.test(url);
}

/**
 * Returns the provided value unless it is a function.
 * If the provided value is a function the return value from invoking it with any additional arguments will be returned.
 */
export function renderProp(value: ReactNode | ((...args: unknown[]) => ReactNode) = null, ...args: unknown[]): ReactNode {
	return typeof value === 'function' ? value(...args) : value;
}

/**
 * Function for getting the anchorEl used in Popper components.
 */
export function getAnchorEl(anchorEl: PopperProps['anchorEl']): PopperProps['anchorEl'] {
	return typeof anchorEl === 'function' ? anchorEl() : anchorEl;
}
