import React, { FunctionComponent, PropsWithChildren } from 'react';

import { BaseModal } from '~components/base-modal';
import { FullScreenModalFooterProps } from '../full-screen-modal.types';
import { useFooterStyles } from './footer.styles';

export const Footer: FunctionComponent<PropsWithChildren<FullScreenModalFooterProps>> = props => {
	const { classes } = useFooterStyles();

	return (
		<div className={classes.footer}>
			<BaseModal.Footer actionAlignment="start" {...props} />
		</div>
	);
};
