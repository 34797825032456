import { makeStyles } from '@mui/styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';

// Theme with top and bottom borders
export const useAccordionBorderTheme = makeStyles(({ borderRadiuses, constructs, palette, spacing }) => ({
	root: {
		backgroundColor: palette.common.white,
		borderBottomColor: ifFeature('encore', constructs.border.neutral.xWeak),
		borderBottomWidth: ifFeature('encore', '1px'),
		/* @startCleanup encore */
		borderColor: ifFeature('encore', undefined, palette.grey[300]),
		/* @endCleanup encore */
		borderRadius: borderRadiuses[0],
		borderStyle: 'solid',
		/* @startCleanup encore */
		borderWidth: ifFeature('encore', undefined, '1px 0'),
		/* @endCleanup encore */
		boxShadow: 'none',
		boxSizing: 'border-box',
		/* @startCleanup encore */
		'&:not(:last-child)': {
			borderBottom: ifFeature('encore', undefined, 0),
		},
		/* @endCleanup encore */
		paddingBottom: ifFeature('encore', spacing(1)),
		'& + &': {
			margin: ifFeature('encore', spacing(1, 0, 0, 0)),
		},
		'&:has(.MuiAccordionSummary-root.Mui-focusVisible)': {
			borderRadius: ifFeature('encore', borderRadiuses[300]),
		},
		'&$expanded': {
			/* @startCleanup encore */
			margin: ifFeature('encore', undefined, 0),
			/* @endCleanup encore */
			// Override margin that MUI adds to expanded accordions
			marginBottom: ifFeature('encore', 0),
		},
	},
	disabled: {},
	expanded: {},
}));

// Theme with card like appearance with gray background on collapsed
// and company colored border on uncollapsed
export const useAccordionCardTheme = makeStyles(({ borderRadiuses, constructs, mixins: { border }, palette, spacing }) => ({
	root: {
		backgroundColor: ifFeature('encore', constructs.surface.neutral.xxWeak, palette.grey[100]),
		borderRadius: ifFeature('encore', borderRadiuses[300], 8),
		'& + &': {
			marginTop: ifFeature('encore', spacing(1), '12px'),
		},
		'&$disabled': {
			// Need to specify the same color here as on `root`
			// to override the disabled backgroundColor set by MUI
			backgroundColor: ifFeature('encore', constructs.surface.neutral.xxWeak),
		},
		'&:has(.MuiAccordionSummary-root.Mui-focusVisible)': {
			backgroundColor: constructs.surface.neutral.inverted,
		},
		'&:hover:not(&$expanded)': {
			// Will change the border color on hover if the accordion is not expanded
			borderColor: ifFeature('encore', constructs.border.neutral.weak),
		},
	},
	expanded: {
		backgroundColor: palette.common.white,
		border: border(1, palette.primary.lighter),
	},
	disabled: {},
}));

// This theme was created for the eNPS pro report to display the
// likes, dislikes, suggestions component. It has white headers when collapsed,
// and gray headers and a gray border when expanded
export const useAccordionCompactTheme = makeStyles(({ borderRadiuses, constructs, spacing, palette }) => ({
	root: {
		maxHeight: '40px',
		transition: 'all .2s ease-in-out',
		border: '0px solid transparent',

		'& + &': {
			marginTop: ifFeature('encore', spacing(1)),
		},
	},
	expanded: {
		margin: '0 0 8px 0!important',
		// this maxHeight has to be a pixel value in order for the transition
		// animation to work. unset and fit-content make the collapsing animation
		// look ugly
		maxHeight: '9999999999px',
		border: `1px solid ${ifFeature('encore', constructs.border.neutral.xWeak, palette.gray[200])}`,
		borderRadius: ifFeature('encore', borderRadiuses[200], '4px'),
		'& $header': {
			backgroundColor: ifFeature('encore', constructs.surface.neutral.xWeak, palette.gray[200]),
			display: 'flex',
			alignItems: 'center',
			borderTopLeftRadius: ifFeature('encore', borderRadiuses[200], '4px'),
			borderTopRightRadius: ifFeature('encore', borderRadiuses[200], '4px'),
		},
		transition: 'all .2s ease-in-out',
	},
	header: {
		padding: '0px 12px',
		minHeight: '40px!important',
	},
	body: {
		padding: '0px 32px',
		margin: '-1px 0px -1px 0px',
	},
}));
