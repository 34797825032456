import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ palette, typography }) => ({
	root: {
		margin: 0,
	},
	disabled: {
		color: palette.gray[500],
	},
	mediumSize: {
		fontSize: typography.medium?.fontSize,
		lineHeight: '18px',
	},
	primary: {
		alignItems: 'center',
		color: palette.gray[900],
		display: 'flex',
		fontWeight: typography.fontWeightRegular,
	},
	primaryActive: {
		color: palette.common.white,
		fontWeight: typography.fontWeightSemibold,
	},
	secondary: {
		color: palette.gray[700],
		fontSize: typography.teenie?.fontSize,
		lineHeight: '17px',
		marginBottom: 2,
		marginTop: 1,
	},
	secondaryActive: {
		color: palette.common.white,
	},
	smallSize: {
		fontSize: typography.small?.fontSize,
		lineHeight: '17px',
	},
}));
