import { Icon } from '@fabric/icon';
import React, { FunctionComponent, PropsWithChildren } from 'react';

import { FullScreenModalTitleProps } from '../../full-screen-modal.types';

import { StyledIcon, StyledRoot } from './styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Headline } from '~components/headline';

export const Title: FunctionComponent<PropsWithChildren<FullScreenModalTitleProps>> = props => {
	const { children, icon, text } = props;

	return (
		<StyledRoot>
			{icon ? <StyledIcon>{typeof icon === 'string' ? <Icon brand={true} name={icon} /> : icon}</StyledIcon> : null}
			{text ? ifFeature('encore', <Headline size="large">{text}</Headline>, <h2>{text}</h2>) : null}
			{children}
		</StyledRoot>
	);
};
