import { makeStyles } from '~styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const useHeaderStyles = makeStyles()(theme => {
	const { breakpoints, palette, spacing } = theme;

	return {
		header: {
			alignItems: 'center',
			borderTopLeftRadius: ifFeature('encore', '16px'),
			borderTopRightRadius: ifFeature('encore', '16px'),
			backgroundColor: ifFeature('encore', palette.common.white, palette.gray[100]),
			borderBottom: ifFeature('encore', `1px solid ${palette.gray[200]}`, `1px solid ${palette.gray[300]}`),
			display: 'flex',
			flexShrink: '0',
			// startCleanup encore
			minHeight: ifFeature('encore', undefined, '80px'),
			// endCleanup encore
			padding: ifFeature('encore', spacing(0.5, 2.5), '0 32px'),
			[breakpoints.down('md')]: {
				padding: spacing(0, 3),
			},
			[breakpoints.down('sm')]: ifFeature('encore', {
				padding: 0,
			}),
		},
	};
});
