import React, { ReactElement } from 'react';
import { CalendarViewProps } from './calendar-view.types';
import { CalendarBase } from '~components/calendar-base';
import { useCalendarViewStyles } from './calendar-view.styles';

export function CalendarView({ biId, id, month, monthsToShow = 1, renderDay }: CalendarViewProps): ReactElement {
	const { classes } = useCalendarViewStyles();

	return (
		<div className={classes.root} data-bi-id={biId} id={id}>
			<CalendarBase hideNavigation={true} month={month} monthsToShow={monthsToShow} renderDay={renderDay} />
		</div>
	);
}

CalendarView.Day = CalendarBase.Day;
