import React, { ReactNode } from 'react';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';

const useStyles = makeStyles(({ palette, spacing, typography }) => ({
	root: {
		display: 'block',
		color: palette.text.secondary,
		fontSize: typography.small?.fontSize,
		marginBottom: spacing(0.5),
	},
}));

export const Label = ({ children, className = '' }: { children: ReactNode; className?: string }) => {
	const classes = useStyles();

	return <span className={classNames(classes.root, className)}>{children}</span>;
};
