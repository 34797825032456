// eslint-disable-next-line no-use-before-define
import React, { FC, PropsWithChildren } from 'react';
import { FormHelperText, Theme } from '@mui/material';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import clsx from 'classnames';
import { NoteProps } from './note-props';

const styles = ({ palette, spacing, typography }: Theme) => {
	const styles = createStyles({
		root: {
			marginTop: 2,
			marginLeft: spacing(1),
		},
		error: {
			color: palette.error.main,
			fill: palette.error.main,
		},
		warning: {
			color: palette.warning.main,
			fill: palette.warning.main,
		},
		info: {
			color: palette.info.main,
			fill: palette.info.main,
		},
	});

	const encoreStyles = createStyles({
		root: {
			color: palette.gray[600],
			fontSize: typography.xsmall?.fontSize,
			lineHeight: typography.xsmall?.lineHeight,
			marginTop: 0,
			marginLeft: 0,
			'&.Mui-disabled, &.Mui-error': {
				color: palette.gray[600],
			},
		},
		error: {},
		warning: {},
		info: {},
	});

	return ifFeature('encore', encoreStyles, styles);
};

export const Note = withStyles(styles)(({ classes, note, disabled, status }: NoteProps & WithStyles<typeof styles>) => {
	if (!note) return null;

	return (
		<FormHelperText
			className={clsx({
				[classes.root]: true,
				[classes.error]: status === 'error',
				[classes.warning]: status === 'warning',
				[classes.info]: status === 'info',
			})}
			disabled={disabled}
			error={status === 'error'}
			variant="standard"
		>
			{note}
		</FormHelperText>
	);
}) as FC<PropsWithChildren<NoteProps>>;
