// eslint-disable-next-line no-use-before-define
import React from 'react';
import classnames from 'classnames';
import { AccordionDetails as MuiAccordionDetails } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AccordionBodyProps } from './accordion.types';

const useStyles = makeStyles(({ spacing }) => ({
	root: {
		display: 'flex',
		padding: spacing(0, 2, 3),
	},
}));

export function AccordionBody({ classes = {}, ...rest }: AccordionBodyProps): JSX.Element {
	const styles = useStyles();
	const { root: rootClass, ...restClasses } = classes;
	return <MuiAccordionDetails classes={{ root: classnames(styles.root, rootClass), ...restClasses }} {...rest} />;
}
