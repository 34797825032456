import React from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Box, BoxProps } from '@mui/material';
import classNames from 'classnames';

import { useStyles } from './tile.styles';

export type TileVariant = 'primary' | 'secondary' | 'placeholder';

export type TileProps = BoxProps & {
	/** Defaults to 'primary' */
	variant?: TileVariant;
	/**
	 * Override to apply active styling to tile when focus-within and hover don't indicate focus on tile related elements
	 *
	 * e.g. Focus of dropdown menu item rendered from within the Tile (but appeneded to the document body) does not
	 * trigger `focus-within` or `hover` states on the Tile to apply desired pseudo-class css.
	 */
	isActive?: boolean;
};

/**
 * @deprecated Use `Tile V2` instead.
 */
export const Tile = ({ variant = 'primary', isActive, ...props }: TileProps) => {
	const styles = useStyles();

	const classes = classNames(styles.root, {
		[styles.primary]: variant === 'primary',
		[styles.secondary]: variant === 'secondary',
		[styles.placeholder]: variant === 'placeholder',
		[styles.active]: isActive,
	});

	return <Box className={classes} height={ifFeature('encore', 184, 148)} width={ifFeature('encore', 200, 224)} {...props} />;
};
