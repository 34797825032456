// eslint-disable-next-line no-use-before-define
import React, { FC, PropsWithChildren, useMemo } from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { roundPercent } from '../common';

export interface CenteredPercentageProps {
	value: number;
	total: number;
}

const useStyles = makeStyles(({ typography }) => ({
	root: {
		fontSize: typography.h3?.fontSize,
		lineHeight: typography.h3?.lineHeight,
		fontWeight: typography.fontWeightHeavy,
	},
}));

export const CenteredPercentage: FC<PropsWithChildren<CenteredPercentageProps>> = ({ value, total }) => {
	const classes = useStyles();
	const percentage = useMemo(() => `${roundPercent((value / total) * 100)}%`, [value, total]);

	return (
		<Typography className={classes.root} color="inherit">
			{percentage}
		</Typography>
	);
};
