import { makeStyles } from '~styles';
import { ChipProps } from './chip.types';

export const useStyles = makeStyles<ChipProps>()((theme, props: ChipProps) => {
	const { borderRadiuses, constructs, spacing, typography } = theme;
	const { onRemove } = props;
	return {
		root: {
			backgroundColor: constructs.surface.neutral.xWeak,
			borderRadius: borderRadiuses[1000],
			color: constructs.text.neutral.xStrong,
			display: 'inline-flex',
			fontSize: typography.xsmall?.fontSize,
			fontWeight: typography.fontWeightMedium,
			lineHeight: typography.xsmall?.lineHeight,
			maxHeight: typography.xsmall?.lineHeight,
			maxWidth: '300px',
			overflow: 'hidden',
			padding: onRemove ? `3px 0 3px ${spacing(1)}` : `3px ${spacing(1)}`,
			width: 'fit-content',

			'&:focus-within': {
				backgroundColor: constructs.surface.neutral.xStrong,
				color: constructs.text.neutral.forcedWhite,
			},
		},

		deleteIcon: {
			backgroundColor: constructs.surface.neutral.xWeak,
			border: 'none',
			display: 'flex',
			fill: constructs.surface.neutral.strong,
			lineHeight: typography.xsmall?.lineHeight,
			marginBottom: 'auto',
			marginTop: 'auto',
			padding: `3px ${spacing(1)}`,

			'&:active, &:focus': {
				backgroundColor: constructs.surface.neutral.xStrong,
				cursor: 'pointer',
				fill: constructs.surface.neutral.forcedWhite,
			},

			'&:focus-visible': {
				outline: 'none',
			},

			'&:hover:not(:focus, :active)': {
				backgroundColor: constructs.surface.neutral.weak,
				cursor: 'pointer',
			},
		},

		label: {},

		hover: {
			backgroundColor: constructs.surface.neutral.weak,
			cursor: 'pointer',
			borderRadius: borderRadiuses[1000],
			color: constructs.text.neutral.xStrong,
			display: 'flex',
			fontSize: typography.xsmall?.fontSize,
			fontWeight: typography.fontWeightMedium,
			lineHeight: typography.xsmall?.lineHeight,
			maxHeight: typography.xsmall?.lineHeight,
			maxWidth: '300px',
			overflow: 'hidden',
			padding: onRemove ? `3px 0 3px ${spacing(1)}` : `3px ${spacing(1)}`,
			width: 'fit-content',

			'&:focus-within, &:active': {
				backgroundColor: constructs.surface.neutral.xStrong,
				color: constructs.text.neutral.forcedWhite,
			},
		},
	};
});
