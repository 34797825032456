/* eslint-disable sort-keys */
import { Theme, alpha } from '@mui/material';
import { createStyles } from '@mui/styles';
import { widths } from '~definitions/json/sizes.json';
import { ifFeature } from '@bamboohr/utils/lib/feature';

import { CSSProperties } from 'react';
import { Size } from '../types';
import { TextAreaProps } from './types';

const xPadding = 1.25;
const heights = {
	teenie: 28,
	small: 32,
	medium: 34,
	large: 36,
	biggie: 42,
};
const yPadding = {
	teenie: 0.875,
	small: 0.9375,
	medium: 0.625,
	large: 0.625,
	biggie: 1.315,
};

export const styles = (theme: Theme) => {
	const { borderRadiuses, mixins, spacing, transitions, palette, typography } = theme;

	function mapSizeToFabricFont(size: Size): CSSProperties {
		const defaultFont = { fontSize: typography.medium.fontSize, lineHeight: typography.medium.lineHeight };
		const sizeMap: Record<Size, CSSProperties> = {
			teenie: typography.small,
			small: typography.small,
			medium: typography.medium,
			large: typography.large,
			biggie: typography.large,
		};
		return sizeMap[size] ?? defaultFont;
	}

	return ifFeature(
		'encore',
		createStyles({
			textAreaRoot: ({ disabled = false, status, viewMode = false }: Partial<TextAreaProps>) => ({
				backgroundColor: disabled ? palette.gray[50] : palette.common.white,
				borderRadius: borderRadiuses[200],
				color: disabled ? palette.gray[600] : palette.gray[800],
				fontFamily: typography.fontFamily,
				padding: `9px ${spacing(2)} 9px ${spacing(1.5)}`, // 9px to match the spacing on the other inputs
				...mixins.inputOutline(theme, { disabled, status, viewMode }),
				transition: transitions.create(['border', 'box-shadow']),
				'&:hover': {
					...mixins.inputOutline(theme, { disabled, hover: true, status, viewMode }),
				},
				'&:focus, &:focus-within': {
					...mixins.inputOutline(theme, { disabled, focused: true, status, viewMode }),
				},
				position: 'relative',
				resize: 'both',
				'&::placeholder': {
					color: palette.gray[600],
					opacity: 1,
				},
				'&.Mui-disabled': {
					color: palette.gray[600],
					WebkitTextFillColor: 'unset',
					'&::placeholder': {
						color: palette.gray[600],
						opacity: 1,
					},
				},
			}),
			denseAdornment: {},
			width: ({ width }: Partial<TextAreaProps>) => ({
				width: !width || width === 100 ? '100%' : widths[width],
			}),
			input: {},
			label: {},
			size: ({ size = 'medium' }) => {
				return { ...mapSizeToFabricFont(size) };
			},
		}),
		createStyles({
			textAreaRoot: ({ disabled = false, size = 'medium', status, viewMode = false }: Partial<TextAreaProps>) => ({
				backgroundColor: palette.common.white,
				borderRadius: 2,
				fontFamily: typography.fontFamily,
				border: (() => {
					if (disabled) {
						return mixins.border(1, palette.grey[300]);
					}
					if (status) {
						return mixins.border(1, mixins.fieldStatusColor(status, palette));
					}
					if (viewMode) {
						return mixins.border(1, palette.grey[300]);
					}
					return mixins.border(1, palette.grey[400]);
				})(),
				transitions: transitions.create(['border-color', 'box-shadow']),

				'&:hover': {
					border: !disabled && !status && viewMode ? mixins.border(1, palette.grey[400]) : undefined,
					boxShadow: disabled ? 'none' : '0 1px 0 0 rgba(0, 0, 0, 0.05)',
				},
				'&:focus-within': {
					borderColor: mixins.fieldStatusColor(status, palette, palette.primary.lighter),
					boxShadow: `${alpha(mixins.fieldStatusColor(status, palette, palette.primary.lighter), 0.25)} 0 0 0 2px`,
				},
				'&>.MuiInputAdornment-positionEnd': {
					marginRight: -10,
				},
				'&>.MuiInputAdornment-positionStart': {
					marginRight: -10,
				},
				'&>.MuiInputAdornment-root': {
					backgroundColor: palette.gray[100],
					height: heights[size] - 2,
					justifyContent: 'center',
					maxHeight: 'unset',
					padding: ifFeature('encore', spacing(1.5), spacing(0, ['large', 'biggie'].includes(size) ? 1 : 0.75)),
				},
				outline: 'none',
			}),
			denseAdornment: {
				'&.MuiInputBase-root>.MuiInputAdornment-root': {
					padding: 0,
				},
			},
			width: ({ width }: Partial<TextAreaProps>) => ({
				width: !width || width === 100 ? '100%' : widths[width],
			}),
			input: {
				boxSizing: 'border-box',
				height: 'unset',
				position: 'relative',
			},
			label: {},
			size: ({ size = 'medium' }) => ({
				fontSize: typography[size]?.fontSize,
				lineHeight: typography[size]?.lineHeight,
				padding: spacing(yPadding[size], xPadding),
			}),
		})
	);
};
