import { heights } from '@fabric/definitions/json/sizes.json';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { withStyles } from '@mui/styles';
import { includes } from 'lodash';
import React, { Children, Component, Fragment, ReactNode } from 'react';

import { ErrorMessage } from './components/error-message.react';
import { FileDropper } from '~components/file-dropper';
import { LayoutBox } from '~components/layout-box';
import { Input } from './components/input.react';
import { List } from './components/list.react';
import { FilesContext } from './context';
import { styles } from './file-upload.styles';
import { addPendingFiles, removeFile, setUploadedFile, updateProgress, updateWithFailures } from './mutators';
import { uploadFile } from './service';
import {
	FileUploadFailure,
	FileUploadFile,
	FileUploadFileMetadata,
	FileUploadProps,
	FileUploadServiceOptions,
	FileUploadState,
} from './types';
import {
	checkIfPdfFileIsEncrypted,
	convertInitialFilesToFilesById,
	getMetadataFromFile,
	getPromiseRejections,
	getTemporaryIds,
	mapMetadataByTemporaryId,
	mapTemporaryIdsByNativeFile,
} from './util';

export class FileUpload extends Component<FileUploadProps, FileUploadState> {
	constructor(props: FileUploadProps) {
		super(props);

		const { initialFiles = [] } = props || {};

		const filesById = convertInitialFilesToFilesById(initialFiles);

		// eslint-disable-next-line react/state-in-constructor
		this.state = {
			allFileIds: Object.keys(filesById),
			failedFileIds: [],
			failuresByFileId: {},
			filesById,
			pendingFileIds: [],
			progressByFileId: {},
			showErrors: false,
		};
	}

	get _service(): (file: File, options: FileUploadServiceOptions) => Promise<FileUploadFile> {
		const { endpoint } = this.props;

		return typeof endpoint === 'function' ? endpoint : uploadFile;
	}

	_getServiceOptions(temporaryId: string): FileUploadServiceOptions {
		const { additionalValues, endpoint } = this.props;

		let url: string | undefined;
		if (typeof endpoint === 'string') {
			url = endpoint;
		} else if (typeof endpoint === 'object') {
			url = endpoint.url;
		}

		return {
			additionalValues,
			name: typeof endpoint === 'object' && endpoint.name ? endpoint.name : undefined,
			onUploadProgress: progress => this._handleUploadProgress(temporaryId, progress),
			url,
		};
	}

	_startUpload(nativeFile: File, temporaryId: string): Promise<void> {
		return this._service(nativeFile, this._getServiceOptions(temporaryId)).then(
			metadata =>
				this._handleUploadSuccess({
					postUploadMetadata: metadata,
					temporaryId,
				}),
			rejection =>
				this._handleUploadFailure({
					nativeFile,
					originalRejection: rejection,
					temporaryId,
				})
		);
	}

	_removeFile = (fileId: string): void => {
		const { onRemove = () => null } = this.props;

		const { filesById, pendingFileIds = [] } = this.state;

		this.setState(removeFile(fileId));

		if (!includes(pendingFileIds, fileId)) {
			onRemove(filesById[fileId]);
		}
	};

	_uploadFiles = (nativeFiles: File[]) => {
		const { onChange = () => null, allowEncryptedPdfs = true } = this.props;
		const temporaryIdsByNativeFile = mapTemporaryIdsByNativeFile(nativeFiles);
		const metadataByTemporaryId = mapMetadataByTemporaryId(nativeFiles, temporaryIdsByNativeFile);
		const temporaryIds = getTemporaryIds(nativeFiles, temporaryIdsByNativeFile).filter((id): id is string => !!id);

		this.setState(addPendingFiles(temporaryIds, metadataByTemporaryId));

		const promises = nativeFiles.map(async nativeFile => {
			const temporaryId = temporaryIdsByNativeFile.get(nativeFile);

			if (temporaryId && !allowEncryptedPdfs && nativeFile.type === 'application/pdf') {
				const isEncrypted = await checkIfPdfFileIsEncrypted(nativeFile);

				if (isEncrypted) {
					return this._handleUploadFailure({
						nativeFile,
						originalRejection: {
							message: '',
							errorType: 'encrypted_pdf_not_allowed',
						},
						temporaryId,
					});
				}
			}

			// eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
			return temporaryId ? this._startUpload(nativeFile, temporaryId) : Promise.reject();
		});

		onChange(nativeFiles, promises);

		// eslint-disable-next-line no-void
		void this._handleUploadFailures(promises);
	};

	_renameFile = (file: FileUploadFileMetadata, fileId: string): void => {
		const { onRename, canRename } = this.props;
		const handleRename = (name: string) => {
			if (!name) {
				return;
			}
			const { filesById } = this.state;

			if (filesById[fileId]) {
				const fileCopy = { ...filesById[fileId], name };
				const filesCopy = { ...filesById, [fileId]: fileCopy };

				this.setState({
					filesById: filesCopy,
				});
			}
		};

		if (onRename && canRename) {
			onRename(file, handleRename);
		}
	};

	_handleUploadFailure = ({ nativeFile, originalRejection, temporaryId }: FileUploadFailure): Promise<void> => {
		const { onError = () => null } = this.props;

		this.setState(removeFile(temporaryId));

		onError(getMetadataFromFile(nativeFile));

		// eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
		return Promise.reject({ ...originalRejection, temporaryId, nativeFile });
	};

	_handleUploadFailures = async (promises: Promise<void>[]): Promise<void> => {
		const { hasCustomErrorHandler } = this.props;

		const rejections = await getPromiseRejections(promises);

		if (rejections.length > 0 && !hasCustomErrorHandler) {
			this.setState(updateWithFailures(rejections));
		}
	};

	_handleUploadProgress = (temporaryId: string, progress: number): void => {
		this.setState(updateProgress(temporaryId, progress));
	};

	_handleUploadSuccess = ({
		temporaryId,
		postUploadMetadata,
	}: {
		temporaryId: string;
		postUploadMetadata: FileUploadFile;
	}): void => {
		const { onUpload = () => null } = this.props;
		const { pendingFileIds } = this.state;

		if (includes(pendingFileIds, temporaryId)) {
			this.setState(setUploadedFile(temporaryId, postUploadMetadata));
			onUpload(postUploadMetadata);
		}
	};

	render(): ReactNode {
		const {
			acceptedTypes = [],
			biId = '',
			canDragDrop = false,
			canRename = false,
			canSelectMultiple = false,
			children,
			classes = {},
			fileDropperProps = {},
			inputRef,
			isDisabled = false,
			isRequired,
			name,
			size = 'medium',
		} = this.props;

		const { allFileIds, failuresByFileId, failedFileIds, filesById, pendingFileIds, progressByFileId, showErrors } = this.state;

		const value = {
			allFileIds,
			biId,
			canRename,
			acceptedTypes,
			canSelectMultiple,
			failedFileIds,
			filesById,
			inputRef,
			isDisabled,
			isRequired,
			name,
			pendingFileIds,
			progressByFileId,
			removeFile: this._removeFile,
			upload: this._uploadFiles,
			renameFile: this._renameFile,
		};

		const content =
			Children.count(children) > 0
				? children
				: ifFeature(
						'encore',
						<LayoutBox data-bi-id={biId} display="inline-block" minHeight={heights[size]}>
							<List />
							<Input />
						</LayoutBox>,
						<div className={classes.root} data-bi-id={biId} style={{ minHeight: heights[size] }}>
							<Fragment>
								<List />
								<Input />
							</Fragment>
						</div>
					);

		return (
			<FilesContext.Provider value={value}>
				{canDragDrop ? (
					<FileDropper
						{...fileDropperProps}
						acceptedTypes={acceptedTypes}
						canSelectMultiple={canSelectMultiple}
						onFileDrop={files => {
							// eslint-disable-next-line no-unused-expressions
							fileDropperProps.onFileDrop?.(files);
							this._uploadFiles(files);
						}}
						permission={(canSelectMultiple || allFileIds.length === 0) && fileDropperProps.permission !== false}
					>
						{content}
					</FileDropper>
				) : (
					content
				)}

				<ErrorMessage
					canSelectMultiple={canSelectMultiple}
					failedFileIds={failedFileIds}
					failuresByFileId={failuresByFileId}
					isVisible={showErrors}
					onDismiss={() => this.setState({ showErrors: false })}
				/>
			</FilesContext.Provider>
		);
	}
}

export default withStyles(styles)(FileUpload);
