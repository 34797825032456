import { Theme, createStyles } from '@mui/material';
import { AdornedTextProps } from './types';

export const styles = ({ palette, typography, spacing }: Theme) =>
	createStyles({
		root: {
			color: palette.gray[1000],
			fontSize: typography.large.fontSize,
			fontWeight: typography.fontWeightSemibold,
			lineHeight: typography.medium.lineHeight,
			margin: '0 0 20px',
		},
		noMargin: {
			marginBottom: 0,
		},
		adornment: ({ AdornedProps, verticalLayout }: Partial<AdornedTextProps>) => {
			let marginRight = AdornedProps ? spacing(AdornedProps.spacing) : spacing(1);
			if (verticalLayout) {
				marginRight = '0';
			}
			return {
				display: 'flex', // prevents inline child from having gap at bottom
				fill: palette.primary.main,
				marginRight,
			};
		},
		content: ({ ContentProps }: Partial<AdornedTextProps>) => ({
			marginLeft: ContentProps ? spacing(ContentProps.spacing) : spacing(0),
		}),
	});
