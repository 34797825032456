/* eslint-disable react/forbid-component-props */
// eslint-disable-next-line no-use-before-define
import React, { useCallback, ReactElement } from 'react';
import ClassNames from 'classnames';
import { motion } from 'framer-motion';
import { makeStyles, useTheme } from '@mui/styles';

import { PieSectionProps } from '../types';
import { useAnimatedPieSectionPath } from './use-animated-pie-section-path';
import { pieSectionTransition } from './constants';

export const PieSection = <Datum = unknown,>({
	animate = true,
	animateInitial = true,
	ariaHidden,
	className,
	datum,
	label,
	path,
	onClick,
	onMouseMove,
	onMouseOut,
	state,
	fill,
	arc,
	...rest
}: PieSectionProps<Datum>): ReactElement => {
	const classes = makeStyles(({ palette }) => ({
		section: {
			'&:focus': {
				filter: `drop-shadow(0 0 4px ${palette.primary.lightest})`,
				outline: 'none',
			},
		},
	}))();

	const handleMouseMove = useCallback(
		e => {
			if (onMouseMove) {
				onMouseMove(e, datum);
			}
		},
		[datum, onMouseMove]
	);

	const handleMouseOut = useCallback(
		e => {
			if (onMouseOut) {
				onMouseOut(e);
			}
		},
		[onMouseOut]
	);

	const handleClick = useCallback(
		e => {
			if (onClick) {
				onClick(e, datum);
			}
		},
		[datum, onClick]
	);

	const {
		mixins: { lighten },
	} = useTheme();

	let animation = 'idle';
	if (animate) {
		animation = state || 'idle';
	}

	const d = useAnimatedPieSectionPath(path, arc);

	const animations = {
		initial: {
			scale: 0,
			opacity: 0,
		},
		idle: {
			scale: 1,
			opacity: 1,
		},
		inactive: {
			fill: lighten(fill, 0.45),
			stroke: lighten(fill, 0.45),
			scale: 0.95,
			opacity: 1,
		},
		active: {
			scale: 1,
			opacity: 1,
		},
		dim: {
			fill: lighten(fill, 0.45),
			stroke: lighten(fill, 0.45),
			scale: 1,
			opacity: 1,
		},
	};

	return (
		<motion.path
			{...rest}
			animate={animation}
			className={ClassNames(className, classes.section)}
			d={d}
			fill={fill}
			initial={animateInitial ? 'initial' : 'idle'}
			onClick={handleClick}
			onMouseMove={handleMouseMove}
			onMouseOut={handleMouseOut}
			stroke={fill}
			transition={pieSectionTransition}
			variants={animations}
			{...{
				'aria-hidden': ariaHidden,
				onBlur: handleMouseOut,
				onFocus: handleMouseMove,
				tabIndex: animate && !ariaHidden ? 0 : undefined,
				title: label,
				zIndex: animation === 'active' ? 1 : 0,
			}}
		/>
	);
};
