// eslint-disable-next-line no-use-before-define
import React from 'react';
import classnames from 'classnames';
import { Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { RoundedBoxProps } from './types';
import { dyeColors, ModeOptions } from '~styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';

const useStyles = makeStyles(({ palette, mode }) => ({
	root: {
		backgroundColor: ({ backgroundColor }: RoundedBoxProps) => backgroundColor || palette.gray[100],
		border: ({ border }: RoundedBoxProps) => border || 'none',
		borderRadius: ({ borderRadius }: RoundedBoxProps) => ifFeature('encore', 16, borderRadius || 8),
		margin: ({ margin }: RoundedBoxProps) => margin,
		outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
		padding: ({ padding }: RoundedBoxProps) => ifFeature('encore', '20px', padding),
	},
}));

/**
 * @deprecated RoundedBox should no longer be used. Instead, StyledBox is the preferred option.
 */
export const RoundedBox = ({
	backgroundColor,
	border,
	borderRadius,
	children,
	className,
	margin,
	padding,
	...rest
}: RoundedBoxProps) => {
	const classes = useStyles({ backgroundColor, border, borderRadius, margin, padding });
	return (
		<Paper className={classnames(classes.root, className)} elevation={0} {...rest}>
			{children}
		</Paper>
	);
};
