/* @startCleanup encore */
import { createStyles, makeStyles } from '@mui/styles';

export const useOverrideStyles = makeStyles(() => {
	return createStyles({
		root: {
			'& .MuiInputBase-root': {
				display: 'flex',
				flexWrap: 'wrap',
				padding: '0 24px 0 0',
			},
		},
		input: {
			lineHeight: '21px',
		},
	});
});

export const useAdditionalStyles = makeStyles(theme => {
	const { palette } = theme;

	return createStyles({
		clearIcon: {
			alignItems: 'center',
			display: 'flex',
			fill: palette.grey[500],
			justifyContent: 'center',
			'&:hover': {
				fill: palette.grey[700],
			},
		},
	});
});
/* @endCleanup encore */
