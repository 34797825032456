import { ifFeature } from '@bamboohr/utils/lib/feature';
import { makeStyles } from '~styles';

export const signatureFont = '4rem "Ephesis"';

export const useStyles = makeStyles()(({ palette, spacing, constructs }) => {
	return {
		clear: {
			position: 'absolute',
			right: spacing(3),
			top: spacing(2),
		},
		container: {
			alignItems: 'flex-end',
			display: 'flex',
			height: '100%',
			padding: `${spacing(6)} 10px 10px 10px`,
		},
		input: {
			background: 'none',
			border: 'none',
			borderRadius: spacing(1),
			font: signatureFont,
			height: '100%',
			padding: `${spacing(5)} ${spacing(2)} 13px ${spacing(2)}`,
			textIndent: '29px',
			width: '100%',
			'&:focus': {
				outline: `2px solid ${palette.primary.lightest}`,
			},
		},
		placeholder: {
			bottom: 0,
			color: ifFeature('encore', constructs.text.neutral.xStrong, palette.gray[700]),
			lineHeight: spacing(3),
			padding: `${spacing(5)} 55px`,
			pointerEvents: 'none', // Prevent element from being interactive
			position: 'absolute',
		},
		root: {
			height: '100%',
		},
	};
});
