import React, { FC, PropsWithChildren } from 'react';
import { GutterGroup } from './gutter-group';
import { useStyles } from './styles';
import { getGutterGroups } from '../etc/get-gutter-groups';
import { GutterGroupsProps } from '../types';

export const GutterGroups: FC<PropsWithChildren<GutterGroupsProps>> = props => {
	const classes = useStyles(props);

	const { getGroup, renderGroup, size, virtualItems } = props;

	const gutterGroups = getGutterGroups(virtualItems, getGroup);

	return (
		<div className={classes.root}>
			{gutterGroups.map((gutterGroup, index) => {
				return (
					<GutterGroup
						className={index !== 0 ? classes.gutterGroupSeparator : ''}
						key={gutterGroup.name}
						renderGroup={renderGroup}
						size={size}
						{...gutterGroup}
					/>
				);
			})}
		</div>
	);
};
