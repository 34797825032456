import { createStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { mediumScreenWidth } from '@fabric/definitions/json/mobile.json';
import { ActionFooterProps } from './types';
import { dyeColors, ModeOptions } from '~styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const styles = ({ palette, breakpoints, mode, mixins, constructs, layout }: Theme) =>
	createStyles({
		root: {
			/* @startCleanup encore */
			minHeight: ifFeature('encore', undefined, 92),
			borderColor: ifFeature('encore', undefined, palette.grey[300]),
			/* @endCleanup encore */
			width: '100%',
			backgroundColor: ifFeature(
				'encore',
				({ dark }: ActionFooterProps) => (dark ? constructs.surface.neutral.xxStrong : constructs.surface.neutral.xxWeak),
				({ dark }: ActionFooterProps) => (dark ? 'black' : palette.grey[100])
			),
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
			borderTop: ifFeature('encore', '1px solid transparent', 'solid 1px'),
			boxShadow: ifFeature('encore', '0 0 transparent'),
			transition: 'border-top 0.3s ease, box-shadow 0.3s ease',
			[breakpoints.down(mediumScreenWidth)]: {
				flexDirection: ifFeature('encore', 'row', 'column'),
			},
		},
		rootBorder: {
			borderTop: ({ borderTop }) =>
				borderTop ? ifFeature('encore', `1px solid ${mixins.alpha(constructs.border.neutral.strong, 0.2)}`) : undefined,
			boxShadow: ({ borderTop }) =>
				borderTop ? ifFeature('encore', `0 -2px 1px -1px ${mixins.alpha(constructs.border.neutral.strong, 0.05)}`) : undefined,
		},
		content: {
			display: 'flex',
			/* @startCleanup encore */
			margin: ifFeature('encore', undefined, '0 auto'),
			/* @endCleanup encore */
			maxWidth: ifFeature('encore', 'none', 1348),
			justifyContent: 'space-between',
			[breakpoints.down(mediumScreenWidth)]: {
				flexDirection: 'column',
			},
			alignItems: ifFeature('encore', 'center'),
			padding: ifFeature('encore', `${layout.pageCapsule.padding}px`),
		},
		left: {
			display: 'flex',
			[breakpoints.down(mediumScreenWidth)]: {
				justifyContent: 'center',
				flexDirection: ifFeature('encore', 'row', 'column'),
			},
		},
		actions: {
			color: ({ dark }: ActionFooterProps) => (dark ? 'white' : 'black'),
			/* @startCleanup encore */
			padding: ifFeature('encore', undefined, '24px 16px 32px 32px'),
			/* @endCleanup encore */
			display: ifFeature('encore', 'flex'),
			alignItems: ifFeature('encore', 'center'),
			gap: ifFeature('encore', '16px'),
			[breakpoints.down(mediumScreenWidth)]: {
				display: 'flex',
				flexDirection: ifFeature('encore', 'row', 'column'),
				flexWrap: ifFeature('encore', 'wrap'),
				justifyContent: 'center',
				width: 'auto',
				/* @startCleanup encore */
				padding: ifFeature('encore', undefined, '24px 32px 0px 32px'),
				/* @endCleanup encore */
				'& button': {
					marginBottom: '10px',
					marginLeft: '0',
				},
			},
		},
		children: {
			color: ({ dark }: ActionFooterProps) => (dark ? 'white' : 'black'),
			padding: ifFeature('encore', '0 0 0 32px', '30px 0 47px 32px'),
			alignSelf: ifFeature('encore', 'center'),
			[breakpoints.down(mediumScreenWidth)]: {
				flexDirection: ifFeature('encore', 'row', 'column'),
				height: 'auto',
				padding: ifFeature('encore', '0', '30px 0 47px 32px'),
			},
		},
		right: {
			/* @startCleanup encore */
			padding: ifFeature('encore', undefined, '31px 32px 45px 0'),
			/* @endCleanup encore */
			[breakpoints.down(mediumScreenWidth)]: {
				justifyContent: 'center',
				margin: '0 auto',
				padding: ifFeature('encore', '0', '0 0 32px'),
			},
		},
		icon: {
			fill: ({ dark }: ActionFooterProps) => (dark ? 'white' : palette.grey[800]),
		},
	});
