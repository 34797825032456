import { createStyles, makeStyles } from '@mui/styles';
import { dyeColors, ModeOptions } from '~styles';
import { DatePickerCalendarRangeStyleProps } from '../../types';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const useStyles = makeStyles(theme => {
	const { borderRadiuses, constructs, mixins, mode, palette, spacing } = theme;

	return createStyles({
		calendars: {
			display: 'flex',
			width: '100%',
			justifyContent: ifFeature('encore', 'space-evenly'),
		},
		endPicker: {
			// @startCleanup encore
			borderLeft: ifFeature('encore', undefined, mixins.border(1, palette.grey[400])),
			// @endCleanup encore
			'& .PrivatePickersFadeTransitionGroup-root': {
				'&>div>div>div:first-of-type': {
					borderLeft: ifFeature('encore', mixins.border(1, constructs.border.neutral.xWeak)),
				},
			},
			'& .PrivatePickersSlideTransition-root': {
				'&>div:first-of-type': {
					borderLeft: ifFeature('encore', mixins.border(1, constructs.border.neutral.xWeak)),
				},
			},
		},
		hidden: {
			visibility: 'hidden',
		},
		picker: {
			margin: ifFeature('encore', spacing(0.5)),
			borderRadius: ifFeature('encore', borderRadiuses[200]),
			backgroundColor: palette.grey[100],
			overflow: 'hidden',
			position: 'relative',
			'& .MuiPickersBasePicker-pickerView': {
				padding: 0,
				minHeight: 0,
			},
			'& .MuiPickersCalendar-transitionContainer': {
				backgroundColor: palette.common.white,
				marginTop: 10,
				minHeight: (props: DatePickerCalendarRangeStyleProps) => 12 + props.maxWeekRowsCount * 32,
				transition: 'min-height 100ms',
			},
			'& .MuiPickersCalendar-transitionContainer > div': {
				top: 8,
			},
			'& .MuiPickersCalendarHeader-transitionContainer': {
				display: 'none',
			},
			width: 616,
		},
		root: {
			backgroundColor: mode === ModeOptions.Dye ? dyeColors.main : undefined,
			display: 'flex',
			width: ifFeature('encore', 633, 616),
		},
		startPicker: {},
	});
});
