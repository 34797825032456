import { makeStyles } from '@mui/styles';
import React, { FC, forwardRef, PropsWithChildren } from 'react';
import { BamboohrLogo115x17 } from '@bamboohr/grim';

import { useStyles } from './footer.styles';
import { Legal } from './legal';
import { Copyright } from './copyright';
import { FooterProps } from './types';

const useIconStyles = makeStyles(({ palette }) => ({
	icon: {
		fill: palette.grey[800],
	},
}));

/**
 * @deprecated We no longer need/want a global footer.
 */
export const Footer = forwardRef<HTMLDivElement, FooterProps>(({ classes: classesProp, children, right }, ref) => {
	const styles = useIconStyles();
	const classes = useStyles({ classes: classesProp });

	const defaultChildren = (
		<div className={classes.defaultChildren}>
			<div>
				<Legal />
			</div>
			<span>&nbsp;&nbsp;·&nbsp;&nbsp;</span>
			<Copyright />
		</div>
	);

	const defaultRight = (
		<div className={styles.icon}>
			<BamboohrLogo115x17 />
		</div>
	);

	return (
		<div className={classes.root} ref={ref} role="contentinfo">
			<div className={classes.guts}>
				<div className={classes.left}>{typeof children === 'undefined' || children === '' ? defaultChildren : children}</div>
				<div className={classes.right}>{typeof right === 'undefined' || right === '' ? defaultRight : right}</div>
			</div>
		</div>
	);
});

export const FooterStorybook = Footer as FC<PropsWithChildren<FooterProps>>;
