import { makeStyles } from '~styles';

export const useDayStyles = makeStyles<void, 'prominent'>()((
	{ borderRadiuses, constructs, mixins, spacing, typography },
	props,
	classes
) => {
	return {
		root: {
			background: 'none',
			border: '1px solid transparent',
			borderRadius: borderRadiuses[200],
			boxSizing: 'border-box',
			color: constructs.text.neutral.xStrong,
			fontSize: typography.small.fontSize,
			fontWeight: typography.fontWeightMedium,
			height: spacing(5),
			lineHeight: spacing(5),
			padding: 0,
			textAlign: 'center',
			transition: 'all 200ms ease',
			width: spacing(5),
			'&:focus-visible': {
				borderColor: constructs.border.primary.medium,
				boxShadow: `0px 0px 0px 4px ${mixins.alpha(constructs.border.primary.medium, 0.1)}`,
				outline: `1px solid ${constructs.border.primary.medium}`,
			},
		},
		hoverable: {
			cursor: 'pointer',
			'&:hover': {
				boxShadow: mixins.boxShadow('300', constructs.surface.neutral.xxStrong),
			},
		},
		highlight: {
			backgroundColor: constructs.surface.neutral.weak,
			fontWeight: typography.fontWeightRegular,
		},
		prominent: {
			backgroundColor: constructs.surface.primary.strong,
			color: constructs.text.neutral.inverted,
			fontWeight: typography.fontWeightBold,
		},
		today: {
			backgroundColor: 'transparent',
			border: `1px solid ${constructs.border.neutral.weak}`,
			fontWeight: typography.fontWeightSemibold,
			[`&.${classes.prominent}`]: {
				backgroundColor: constructs.surface.primary.weak,
				borderColor: constructs.border.primary.strong,
				color: constructs.text.primary.strong,
			},
		},
	};
});
