import { ifFeature } from '@bamboohr/utils/lib/feature';
import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ spacing }) =>
	createStyles({
		checkbox: {
			'&:nth-of-type(n+2)': {
				marginTop: ifFeature('encore', spacing(1.5), 8),
			},
		},
		root: {},
	})
);
