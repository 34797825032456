import React, { FC, forwardRef, useRef, RefObject, ReactElement, PropsWithChildren } from 'react';
import { BamboohrLogo115x17 } from '@bamboohr/grim';
import { useScreenSizes as useFabricScreenSizes } from '@fabric/utils/hooks';
import { ActionFooterProps } from './types';
import { styles } from './action-footer.styles';
import { withStyles, WithStyles } from '@mui/styles';
import { ButtonProps } from '@fabric/button';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const ActionFooter = withStyles(styles)(
	forwardRef<HTMLDivElement, ActionFooterProps & WithStyles<typeof styles>>((props, ref: RefObject<HTMLDivElement>) => {
		const localRef = useRef<HTMLDivElement>(null);
		ref = ref ?? localRef;

		const { isMediumScreen } = useFabricScreenSizes(true);
		const defaultRight = ifFeature(
			'encore',
			null,
			<div className={props.classes.icon}>
				<BamboohrLogo115x17 />
			</div>
		);

		const childElement = props.children ? <div className={props.classes.children}>{props.children}</div> : null;

		const actions = props.actions.map(button => {
			const { children } = button.props as ButtonProps;
			return React.cloneElement(
				button as ReactElement<ButtonProps>,
				{ dark: props.dark, size: 'large', noSpacing: isMediumScreen, ...button.props } as ButtonProps,
				children
			);
		});

		const classes = `${props.classes.root} ${props.borderTop ? props.classes.rootBorder : ''}`;

		return (
			<div className={classes} ref={ref}>
				<div className={props.classes.content}>
					<div className={props.classes.left}>
						<div className={props.classes.actions}>{actions}</div>
						{childElement}
					</div>
					<div className={props.classes.right}>{typeof props.right !== 'undefined' ? props.right : defaultRight}</div>
				</div>
			</div>
		);
	})
);

export const ActionFooterStorybook = ActionFooter as FC<PropsWithChildren<ActionFooterProps>>;
