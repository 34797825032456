import React, { Children, cloneElement, FC, forwardRef, isValidElement, PropsWithChildren } from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { ButtonGroup as MuiButtonGroup } from '@mui/material';
import { withStyles, WithStyles } from '@mui/styles';
import clsx from 'classnames';
import { Button } from '../button';
import { ButtonProps as ButtonPropsType, ButtonGroupProps } from '../../types';
import { styles } from './button-group.styles';

export const ButtonGroup = withStyles(styles)(
	forwardRef<HTMLDivElement, ButtonGroupProps & WithStyles<typeof styles>>(
		(
			{
				ButtonProps,
				children,
				classes,
				color: colorProp = 'secondary',
				dark,
				disabled,
				processing,
				size,
				toggle,
				variant: variantProp = 'contained',
				...rest
			},
			ref
		) => {
			const appliedColor = toggle ? 'secondary' : colorProp;

			return (
				<MuiButtonGroup
					classes={{
						root: clsx(classes.root, {
							[classes.altStyling]: dark,
						}),
						grouped: classes.grouped,
						groupedHorizontal: classes.groupedHorizontal,
						groupedContainedHorizontal: classes.groupedContainedHorizontal,
						groupedContained: classes.groupedContained,
						groupedContainedPrimary: classes.groupedContainedPrimary,
						groupedContainedSecondary: classes.groupedContainedSecondary,
						groupedOutlinedPrimary: classes.groupedOutlined,
						groupedOutlinedSecondary: classes.groupedOutlined,
					}}
					color={colorProp}
					disabled={disabled}
					ref={ref}
					role={ifFeature('encore', toggle === 'single' ? 'radiogroup' : 'group', 'group')}
					variant={variantProp}
					{...rest}
				>
					{Children.map(children, child => {
						if (!isValidElement(child)) {
							return null;
						}

						const elementChild = child as React.ReactElement<ButtonPropsType>;
						if (elementChild.type === Button) {
							return cloneElement<ButtonPropsType>(elementChild, {
								...ButtonProps,
								'aria-checked': ifFeature(
									'encore',
									toggle ? elementChild.props?.toggled : elementChild.props?.['aria-checked'],
									elementChild.props?.['aria-checked']
								),
								color: ifFeature('encore', elementChild.props?.toggled ? 'primary' : appliedColor, colorProp),
								dark: elementChild.props.dark || dark,
								disabled: elementChild.props.disabled || disabled,
								processing: elementChild.props?.processing || processing,
								role: ifFeature(
									'encore',
									toggle === 'single' ? 'radio' : elementChild.props?.role,
									elementChild.props?.role
								),
								size: elementChild.props?.size || size,
								variant: elementChild.props?.variant || variantProp,
							} as ButtonPropsType);
						}
						return child;
					})}
				</MuiButtonGroup>
			);
		}
	)
);

export const ButtonGroupStorybook = ButtonGroup as FC<PropsWithChildren<ButtonGroupProps>>;
