import { ifFeature } from '@bamboohr/utils/lib/feature';
import { createStyles, makeStyles } from '@mui/styles';
import { dyeColors, ModeOptions } from '~styles';
import { getCheckboxToLabelMargin, getPixelsFromSize, getSelectedIconScaleFromSize } from './checkbox.domain';
import { CheckboxStyleProps } from './types';

const boxShadowInset = 'inset 0 2px 2px 0 rgba(0, 0, 0, 0.15)';

export const useStyles = makeStyles(theme => {
	const { constructs, palette, mixins, typography, mode } = theme;
	const encoreFocusInsetShadow = `inset 1px 1px 0px 1px ${mixins.alpha(palette.grey[900], 0.05)}`;

	return createStyles({
		asterisk: {
			color: (props: CheckboxStyleProps) => (props.disabled ? palette.grey[600] : palette.primary.main),
			marginLeft: 4,
		},
		error: {
			color: theme.palette.error.main,
		},
		label: {
			alignItems: 'center',
			display: 'flex',
			marginLeft: (props: CheckboxStyleProps) => getCheckboxToLabelMargin(props.size),
		},
		labelContainer: {
			display: 'flex',
		},
		labelContent: {
			color: (props: CheckboxStyleProps) => {
				if (mode === ModeOptions.Dye) {
					return dyeColors.main;
				}
				if (props.disabled) {
					return ifFeature('encore', palette.grey[600], palette.grey[500]);
				}
				if (props.status === 'error') {
					return palette.error.main;
				}
				if (props.status === 'warning') {
					return palette.warning.main;
				}
				return ifFeature('encore', constructs.text.neutral.strong, palette.grey[1000]);
			},
			fontSize: (props: CheckboxStyleProps) => typography.fabricFontSize(props.size),
			fontStyle: (props: CheckboxStyleProps) => (props.status === 'pending' ? 'italic' : 'normal'),
			fontWeight: (props: CheckboxStyleProps) =>
				props.checked || props.indeterminate ? typography.fontWeightSemibold : typography.fontWeightRegular,
			lineHeight: (props: CheckboxStyleProps) => typography.fabricLineHeight(props.size),
		},
		labelRoot: {
			marginLeft: ifFeature('encore', 0),
			marginRight: ifFeature('encore', 0),
		},
		note: {
			marginLeft: (props: CheckboxStyleProps) => getPixelsFromSize(props.size) + getCheckboxToLabelMargin(props.size),
			fontSize: typography.fabricFontSize('teenie'),
			color: palette.grey[700],
			lineHeight: typography.fabricLineHeight('teenie'),
		},
		root: {
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
			// MUI should use the base color we are using
			'& .MuiCheckbox-root': {
				alignSelf: ifFeature('encore', 'start'),
				backgroundColor: ifFeature('encore', 'unset'),
				color: 'inherit',
				height: ifFeature('encore', 'auto'),
				padding: ifFeature('encore', 0),
				position: ifFeature('encore', 'relative'),
				top: ifFeature('encore', (props: CheckboxStyleProps) =>
					props.size === 'small' || props.size === 'medium' ? '3px' : '2px'
				),
			},
			// MUI provides a default asterisk that we don't want to show
			'& .MuiFormControlLabel-asterisk': {
				display: 'none',
			},
		},
		selectedBox: {
			alignItems: 'center',
			backgroundColor: (props: CheckboxStyleProps) => {
				if (mode === ModeOptions.Dye) {
					return dyeColors.main;
				}
				if (props.status === 'error') {
					return palette.error.main;
				}
				if (props.status === 'warning') {
					return palette.warning.main;
				}
				return palette.primary.main;
			},
			border: (props: CheckboxStyleProps) => {
				let color = palette.primary.main;
				if (props.status === 'warning') {
					color = palette.warning.main;
				}
				if (props.status === 'error') {
					color = palette.error.main;
				}
				return `1px solid ${color}`;
			},
			borderRadius: 4,
			boxShadow: 'none',
			boxSizing: 'border-box',
			display: 'flex',
			height: (props: CheckboxStyleProps) => getPixelsFromSize(props.size),
			justifyContent: 'center',
			opacity: 0,
			position: 'absolute',
			transform: 'scale(0)',
			transition: 'transform 200ms ease, opacity 200ms ease, border-radius 200ms ease',
			width: (props: CheckboxStyleProps) => getPixelsFromSize(props.size),
			'.MuiCheckbox-root.Mui-checked &': {
				borderRadius: ifFeature('encore', 4, 2),
				boxShadow: ifFeature(
					'encore',
					`1px 1px 0px 0px ${mixins.alpha(palette.grey[900], 0.06)}`,
					'0 1px 0 0 rgba(0, 0, 0, 0.05)'
				),
				opacity: 1,
				transform: 'scale(1)',
			},
			'.MuiCheckbox-root.MuiCheckbox-indeterminate &': {
				opacity: ifFeature('encore', 1, 0),
				transform: ifFeature('encore', 'scale(1)', 'scale(0)'),
			},
			'.MuiCheckbox-root.Mui-disabled &': {
				backgroundColor: ifFeature('encore', palette.grey[400], palette.grey[500]),
				border: `1px solid ${ifFeature('encore', palette.grey[400], palette.grey[500])}`,
				boxShadow: 'none',
			},
			'.MuiCheckbox-root.Mui-disabled.Mui-checked &': {
				boxShadow: ifFeature('encore', `1px 1px 0px 0px ${mixins.alpha(palette.grey[900], 0.06)}`),
			},
			'$root:focus-within &': {
				boxShadow: ifFeature('encore', `0px 0px 0px 3px ${palette.primary.lightest}`),
			},
			'$warning:focus-within &': {
				boxShadow: ifFeature('encore', `0px 0px 0px 3px ${palette.warning.light}`),
			},
			'$error:focus-within &': {
				boxShadow: ifFeature('encore', `0px 0px 0px 3px ${palette.error.light}`),
			},
		},
		selectedIcon: {
			display: 'flex',
			filter: 'drop-shadow(0 1px 1px rgba(0, 0, 0, 0.1))',
			fill: (props: CheckboxStyleProps) => {
				if (props.indeterminate) {
					if (props.disabled) {
						return ifFeature('encore', palette.primary.contrastText, palette.grey[500]);
					}
					if (props.status === 'error') {
						return ifFeature('encore', palette.primary.contrastText, palette.error.main);
					}
					if (props.status === 'warning') {
						return ifFeature('encore', palette.primary.contrastText, palette.warning.main);
					}
					return ifFeature('encore', palette.primary.contrastText, palette.primary.main);
				}

				return palette.primary.contrastText;
			},
			position: 'absolute',
			transform: (props: CheckboxStyleProps) =>
				props.checked || props.indeterminate ? `scale(${getSelectedIconScaleFromSize(props.size)})` : 'scale(0)',
			transition: 'transform 100ms ease 100ms',
		},
		statusIcon: {
			display: 'flex',
			fill: (props: CheckboxStyleProps) => {
				if (props.disabled) {
					return palette.grey[500];
				}
				if (props.status === 'error') {
					return palette.error.main;
				}
				if (props.status === 'warning') {
					return palette.warning.main;
				}
				return palette.grey[1000];
			},
			marginRight: 6,
		},
		unselectedBox: {
			alignItems: 'center',
			backgroundColor: ifFeature('encore', palette.grey[100], palette.common.white),
			border: `1px solid ${ifFeature('encore', palette.grey[400], palette.grey[500])}`,
			borderRadius: ifFeature('encore', 4, 2),
			boxShadow: ifFeature('encore', `inset 1px 1px 0px 1px ${mixins.alpha(palette.grey[900], 0.05)}`, boxShadowInset),
			boxSizing: 'border-box',
			display: 'flex',
			height: (props: CheckboxStyleProps) => getPixelsFromSize(props.size),
			justifyContent: 'center',
			position: 'relative',
			transition: 'border-radius 200ms ease',
			width: (props: CheckboxStyleProps) => getPixelsFromSize(props.size),
			'.MuiCheckbox-root.Mui-disabled &': {
				border: `1px solid ${ifFeature('encore', palette.grey[200], palette.grey[300])}`,
				boxShadow: 'none',
			},
			'$warning &, $error &, $root:focus-within &': {
				borderColor: 'currentColor',
			},
			'$root:focus-within &': {
				boxShadow: ifFeature(
					'encore',
					`${encoreFocusInsetShadow}, 0px 0px 0px 3px ${palette.primary.lightest}`,
					`${boxShadowInset}, 0 0 0 2px ${mixins.alpha(palette.primary.lighter, 0.35)}`
				),
				border: `1px solid ${ifFeature('encore', palette.grey[400])}`,
			},
			'$warning:focus-within &': {
				boxShadow: ifFeature(
					'encore',
					`${encoreFocusInsetShadow}, 0px 0px 0px 3px ${palette.warning.light}`,
					`${boxShadowInset}, 0 0 0 2px ${mixins.alpha(palette.warning.light, 0.15)}`
				),
				border: ifFeature('encore', `1px solid ${palette.warning.main}`),
			},
			'$error:focus-within &': {
				boxShadow: ifFeature(
					'encore',
					`${encoreFocusInsetShadow}, 0px 0px 0px 3px ${palette.error.light}`,
					`${boxShadowInset}, 0 0 0 2px ${mixins.alpha(palette.error.light, 0.15)}`
				),
				border: ifFeature('encore', `1px solid ${palette.error.main}`),
			},
		},
		warning: {
			color: theme.palette.warning.main,
		},
	});
});
