import { makeStyles, ModeOptions } from '~styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const useBodyStyles = makeStyles<{ noPadding: boolean }>()((theme, props) => {
	const { breakpoints, palette, mode } = theme;
	const { noPadding } = props;

	return {
		body: {
			borderRadius: ifFeature('encore', '16px'),
			backgroundColor: ifFeature('encore', palette.gray[50], palette.common.white),
			display: 'flex',
			flexDirection: 'column',
			height: '100%',
			overflow: ifFeature('encore', 'hidden'),
			width: ifFeature('encore', 'auto', '100%'),
		},
		content: {
			display: 'flex',
			flexDirection: 'column',
			flexGrow: '1',
			overflow: 'auto',
			padding: noPadding && mode !== ModeOptions.Dye ? '0' : '16px',
			[breakpoints.down('md')]: {
				padding: noPadding ? '0' : '8px',
			},
		},
	};
});
