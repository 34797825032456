import React, { FunctionComponent, PropsWithChildren } from 'react';

import { BaseModal } from '~components/base-modal';
import { SheetModalHeaderProps } from '../sheet-modal.types';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Headline } from '~components/headline';

export const Header: FunctionComponent<PropsWithChildren<SheetModalHeaderProps>> = props => {
	const { children, title, ...commonHeaderProps } = props;

	return (
		<BaseModal.Header {...commonHeaderProps}>
			{children ||
				ifFeature(
					'encore',
					<Headline color="primary" size="medium">
						{title}
					</Headline>,
					<h4>{title}</h4>
				)}
		</BaseModal.Header>
	);
};
