import { ListItemAvatar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { MenuItemAvatarProps } from './types';
import React, { cloneElement, ReactElement, useContext } from 'react';
import { Context } from './context';
import classnames from 'classnames';
import { ifFeature } from '@bamboohr/utils/lib/feature';

const useStyles = makeStyles(({ palette }) => ({
	root: {
		marginBottom: ifFeature('encore', 0, 5),
		marginRight: 10,
		marginTop: ifFeature('encore', 0, 5),
		minWidth: 32,
	},
	children: {
		width: 32,
		height: 32,
	},
	fallback: {
		fill: palette.common.white,
		backgroundColor: palette.primary.main,
	},
	muted: {
		opacity: 0.5,
	},
}));

export const Avatar = (props: MenuItemAvatarProps): ReactElement => {
	const { active, muted } = useContext(Context);
	const classes = useStyles(props);

	const { children } = props;

	return (
		<ListItemAvatar classes={{ root: classnames(classes.root, { [classes.muted]: muted }) }} {...props}>
			{cloneElement(children, {
				classes: { fallback: active ? classes.fallback : '' },
				className: classes.children,
			})}
		</ListItemAvatar>
	);
};
