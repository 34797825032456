import { styled, Theme } from '@mui/material';
import { dyeColors, excludeProps, makeStyles, ModeOptions } from '~styles';

export const StyledIcon = styled('div')({
	lineHeight: 0,
});

export const StyledGrimIcon = styled('div')<{ theme: Theme }>(({ theme }) => {
	const { mode, palette } = theme as Theme;
	return {
		fill: mode === ModeOptions.Dye ? dyeColors.main : palette.primary.main,
	};
});

export const StyledRoot = styled('div', { shouldForwardProp: excludeProps(['hasSeparator']) })<{
	hasSeparator?: boolean;
	theme: Theme;
}>(({ hasSeparator, theme }) => {
	const { palette, mixins } = theme as Theme;
	return {
		textAlign: 'center',
		...(hasSeparator
			? {
					borderBottom: mixins.border(1, palette.gray[300]),
					paddingBottom: 20,
				}
			: {}),
	};
});

export const StyledSubtext = styled('p')<{ theme: Theme }>(({ theme }) => {
	const { palette, typography } = theme as Theme;
	return {
		color: palette.gray[900],
		marginTop: 4,
		...typography.medium,
	};
});
