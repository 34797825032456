/* eslint-disable react/forbid-component-props */
import classNames from 'classnames';
import React, { forwardRef } from 'react';

import { DEFAULT_END_LABEL, DEFAULT_START_LABEL, getInputDateRangeOnChangeParam, getNoteStatus } from './input-date-range.domain';
import { useStyles } from './input-date-range.styles';
import { InputDate } from '../input-date';
import { DatePickerRangeInputType, InputDateOnChangeParam, InputDateRangeProps, InputDateRangeStyleProps } from '../../types';

export const InputDateRange = forwardRef<HTMLDivElement, InputDateRangeProps>(
	(
		{
			biId,
			className,
			classes = {},
			disabled = false,
			calendarId,
			endBiId,
			endCalendarButtonRef,
			endDisabled,
			endInputProps = {},
			endLabel = DEFAULT_END_LABEL,
			endPopupToggleButtonProps,
			endRequired,
			endStatus = 'default',
			endValue,
			id,
			isPopoverOpen,
			note,
			onChange,
			onPopupToggle,
			required = false,
			size = 'medium',
			startBiId,
			startDisabled,
			startCalendarButtonRef,
			startInputProps = {},
			startLabel = DEFAULT_START_LABEL,
			startPopupToggleButtonProps,
			startRequired,
			startStatus = 'default',
			startValue,
			viewMode = false,
			variant,
			width,
		},
		ref
	): JSX.Element => {
		if (startDisabled && endDisabled) {
			disabled = true;
		}

		const styleProps: InputDateRangeStyleProps = {
			disabled,
			size,
		};
		const styles = useStyles(styleProps);

		return (
			<div className={classNames(styles.root, className, classes.root)} ref={ref}>
				<div className={styles.startDate}>
					<InputDate
						biId={biId ? `${biId}_start-input-date` : startBiId}
						calendarButtonRef={startCalendarButtonRef}
						calendarId={calendarId}
						disabled={disabled || startDisabled}
						id={id ? `${id}-start-date` : id}
						inputProps={startInputProps}
						isPopoverOpen={isPopoverOpen}
						label={startLabel}
						onChange={handleStartChange}
						onPopupToggle={handleStartPopupToggle}
						popupToggleButtonProps={startPopupToggleButtonProps}
						required={startRequired || required}
						size={size}
						status={startStatus}
						value={startValue}
						variant={variant}
						viewMode={viewMode}
						width={width}
					/>
				</div>
				<div className={styles.rangeSeparator}>&ndash;</div>
				<div className={styles.endDate}>
					<InputDate
						biId={biId ? `${biId}_end-input-date` : endBiId}
						calendarButtonRef={endCalendarButtonRef}
						calendarId={calendarId}
						disabled={disabled || endDisabled}
						id={id ? `${id}-end-date` : id}
						inputProps={endInputProps}
						isPopoverOpen={isPopoverOpen}
						label={endLabel}
						note={note}
						noteStatus={getNoteStatus(startStatus, endStatus)}
						onChange={handleEndChange}
						onPopupToggle={handleEndPopupToggle}
						popupToggleButtonProps={endPopupToggleButtonProps}
						required={endRequired || required}
						size={size}
						status={endStatus}
						value={endValue}
						variant={variant}
						viewMode={viewMode}
						width={width}
					/>
				</div>
			</div>
		);

		function handleChange(type: DatePickerRangeInputType, inputParam: InputDateOnChangeParam): void {
			const param = getInputDateRangeOnChangeParam(type, inputParam);
			onChange(param);
		}

		function handleEndChange(param: InputDateOnChangeParam): void {
			handleChange('end', param);
		}

		function handleEndPopupToggle(): void {
			handlePopupToggle('end');
		}

		function handlePopupToggle(type: DatePickerRangeInputType): void {
			if (onPopupToggle) {
				onPopupToggle(type);
			}
		}

		function handleStartChange(param: InputDateOnChangeParam): void {
			handleChange('start', param);
		}

		function handleStartPopupToggle(): void {
			handlePopupToggle('start');
		}
	}
);
