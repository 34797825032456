import { makeStyles } from '@mui/styles';
import { dyeColors, ModeOptions } from '~styles';

export const useStyles = makeStyles(({ mode }) => ({
	canvas: {
		outline: mode === ModeOptions.Dye ? `2px solid ${dyeColors.main}` : undefined,
		bottom: 0,
		left: 0,
		pointerEvents: 'none',
		position: 'absolute',
		right: 0,
		top: 0,
	},
}));
