import classnames from 'classnames';
import { ListItemText } from '@mui/material';
import React, { FC, PropsWithChildren, useContext } from 'react';
import { useStyles } from './styles';
import { Context } from '../context';
import { MenuItemTextProps } from '../../types';
import { BodyText } from '~components/body-text';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const Text: FC<PropsWithChildren<MenuItemTextProps>> = props => {
	const { active, disabled, muted, size } = useContext(Context);
	const classes = useStyles();
	const { children, primary, secondary } = props;
	const primaryText = primary || children;

	return ifFeature(
		'encore',
		<BodyText
			color={muted ? 'neutral-weak' : 'neutral-extra-strong'}
			size={size === 'small' || size === 'teenie' ? 'small' : 'medium'}
		>
			{primaryText}
			{secondary && (
				<BodyText color="neutral-weak" size="extra-small">
					{secondary}
				</BodyText>
			)}
		</BodyText>,
		<ListItemText
			classes={{
				root: classes.root,
				primary: classnames(classes.primary, {
					[classes.primaryActive]: active && !disabled,
					[classes.mediumSize]: !(size === 'small' || size === 'teenie'),
					[classes.smallSize]: size === 'small' || size === 'teenie',
				}),
				secondary: classnames(classes.secondary, {
					[classes.secondaryActive]: active && !disabled,
				}),
			}}
			{...props}
		/>
	);
};
