import { makeStyles } from '@mui/styles';
import React, { FC, Fragment, PropsWithChildren, RefObject, useRef } from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { dyeColors, ModeOptions } from '~styles';
import { Hidden } from '../hidden.react';
import { Toggle } from '../toggle.react';
import { FileUploadInputProps } from '../../types';
import { FilesContext } from '../../context';
import { getUploadedFileIds } from '../../util';

const useStyles = makeStyles(theme => {
	const { mode } = theme;
	return {
		root: {
			display: 'inline-block',
			outline: mode === ModeOptions.Dye ? `2px solid ${dyeColors.main}` : undefined,
			position: 'relative',
			width: ifFeature('encore', '100%'),
		},
		input: {
			height: 0,
			opacity: 0,
			overflow: 'hidden',
			position: 'absolute',
			width: 0,
		},
	};
});

export const Input: FC<PropsWithChildren<FileUploadInputProps>> = props => {
	const {
		id,
		renderToggle = args => {
			return <Toggle {...args} />;
		},
	} = props;

	const classes = useStyles();
	const fileInputRef = useRef<HTMLInputElement>();

	const handleClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	return (
		<FilesContext.Consumer>
			{value => {
				const {
					acceptedTypes = [],
					allFileIds = [],
					biId = '',
					canSelectMultiple,
					filesById,
					inputRef,
					isDisabled = false,
					isRequired,
					name,
					pendingFileIds = [],
					upload,
				} = value;

				const shouldHideToggle = pendingFileIds.length || (allFileIds.length > 0 && !canSelectMultiple);

				return (
					<Fragment>
						{shouldHideToggle ? null : (
							<div className={classes.root}>
								{renderToggle({
									biId,
									canSelectMultiple,
									hasFiles: allFileIds.length > 0,
									isDisabled,
									onClick: handleClick,
								})}

								<input
									accept={acceptedTypes?.join(',')}
									aria-label="file-input"
									className={classes.input}
									data-bi-id={`${biId}-file-input`}
									disabled={isDisabled}
									id={id}
									multiple={canSelectMultiple}
									onChange={event => {
										upload([...(event.currentTarget.files as unknown as Array<File>)]);
									}}
									ref={fileInputRef as RefObject<HTMLInputElement>}
									required={isRequired && !allFileIds.length}
									type="file"
								/>
							</div>
						)}

						<Hidden
							fileIds={getUploadedFileIds(filesById, allFileIds, pendingFileIds)}
							inputRef={inputRef}
							isDisabled={isDisabled}
							name={name}
						/>
					</Fragment>
				);
			}}
		</FilesContext.Consumer>
	);
};
