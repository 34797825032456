import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { SectionHeader } from '@fabric/section-header';
import { FormSectionProps } from '../../types';

const useStyles = makeStyles(({ palette, spacing }) => ({
	root: {
		borderBottom: `1px solid ${palette.gray[300]}`,
		marginBottom: spacing(2),
		width: '100%',
		'&:last-child': {
			borderBottom: 'none',
			marginBottom: 0,

			'& $children': {
				paddingBottom: 0,
			},
		},
	},
	children: {
		paddingBottom: spacing(2.5),
	},
}));

export const Section = (props: FormSectionProps) => {
	const { children, icon, label, subLabel, ...rest } = props;

	const classes = useStyles();

	return (
		<Box {...rest} className={classes.root}>
			{label && <SectionHeader icon={icon} label={label} subtitle={subLabel} />}
			{children ? <div className={classes.children}>{children}</div> : null}
		</Box>
	);
};
