import { dyeColors, makeStyles, ModeOptions } from '~styles';
import { CarouselProps } from './types';

export const useStyles = makeStyles<{ isSinglePage: boolean }>()(({ mode }, { isSinglePage }) => {
	return {
		baseContainer: {
			flex: '1 1 100%',
			margin: isSinglePage ? '0' : ' 0 16px',
			minWidth: 0,
			order: 2,
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
		},
		button: {
			alignItems: 'center',
			display: 'flex',
			flex: '0 0 auto',
		},
		hideButton: {
			opacity: 0,
		},
		nextButton: {
			order: 3,
		},
		prevButton: {
			order: 1,
		},
		wrapper: {
			display: 'flex',
		},
	};
});
