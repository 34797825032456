import { createStyles } from '@mui/styles';

export const styles = () =>
	createStyles({
		root: {
			display: 'inline-flex',
			flexDirection: 'column',
			justifyContent: 'center',
		},
	});
