import React, { FC, Fragment, PropsWithChildren } from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { FileUnknownBlack16x20 } from '@bamboohr/grim';
import { makeStyles } from '@mui/styles';
import { Button, TextButton } from '~components/button';
import { Divider } from '~components/divider';
import { Flex } from '~components/flex';
import { IconV2 } from '~components/icon-v2';
import { LayoutBox } from '~components/layout-box';
import { FileUploadToggleProps } from '../../types';
import { getPlaceholderText, getToggleText } from '../../util';
import { BodyText } from '~components/body-text';

const useStyles = makeStyles(({ palette, typography }) => ({
	root: {
		alignItems: 'center',
		display: 'flex',
	},
	placeholderIcon: {
		display: 'inline-block',
		fill: palette.grey[700],
		lineHeight: 0,
		marginLeft: '10px',
		marginRight: '5px',
	},
	placeholderText: {
		display: 'inline-block',
		color: palette.grey[700],
		fontSize: typography.fabricFontSize('medium'),
	},
}));

export const Toggle: FC<PropsWithChildren<FileUploadToggleProps>> = props => {
	const { biId, buttonText, canSelectMultiple, isDisabled, hasFiles, onClick } = props;
	const classes = useStyles(props);

	const addFilesText = window.jQuery ? $.__('Add Files') : 'Add Files';
	const chooseFilesText = buttonText || getToggleText(canSelectMultiple);
	const placeholderText = getPlaceholderText(canSelectMultiple);

	return (
		<div className={classes.root}>
			{hasFiles
				? ifFeature(
						'encore',
						<LayoutBox marginTop={0.5} width="100%">
							<Divider color="neutral-extra-weak" marginY={1} orientation="horizontal" />
							<TextButton
								data-bi-id={biId ? `${biId}-add-files-button` : ''}
								disabled={isDisabled}
								onClick={onClick}
								size="small"
								startIcon="up-from-line-regular"
								type="button"
							>
								{addFilesText}
							</TextButton>
						</LayoutBox>,
						<TextButton
							clickAction={onClick}
							data-bi-id={biId ? `${biId}-add-files-button` : ''}
							isDisabled={isDisabled}
							text={`+ ${addFilesText}`}
							type="button"
						/>
					)
				: ifFeature(
						'encore',
						<Flex alignItems="center" gap={2}>
							<Button
								data-bi-id={biId ? `${biId}-choose-files-button` : ''}
								disabled={isDisabled}
								onClick={onClick}
								startIcon="up-from-line-regular"
								type="button"
								variant="outlined"
							>
								{chooseFilesText}
							</Button>
							<Flex alignItems="center" gap={1}>
								<IconV2 color="neutral-extra-extra-strong" name="file-lines-regular" size={16} />
								<BodyText color="neutral-extra-strong">{placeholderText}</BodyText>
							</Flex>
						</Flex>,
						<Fragment>
							<Button
								clickAction={onClick}
								data-bi-id={biId ? `${biId}-choose-files-button` : ''}
								isDisabled={isDisabled}
								outline={true}
								text={chooseFilesText}
								type="button"
							/>

							<div className={classes.placeholderIcon}>
								<FileUnknownBlack16x20 aria-hidden="true" role="presentation" />
							</div>

							<div className={classes.placeholderText}>{placeholderText}</div>
						</Fragment>
					)}
		</div>
	);
};
