import { makeStyles } from '@mui/styles';
import { dyeColors, ModeOptions } from '~styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const useStyles = makeStyles(({ palette, spacing, mode }) => ({
	root: {
		display: 'flex',
		flexGrow: 1,
		flexShrink: 1,
		overflowY: 'auto',
		outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
		padding: ifFeature('encore', `${spacing(2)} 0`),
	},
	groupEndWithGutter: {
		paddingBottom: spacing(1),
	},
	groupEndWithoutGutter: {},
	groupLabelWithGutter: {
		display: 'none',
	},
	groupLabelWithoutGutter: {},
	gutter: {
		backgroundColor: palette.gray[100],
		position: 'relative',
		width: 140,
	},
	virtualItem: {
		left: 0,
		position: 'absolute',
		right: 0,
	},
	virtualItems: {
		position: 'relative',
		flexGrow: 1,
	},
}));
