import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ palette, typography }) => ({
	root: {
		boxSizing: 'border-box',
		color: palette.gray[800],
		fontSize: typography.medium?.fontSize,
		fontWeight: typography.fontWeightSemibold,
		left: 0,
		lineHeight: typography.medium?.lineHeight,
		paddingRight: 12,
		paddingLeft: 12,
		paddingTop: 13,
		position: 'absolute',
		overflow: 'hidden',
		textAlign: 'right',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		width: '100%',
	},

	smallSize: {
		fontSize: typography.small?.fontSize,
		lineHeight: typography.small?.lineHeight,
	},
}));
