import classnames from 'classnames';
import { ListItemIcon } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { FC, PropsWithChildren, useContext } from 'react';

import { Context } from './context';
import { MenuItemIconProps } from './types';
import { ifFeature } from '@bamboohr/utils/lib/feature';

const useStyles = makeStyles(({ palette }) => ({
	root: {
		alignItems: 'center',
		fill: palette.primary.main,
		display: 'flex',
		justifyContent: 'center',
		marginRight: 8,
		minWidth: 'unset',
	},
	// @startCleanup encore
	active: {
		fill: ifFeature('encore', undefined, palette.primary.contrastText),
	},
	// @endCleanup encore
	smallSize: {
		height: 14,
		width: 14,
	},
	mediumSize: {
		height: 16,
		width: 16,
	},
}));

export const Icon: FC<PropsWithChildren<MenuItemIconProps>> = props => {
	const { active, disabled, size } = useContext(Context);
	const classes = useStyles({ active, disabled, size });

	return (
		<ListItemIcon
			aria-hidden={true}
			className={classnames(classes.root, {
				[classes.active]: active && !disabled,
				[classes.smallSize]: size === 'small' || size === 'teenie',
				[classes.mediumSize]: !(size === 'small' || size === 'medium'),
			})}
			{...props}
		/>
	);
};
