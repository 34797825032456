import { TextButton } from '@fabric/button';
import { useReadableContext } from '~components/readable';
import { MaskedInput } from '~components/masked-input';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import { isNil, omit } from 'lodash';
import React, { forwardRef, ReactElement, Ref, useEffect, useRef, useState } from 'react';
import { getMask } from './get-mask';
import { SensitiveTextFieldProps } from './types';

const useStyles = makeStyles(() => ({
	root: {
		'&:hover $toggleButton': {
			visibility: 'visible',
		},
		'&:focus-within $toggleButton': {
			visibility: 'visible',
		},
	},
	toggleButton: {
		marginTop: '-2px',
		visibility: 'hidden',
	},
}));

const showText = window.jQuery ? $.__('Show') : 'Show';
const hideText = window.jQuery ? $.__('Hide') : 'Hide';

export const SensitiveTextField = forwardRef(
	(
		{
			className,
			disabled,
			fieldType,
			onChange = () => undefined,
			onShow,
			note,
			value: valueProp,
			sensitiveValue: redactedText,
			MaskOptions,
			...restProps
		}: SensitiveTextFieldProps,
		ref: Ref<HTMLInputElement>
	): ReactElement => {
		const classes = useStyles();
		const { isReadable } = useReadableContext();
		const [showValue, setShowValue] = useState(false);
		const [loading, setLoading] = useState(false);

		const onShowRef = useRef<typeof onShow>();
		onShowRef.current = onShow;

		useEffect(() => {
			if (!isReadable) {
				setShowValue(true);
			}
		}, [isReadable, setShowValue]);

		useEffect(() => {
			if (showValue && typeof onShowRef.current === 'function') {
				setLoading(true);

				// eslint-disable-next-line @typescript-eslint/no-floating-promises
				Promise.resolve(onShowRef.current()).finally(() => setLoading(false));
			}
		}, [showValue, onShowRef]);

		const showToggleButton = typeof note === 'undefined' && isReadable && redactedText && !disabled;

		/**
		 * I am not going to lie, I feel a bit painted into a corner with solution.
		 * IMask is a pretty terrible library and we can't afford the additional scope
		 * to replace it at the moment.
		 *
		 * This ref is used to work around the fact that `onAccept` is cached by IMask and the
		 * values from outside the function go stale. I would make this change directly to the
		 * MaskedInput component but that is also a lot more scope. Maybe we can follow this up
		 * with another PR to fix the other component.
		 */
		const valueRef = useRef<unknown>();
		valueRef.current = showValue && !isNil(valueProp) ? valueProp : redactedText;

		return (
			<MaskedInput
				{...restProps}
				className={classnames(classes.root, className)}
				conformInitialValue={false}
				disabled={disabled}
				MaskOptions={{
					...omit(MaskOptions || {}, 'mask'),
					mask: getMask(fieldType, redactedText),
					onAccept: (value: string) => {
						if (valueRef.current !== value) {
							onChange({ value });
						}
					},
				}}
				note={
					showToggleButton ? (
						<TextButton
							className={classes.toggleButton}
							disabled={disabled}
							onClick={() => {
								setShowValue(_showValue => !_showValue);
							}}
							onFocus={(e: React.FocusEvent<HTMLButtonElement>) => e.stopPropagation()}
							type="button"
						>
							{showValue && !loading ? hideText : showText}
						</TextButton>
					) : (
						note
					)
				}
				ref={ref}
				submitUnmaskedValue={true}
				value={valueRef.current}
			/>
		);
	}
);
