// eslint-disable-next-line no-use-before-define
import React, { forwardRef } from 'react';
import { InputLabel, FormControl } from '@mui/material';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Flex } from '~components/flex';
import { LayoutBox } from '~components/layout-box';
import { InputWrapperProps } from './types';
import { useInputIcon } from '~components/text-field';
import { Note, NoteInputContainer } from '../common/note';
import { useStyles } from './input-wrapper.styles';

/**
 *
 * Text input for all forms and such.
 * The `ref` is forwarded to the Input.
 */
export const InputWrapper = forwardRef<HTMLInputElement, InputWrapperProps>(
	(
		{
			afterLabel,
			children,
			classes = {},
			className,
			disabled = false,
			id,
			InputLabelProps,
			label,
			labelPlacement,
			note,
			notePlacement,
			required = false,
			status,
			variant,
			viewMode,
			width,
		},
		ref
	) => {
		const { classes: styles, cx } = useStyles({ disabled, labelPlacement, status, viewMode });
		const icon = useInputIcon(status);

		return (
			<FormControl
				className={cx(className, classes.root, styles.root)}
				error={status === 'error'}
				fullWidth={width === 100}
				ref={ref}
			>
				{label &&
					ifFeature(
						'encore',
						<div className={cx(classes.label, styles.labelWrapper)}>
							{typeof label === 'string' ? (
								<>
									{icon && !disabled && <Flex marginRight={0.5}>{icon}</Flex>}
									<InputLabel
										{...InputLabelProps}
										classes={{ root: styles.label }}
										className={cx({
											[styles.error]: status === 'error',
											[styles.warning]: status === 'warning',
											[styles.info]: status === 'info',
										})}
										disabled={disabled}
										error={status === 'error'}
										htmlFor={id}
										required={required}
										shrink={false}
									>
										{label}
									</InputLabel>
									{afterLabel}
								</>
							) : (
								label
							)}
						</div>,
						<Flex alignItems="center" flexShrink={0} gap={0.5}>
							<InputLabel
								{...InputLabelProps}
								classes={{ root: styles.label }}
								className={cx(
									{
										[styles.error]: status === 'error',
										[styles.warning]: status === 'warning',
										[styles.info]: status === 'info',
									},
									classes.label
								)}
								disabled={disabled}
								error={status === 'error'}
								htmlFor={id}
								required={required}
								shrink={true}
							>
								{!disabled && icon}
								{label}
								{afterLabel ? <LayoutBox marginLeft={0.5}>{afterLabel}</LayoutBox> : undefined}
							</InputLabel>
						</Flex>
					)}
				<NoteInputContainer inputHasLabel={Boolean(label)} notePlacement={notePlacement}>
					{children}
					{variant === 'single' ? null : (
						<Note classes={{ root: styles.note }} disabled={disabled} note={note} status={status} />
					)}
				</NoteInputContainer>
			</FormControl>
		);
	}
);
