import { ifFeature } from '@bamboohr/utils/lib/feature';
import { fabricFontSize, fabricLineHeight } from '@fabric/definitions/js/text';
import { createStyles, makeStyles } from '@mui/styles';
import { BannerStyleProps, BannerType } from './types';
import { dyeColors, ModeOptions } from '~styles';

export const useStyles = makeStyles(theme => {
	const { constructs, palette, typography, mode } = theme;

	return createStyles({
		closeIcon: ifFeature(
			'encore',
			{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				'&>.MuiButtonBase-root': {
					boxShadow: 'none',
				},
			},
			{}
		),
		content: {
			flexGrow: 1,
		},
		icon: {
			marginRight: ifFeature('encore', 12, 11),
		},
		root: {
			alignItems: 'center',
			backgroundColor: ({ type }: BannerStyleProps) => getBannerColor(type),
			color: palette.common.white,
			display: 'flex',
			fill: palette.common.white,
			fontSize: fabricFontSize('large'),
			fontWeight: ifFeature('encore', typography.fontWeightMedium, typography.fontWeightRegular),
			justifyContent: 'space-between',
			lineHeight: fabricLineHeight('large'),
			minHeight: ifFeature('encore', 32, 44),
			outline: mode === ModeOptions.Dye ? `2px solid ${dyeColors.main}` : undefined,
			paddingBottom: ifFeature('encore', 12),
			paddingTop: ifFeature('encore', 12),
			paddingLeft: ifFeature('encore', 40, 32),
			paddingRight: ifFeature('encore', 40, ({ hasClose }: BannerStyleProps) => (hasClose ? 20 : 32)),
		},
		// These three classes are used for sliding the banner closed
		container: {
			display: 'grid',
			gridTemplateRows: '1fr',
			transition: 'grid-template-rows 400ms',
		},
		innerContainer: {
			overflow: 'hidden',
		},
		closing: {
			gridTemplateRows: '0fr',
		},
	});

	function getBannerColor(type: BannerType): string {
		switch (type) {
			case 'info':
				return ifFeature('encore', constructs.surface.info.strong, palette.info.main);
			case 'trial':
				return ifFeature('encore', constructs.surface.neutral.xStrong, palette.grey[1000]);
			case 'warning':
				return ifFeature('encore', constructs.surface.warning.strong, palette.warning.main);
			case 'error':
				return ifFeature('encore', constructs.surface.error.strong, palette.error.main);
			default:
				return ifFeature('encore', constructs.surface.info.strong, palette.info.main);
		}
	}
});
