import React from 'react';
import { Collapse, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { IconV2 } from '~components/icon-v2';
import { AccordionProps } from './accordion.types';

const getTextColor = (disabled, expanded, theme: Theme) => {
	if (disabled) {
		return ifFeature('encore', theme.constructs.text.neutral.weak, theme.palette.gray[600]);
	}
	if (expanded) {
		return theme.palette.primary.main;
	}

	return theme.palette.gray[900];
};

const getIconColor = (disabled, expanded, theme: Theme) => {
	if (disabled) {
		return ifFeature('encore', theme.constructs.icon.neutral.strong, theme.palette.gray[600]);
	}
	if (expanded) {
		return ifFeature('encore', theme.constructs.icon.primary.strong, theme.palette.primary.main);
	}

	return ifFeature('encore', theme.constructs.icon.neutral.xxStrong, theme.palette.gray[900]);
};

const getPointerEvents = (hideDescriptionOnCollapse, expanded) => {
	if (hideDescriptionOnCollapse && expanded) {
		return 'auto';
	}
	if (hideDescriptionOnCollapse && !expanded) {
		return 'none';
	}

	return 'inherit';
};

const useStyles = makeStyles(theme => {
	const { constructs, palette, spacing, transitions, typography } = theme;

	return {
		title: {
			display: 'flex',
			gridColumn: 'center-column',
		},
		defaultTitle: ({ disabled, expanded }: AccordionProps) => ({
			...ifFeature('encore', typography.h5, typography.h4),
			color: getTextColor(disabled, expanded, theme),
			margin: 0,
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			transition: `color 150ms ${transitions.easing.easeInOut}`,
			whiteSpace: 'nowrap',
			'.Mui-focusVisible &, .MuiAccordionSummary-root:hover &': {
				color: ifFeature('encore', constructs.text.primary.strong),
			},
		}),
		headerContent: {
			alignItems: 'center',
			display: 'grid',
			gridColumnGap: spacing(1.25),
			gridTemplateColumns: '[left-column] auto [center-column] minmax(auto, 100%) [right-column] auto',
			width: '100%',
		},
		headerIcon: ({ disabled, expanded }: AccordionProps) => ({
			display: 'flex',
			fill: getIconColor(disabled, expanded, theme),
			/* @startCleanup encore */
			minWidth: ifFeature('encore', undefined, 20),
			/* @endCleanup encore */
			gridColumn: 'left-column',
			transition: `fill 150ms ${transitions.easing.easeInOut}`,
			'.Mui-focusVisible &, .MuiAccordionSummary-root:hover &': {
				fill: ifFeature('encore', constructs.icon.primary.strong),
			},
		}),
		headerRight: {
			display: 'flex',
			gridColumn: 'right-column',
			gridRow: 1,
		},
		description: ({ expanded, hideDescriptionOnCollapse }: AccordionProps) => ({
			background: 'none',
			border: 'none',
			color: palette.gray[700],
			cursor: expanded ? 'initial' : 'inherit',
			fontSize: typography.small.fontSize,
			gridColumn: ifFeature('encore', 'center-column', hideDescriptionOnCollapse ? '1 / span 3' : 'center-column'),
			lineHeight: typography.small.lineHeight,
			opacity: hideDescriptionOnCollapse && !expanded ? 0 : 1,
			/* @startCleanup encore */
			padding: ifFeature('encore', undefined, hideDescriptionOnCollapse ? spacing(0, 0, 2, 3.75) : 'inherit'),
			/* @endCleanup encore */
			pointerEvents: getPointerEvents(hideDescriptionOnCollapse, expanded),
			textAlign: 'inherit',
			transition: `opacity 150ms ${transitions.easing.easeInOut}`,
			userSelect: expanded ? 'text' : 'inherit',
		}),
	};
});

export function AccordionHeaderContent({
	disabled,
	headerIcon,
	title,
	description,
	expanded,
	headerRight,
	hideDescriptionOnCollapse,
	id,
}: AccordionProps): JSX.Element {
	const classes = useStyles({ disabled, expanded, hideDescriptionOnCollapse });
	return (
		<div aria-level={1} className={classes.headerContent} id={id} role="heading">
			{ifFeature(
				'encore',
				headerIcon && typeof headerIcon === 'string' && (
					<div className={classes.headerIcon}>
						<IconV2 name={headerIcon} size={20} />
					</div>
				),
				headerIcon && <div className={classes.headerIcon}>{headerIcon}</div>
			)}
			{title && (
				<div className={classes.title}>
					{typeof title === 'string' ? <div className={classes.defaultTitle}>{title}</div> : title}
				</div>
			)}
			{description && (
				<div className={classes.description}>
					{ifFeature(
						'encore',
						<Collapse
							collapsedSize={0}
							id={`accordion-description-${id}`}
							in={!hideDescriptionOnCollapse || expanded}
							timeout="auto"
						>
							{description}
						</Collapse>,
						description
					)}
				</div>
			)}
			{headerRight && <div className={classes.headerRight}>{headerRight}</div>}
		</div>
	);
}
