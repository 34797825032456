// eslint-disable-next-line no-use-before-define
import React, { ReactElement } from 'react';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { Axis } from '../axis';
import { defaultTransition, useChartColors } from '../common';
import { SwimLanes } from '../swim-lanes';
import { LineChartProps } from '../types';
import { Lines } from './lines';
import { ResponsiveParentSize } from '../responsive-parent-size';
import { AnimatedSvg } from '../animated-svg';
import { getAxisLeftWidth, getAxisRightWidth, getAxisTopHeight, getAxisBottomHeight } from '../axis/axis.domain';

export const LineChart = <Datum extends Record<string, number | string>>({
	animate = true,
	areaColorsByKey,
	axisBottom,
	axisLeft,
	axisRight,
	axisTop,
	chartTitle = window.jQuery ? $.__('Line chart') : 'Line chart',
	circleRadius,
	circleOpenRadius,
	circleStrokeWidth,
	curve,
	data,
	getColor,
	getDataValue = (d, key) => d[key] as number,
	getPointValueFormat,
	getSeriesValue,
	hideInnerSeriesCircles,
	keys,
	lineStrokeWidth,
	margin = { top: 0, right: 0, bottom: 0, left: 0 },
	onClick,
	onMouseLeave,
	onMouseMove,
	pointValues = false,
	swimLanesHorizontal,
	swimLanesVertical,
	transition = defaultTransition,
	range: linearScaleDomain /** The domain of the linear scale is the range of the chart from the user's perspective */,
	renderArea,
	renderOpenCircles,
	renderOverlay,
	outerPadding = 0.5,
}: LineChartProps<Datum>): ReactElement => {
	const yMaxVal = (data: Datum[]): number => {
		const arr = data.map(item => keys.map(k => getDataValue(item, k) ?? null)) as [];
		const max = Math.max(...[].concat(...arr));
		// This is to handle cases with no data or cases where the max is 0.
		// d3 scales that have a domain that starts and ends at the same number, will map that number to the middle of the range.
		if (max === 0 || max === -Infinity) {
			return 1;
		}
		return max;
	};

	// scales
	const color = scaleOrdinal({
		domain: keys,
		range: useChartColors({ onGetColor: getColor }).colors,
	});

	const yDomainMax = yMaxVal(data);
	const linear = (range: number[]) =>
		scaleLinear<number>({
			domain: linearScaleDomain ?? [0, yMaxVal(data)],
			nice: true,
			range,
			round: true,
		});

	const band = (range: number[]) =>
		scaleBand<string>({
			domain: data.map(getSeriesValue),
			range: range as [number, number],
			round: true,
			paddingOuter: outerPadding,
			paddingInner: 1 - outerPadding,
			align: 0.5,
		});

	const axisLeftWidth = getAxisLeftWidth(axisLeft, yDomainMax);
	const axisRightWidth = getAxisRightWidth(axisRight, yDomainMax);
	const axisTopHeight = getAxisTopHeight(axisTop);
	const axisBottomHeight = getAxisBottomHeight(axisBottom);

	const spacingLeft = (margin.left ?? 0) + axisLeftWidth;
	const spacingRight = (margin.right ?? 0) + axisRightWidth;
	const spacingTop = (margin.top ?? 0) + axisTopHeight;
	const spacingBottom = (margin.bottom ?? 0) + axisBottomHeight;

	return (
		<ResponsiveParentSize>
			{({ height, width }) => {
				const xMax = width && width - spacingLeft - spacingRight;
				const yMax = height && height - spacingTop - spacingBottom;

				const xRange = [0, xMax];
				const yRange = [yMax, 0];

				const xScale = band(xRange);
				const yScale = linear(yRange);

				const swimLanesProps = {
					height: yMax,
					width: xMax,
				};

				const swimLanesHorizontalProps = {
					horizontal: {
						scale: yScale,
						...swimLanesProps,
						numTicks: typeof axisLeft === 'object' ? axisLeft.numTicks : undefined,
						tickValues: typeof axisLeft === 'object' ? axisLeft.tickValues : undefined,
					},
				};

				const swimLanesVerticalProps = {
					vertical: {
						scale: xScale,
						...swimLanesProps,
						numTicks: typeof axisBottom === 'object' ? axisBottom.numTicks : undefined,
						tickValues: typeof axisBottom === 'object' ? axisBottom.tickValues : undefined,
					},
				};

				const axisBottomProps = {
					bottom: {
						scale: xScale,
						top: yMax,
						...(typeof axisBottom === 'object' && axisBottom),
					},
				};
				const axisLeftProps = {
					left: {
						scale: yScale,
						...(typeof axisLeft === 'object' && axisLeft),
					},
				};
				const axisRightProps = {
					right: {
						scale: yScale,
						left: xMax,
						...(typeof axisRight === 'object' && axisRight),
					},
				};
				const axisTopProps = {
					top: {
						scale: xScale,
						...(typeof axisTop === 'object' && axisTop),
					},
				};

				const linesProps = {
					animate,
					areaColorsByKey,
					circleRadius,
					circleOpenRadius,
					circleStrokeWidth,
					color,
					curve,
					data,
					getDataValue,
					getPointValueFormat,
					getSeriesValue,
					hideInnerSeriesCircles,
					keys,
					lineStrokeWidth,
					onClick,
					onMouseLeave,
					onMouseMove,
					pointValues,
					x: getSeriesValue,
					xMax,
					xScale,
					y: getDataValue,
					yMax,
					yScale,
					renderArea,
					renderOpenCircles,
					renderOverlay,
					outerPadding,
					transition,
				};

				// Don't render until it has resized to take up parent's dimensions
				if (yMax === 0 && xMax === 0) {
					return null;
				}

				return (
					<AnimatedSvg chartTitle={chartTitle} height={height} transition={transition} width={width}>
						<Group left={spacingLeft} top={spacingTop}>
							<SwimLanes
								{...(swimLanesHorizontal && swimLanesHorizontalProps)}
								{...(swimLanesVertical && swimLanesVerticalProps)}
							/>
							<Axis
								{...(axisBottom && axisBottomProps)}
								{...(axisLeft && axisLeftProps)}
								{...(axisRight && axisRightProps)}
								{...(axisTop && axisTopProps)}
							/>
							<Lines<Datum> {...linesProps} />
						</Group>
					</AnimatedSvg>
				);
			}}
		</ResponsiveParentSize>
	);
};
