import { makeStyles } from '@mui/styles';
import { ParentSize } from '@visx/responsive';
import { ParentSizeProps } from '@visx/responsive/lib/components/ParentSize';
import React, { FC, PropsWithChildren } from 'react';

const useStyles = makeStyles({
	root: {
		position: 'relative',
		width: '100%',
		height: '100%',
	},
});

export const ResponsiveParentSize: FC<
	PropsWithChildren<ParentSizeProps & Omit<JSX.IntrinsicElements['div'], keyof ParentSizeProps>>
> = ({ children, ...rest }) => {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<ParentSize debounceTime={10} {...rest}>
				{children}
			</ParentSize>
		</div>
	);
};
