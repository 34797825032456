import { makeStyles } from '@mui/styles';

import ephesisRegularWoff from '../../assets/fonts/Ephesis-Regular.woff';
import ephesisRegularWoff2 from '../../assets/fonts/Ephesis-Regular.woff2';
import latoBoldWoff from '../../assets/fonts/Lato-Bold.woff';
import latoBoldWoff2 from '../../assets/fonts/Lato-Bold.woff2';
import latoHeavyWoff from '../../assets/fonts/Lato-Heavy.woff';
import latoHeavyWoff2 from '../../assets/fonts/Lato-Heavy.woff2';
import latoItalicWoff from '../../assets/fonts/Lato-Italic.woff';
import latoItalicWoff2 from '../../assets/fonts/Lato-Italic.woff2';
import latoMediumWoff from '../../assets/fonts/Lato-Medium.woff';
import latoMediumWoff2 from '../../assets/fonts/Lato-Medium.woff2';
import latoRegularWoff from '../../assets/fonts/Lato-Regular.woff';
import latoRegularWoff2 from '../../assets/fonts/Lato-Regular.woff2';
import latoSemiBoldWoff from '../../assets/fonts/Lato-Semibold.woff';
import latoSemiBoldWoff2 from '../../assets/fonts/Lato-Semibold.woff2';

const useStyles = makeStyles(() => ({
	'@global': {
		'@font-face': [
			/* Webfont: Ephesis-Regular */
			{
				fallbacks: [
					{
						src: `url('${ephesisRegularWoff}') format('woff')`,
					},
				],
				fontFamily: 'Ephesis',
				src: `url('${ephesisRegularWoff2}') format('woff2')`,
			},

			/* Webfont: Lato-Regular */
			{
				fallbacks: [
					{
						src: `url('${latoRegularWoff}') format('woff')`,
					},
				],
				fontFamily: 'LatoInitial',
				src: `url('${latoRegularWoff2}') format('woff2')`,
			},

			/* Webfont: Lato-Regular */
			{
				fallbacks: [
					{
						src: `url('${latoRegularWoff}') format('woff')`,
					},
				],
				fontFamily: 'Lato',
				src: `url('${latoRegularWoff2}') format('woff2')`,
			},

			/* Webfont: Lato-Italic */
			{
				fallbacks: [
					{
						src: `url('${latoItalicWoff}') format('woff')`,
					},
				],
				fontFamily: 'Lato',
				fontStyle: 'italic',
				src: `url('${latoItalicWoff2}') format('woff2')`,
			},

			/* Webfont: Lato-Medium */
			{
				fallbacks: [
					{
						src: `url('${latoMediumWoff}') format('woff')`,
					},
				],
				fontFamily: 'Lato',
				fontWeight: 500,
				src: `url('${latoMediumWoff2}') format('woff2')`,
			},

			/* Webfont: Lato-Semibold */
			{
				fallbacks: [
					{
						src: `url('${latoSemiBoldWoff}') format('woff')`,
					},
				],
				fontFamily: 'Lato',
				fontWeight: 600,
				src: `url('${latoSemiBoldWoff2}') format('woff2')`,
			},

			/* Webfont: Lato-Bold */
			{
				fallbacks: [
					{
						src: `url('${latoBoldWoff}') format('woff')`,
					},
				],
				fontFamily: 'Lato',
				fontWeight: 700,
				src: `url('${latoBoldWoff2}') format('woff2')`,
			},

			/* Webfont: Lato-Heavy */
			{
				fallbacks: [
					{
						src: `url('${latoHeavyWoff}') format('woff')`,
					},
				],
				fontFamily: 'Lato',
				fontWeight: 800,
				src: `url('${latoHeavyWoff2}') format('woff2')`,
			},

			/* Webfont: Fields-Regular */
			{
				fontFamily: 'Fields',
				src: `url('https://staticfe.bamboohr.com/assets/fonts/Fields-Regular.woff2 ') format('woff2')`,
			},

			/* Webfont: Fields-Semibold */
			{
				fontFamily: 'Fields',
				fontWeight: 600,
				src: `url('https://staticfe.bamboohr.com/assets/fonts/Fields-SemiBold.woff2 ') format('woff2')`,
			},

			/* Webfont: Fields-Bold */
			{
				fontFamily: 'Fields',
				fontWeight: 700,
				src: `url('https://staticfe.bamboohr.com/assets/fonts/Fields-Bold.woff2 ') format('woff2')`,
			},

			/* Webfont: Inter-Regular */
			{
				fontFamily: 'Inter',
				src: `url('https://staticfe.bamboohr.com/assets/fonts/Inter-Regular.woff2 ') format('woff2')`,
			},

			/* Webfont: Inter-Medium */
			{
				fontFamily: 'Inter',
				fontWeight: 500,
				src: `url('https://staticfe.bamboohr.com/assets/fonts/Inter-Medium.woff2 ') format('woff2')`,
			},

			/* Webfont: Inter-Semibold */
			{
				fontFamily: 'Inter',
				fontWeight: 600,
				src: `url('https://staticfe.bamboohr.com/assets/fonts/Inter-SemiBold.woff2 ') format('woff2')`,
			},

			/* Webfont: Inter-Bold */
			{
				fontFamily: 'Inter',
				fontWeight: 700,
				src: `url('https://staticfe.bamboohr.com/assets/fonts/Inter-Bold.woff2') format('woff2')`,
			},
		],
	},
}));

export default (): null => {
	useStyles();
	return null;
};
