import { ajax } from '@bamboohr/utils/lib/ajax';

import { DEFAULT_UPLOAD_ENDPOINT_URL, DEFAULT_UPLOAD_NAME } from './constants';
import { FileUploadFile, FileUploadServiceOptions, FileUploadServiceResponse } from './types';

class FileUploadServiceError extends Error {
	errorType: string;

	constructor({ message, errorType }: { message: string; errorType: string }) {
		super(message);

		this.errorType = errorType;
	}
}

export function uploadFile(file: File, options: FileUploadServiceOptions): Promise<FileUploadFile> {
	const { additionalValues = {}, name = DEFAULT_UPLOAD_NAME, onUploadProgress, url = DEFAULT_UPLOAD_ENDPOINT_URL } = options || {};

	const formData = new FormData();
	formData.append(name, file);

	Object.keys(additionalValues).forEach(key => formData.append(key, additionalValues[key]));

	return ajax
		.post(url, formData, {
			onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
				onUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
			},
		})
		.then(({ data }: FileUploadServiceResponse): FileUploadFile => {
			const { errorType, fid, message, name: _name, type, size, status } = data || {};

			if (status !== 'OK') {
				throw new FileUploadServiceError({ message, errorType });
			}

			return {
				id: fid,
				name: _name,
				type,
				size,
			};
		});
}
