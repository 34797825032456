// eslint-disable-next-line no-use-before-define
import { ifFeature } from '@bamboohr/utils/lib/feature';
import React, { ReactElement } from 'react';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { Flex } from '../../flex';
import { LegendItemProps } from '../types';
import { roundPercent, useChartColors } from '../common';
import { MotionTypography } from '../../typography';
import { LegendLink } from './legend-link';
import { useStyles } from './use-styles';

export const LegendItem = <Datum = unknown,>({
	datum,
	index,
	legendLength,
	total,
	legendProps: {
		animate = true,
		totalLabel = window.jQuery ? $.__('Total') : 'Total',
		anchorProps,
		renderCount,
		renderLabel,
		renderTotal: TotalComponent,
		onClickLabel,
		onClickValue,
		orientation = 'vertical',
		reverse,
		showCompactSwatch,
		showPercentage,
		showValue,
		getKey,
		getValue,
		getColor,
		hrefLabel,
		hrefValue,
	},
}: Omit<LegendItemProps<Datum>, 'data'>): ReactElement => {
	const { palette, typography } = useTheme();
	const { getColor: getChartColor } = useChartColors({ length: legendLength, onGetColor: getColor, reverse });

	const style = {
		swatchColor: typeof index === 'number' ? getChartColor(index) : null,
		swatchWidth: showCompactSwatch ? 8 : 20,
		orientation,
	};

	const classes = useStyles(style);

	const getPercentage = (value: number, total: number) => (value / total) * 100;

	const itemProps =
		orientation === 'vertical'
			? {
					mb: '10px',
				}
			: {
					mb: '10px',
					mr: '30px',
				};

	const totalProps =
		orientation === 'vertical'
			? {
					borderTop: `1px solid ${palette.divider}`,
					mt: '3px',
					pt: '3px',
				}
			: {
					mb: '10px',
				};

	const value = getValue && datum ? getValue(datum) : 0;
	const percent = getValue && datum ? getPercentage(getValue(datum), total || 1) : 0;

	const legendLabelProps = {
		href: hrefLabel ? datum && hrefLabel(datum) : undefined,
		onClick: onClickLabel ? () => datum && onClickLabel(datum) : undefined,
		...anchorProps,
	};

	const legendValueProps = {
		href: hrefValue ? datum && hrefValue(datum) : undefined,
		onClick: onClickValue ? () => datum && onClickValue(datum) : undefined,
		...anchorProps,
	};

	const swatch = <span className={classes.swatch}></span>;
	const totalValue = total === undefined ? 0 : total;
	// If animation is on, we want to use the default duration
	const animationDuration = animate ? undefined : 0;

	return datum ? (
		<Flex alignItems="center" className={classes.root} {...itemProps}>
			{renderLabel ? (
				renderLabel({ datum, swatch })
			) : (
				<LegendLink {...legendLabelProps}>
					{swatch}
					{getKey(datum)}
				</LegendLink>
			)}
			{getValue && total !== 0 && showPercentage && (
				<MotionTypography
					animateDuration={animationDuration}
					className={classes.percentage}
					formatter={(v: string) => {
						// Unfortunately value gets truncated to an int in the MotionTypography component so we have to use this hack :(
						if (!datum) {
							return `0%`;
						}
						const progress = parseFloat(v) / 100;
						return `${roundPercent(progress * percent)}%`;
					}}
					to={100}
					TypographyProps={{
						color: 'inherit',
						fontSize: ifFeature('encore', typography.small.fontSize),
						lineHeight: ifFeature('encore', typography.small.lineHeight),
					}}
				/>
			)}
			{getValue && showValue && (
				<div className={classes.value}>
					{renderCount ? (
						renderCount({ datum })
					) : (
						<LegendLink {...legendValueProps}>
							<MotionTypography
								animateDuration={animationDuration}
								to={value}
								TypographyProps={{
									color: 'inherit',
									fontSize: ifFeature('encore', typography.small.fontSize),
									lineHeight: ifFeature('encore', typography.small.lineHeight),
								}}
							/>
						</LegendLink>
					)}
				</div>
			)}
		</Flex>
	) : (
		<Flex alignItems="center" justifyContent="space-between" {...totalProps} className={classes.totalRow}>
			<Typography className={classes.total}>{totalLabel}</Typography>
			<span className={classes.value}>
				{TotalComponent ? (
					<TotalComponent total={totalValue} />
				) : (
					<MotionTypography
						animateDuration={animationDuration}
						to={totalValue}
						TypographyProps={{
							color: 'inherit',
							fontSize: ifFeature('encore', typography.small.fontSize),
							lineHeight: ifFeature('encore', typography.small.lineHeight),
						}}
					/>
				)}
			</span>
		</Flex>
	);
};
