import { Box } from '@mui/material';
import React, { forwardRef } from 'react';
import { formatBaseProps } from '~utils';
import { useStyles } from './styled-box.styles';
import { StyledBoxComponent, StyledBoxProps } from './styled-box.types';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const StyledBox = forwardRef<HTMLElement, StyledBoxProps>((props, ref) => {
	const {
		backgroundColor = ifFeature('encore', 'none'),
		borderColor = ifFeature('encore', 'none'),
		borderRadius = ifFeature('encore', 'none'),
		borderStyle = ifFeature('encore', 'none'),
		boxShadow = ifFeature('encore', 'none'),
		encoreOnly,
		objectFit = 'fill',
		v2 = false,
		...rest
	} = props;

	const { classes } = useStyles({
		backgroundColor,
		borderColor,
		borderRadius,
		borderStyle,
		boxShadow,
		encoreOnly,
		objectFit,
		v2,
		...rest,
	});

	const box = <Box {...formatBaseProps(rest)} className={classes.root} ref={ref} />;

	return encoreOnly ? ifFeature('encore', box, <div>{props.children}</div>) : box;
}) as StyledBoxComponent;
