import React, { forwardRef } from 'react';
import { Box } from '@mui/material';
import { withStyles, WithStyles } from '@mui/styles';
import { PillProps } from './types';
import { styles } from './pill.styles';
import clsx from 'classnames';
import { IconV2 } from '~components/icon-v2';

export const Pill = withStyles(styles)(
	forwardRef<HTMLElement, PillProps & WithStyles<typeof styles>>(({ children, classes, className, icon }, ref) => {
		return (
			<Box className={clsx(className, classes.root)} ref={ref}>
				{icon?.endsWith('-solid') ? (
					<span className={classes.icon}>
						<IconV2 name={icon} size={12} />
					</span>
				) : null}
				{children}
			</Box>
		);
	})
);
