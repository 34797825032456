import { FileIcon } from '@fabric/file-icon';
import { Pencil14x14, CircleX14x14 } from '@bamboohr/grim';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { makeStyles, useTheme } from '@mui/styles';
import React, { FC, PropsWithChildren } from 'react';
import { clsx } from '@fabric/styles';
import { IconButton } from '~components/button';
import { ProgressBar as JadeProgressBar } from '../../../progress-bar.react';
import { ProgressBar } from '~components/progress-bar';
import { FileUploadItemProps } from '../../../../types';

const useStyles = makeStyles(({ palette, spacing, typography }) => ({
	root: {
		alignItems: 'center',
		display: 'flex',
		fontSize: ifFeature('encore', typography.medium.fontSize),
		gap: ifFeature('encore', spacing(1.5)),
		lineHeight: ifFeature('encore', typography.medium.lineHeight),
		marginBottom: ifFeature('encore', undefined, '10px'),
		minHeight: ifFeature('encore', '32px'),

		'&:last-child': {
			marginBottom: ifFeature('encore', undefined, 0),
		},
	},
	name: {
		color: ({ isPending }: { isPending: boolean }) =>
			ifFeature('encore', palette.gray[isPending ? 600 : 900], palette.grey[isPending ? 500 : 800]),
		fontWeight: ifFeature('encore', typography.fontWeightMedium),
	},
	icon: {
		lineHeight: '0',
		marginRight: ifFeature('encore', '-4px', '8px'),
	},
	actionIcon: {
		cursor: 'pointer',
		fill: palette.grey[600],
		lineHeight: 0,
		marginLeft: ifFeature('encore', '4px', '8px'),
		'&:active, &:hover': {
			fill: palette.grey[800],
		},
	},
	progressBar: {
		marginLeft: ifFeature('encore', '4px', '10px'),
		width: ifFeature('encore', undefined, '92px'),
	},
}));

export const Item: FC<PropsWithChildren<FileUploadItemProps>> = props => {
	const { biId, canRename, canRemove = true, classes, isPending, file, progress, onRemove, onRename, temporaryId } = props;

	const styles = useStyles({ isPending });
	const { palette } = useTheme();

	return (
		<div className={styles.root}>
			<span className={styles.icon}>
				<FileIcon ariaHidden customFill={isPending ? palette.gray[500] : undefined} filename={file.name} />
			</span>

			<span className={clsx(styles.name, classes?.name)}>{file.name}</span>

			{canRename
				? ifFeature(
						'encore',
						<IconButton
							aria-label={window.jQuery ? $.__('edit filename') : 'edit filename'}
							data-bi-id={biId ? `${biId}-rename-button` : ''}
							icon="pen-solid"
							noBoundingBox={true}
							onClick={() => {
								onRename(file, temporaryId);
							}}
							size="extra-small"
							type="button"
						/>,
						<span
							aria-label={window.jQuery ? $.__('edit filename') : 'edit filename'}
							className={styles.actionIcon}
							data-bi-id={biId ? `${biId}-rename-button` : ''}
							onClick={() => {
								onRename(file, temporaryId);
							}}
							onKeyDown={event => {
								if (event.key === 'Enter') {
									onRename(file, temporaryId);
								}
							}}
							role="button"
							tabIndex={0}
						>
							<Pencil14x14 role="presentation" />
						</span>
					)
				: null}

			{canRemove
				? ifFeature(
						'encore',
						<IconButton
							aria-label={window.jQuery ? $.__('remove file') : 'remove file'}
							data-bi-id={biId ? `${biId}-removed-button` : ''}
							icon="circle-xmark-solid"
							noBoundingBox={true}
							onClick={() => {
								onRemove(temporaryId);
							}}
							size="extra-small"
							type="button"
						/>,
						<span
							aria-label={window.jQuery ? $.__('remove file') : 'remove file'}
							className={styles.actionIcon}
							data-bi-id={biId ? `${biId}-removed-button` : ''}
							onClick={() => {
								onRemove(temporaryId);
							}}
							onKeyDown={event => {
								if (event.key === 'Enter') {
									onRemove(temporaryId);
								}
							}}
							role="button"
							tabIndex={0}
						>
							<CircleX14x14 role="presentation" />
						</span>
					)
				: null}

			{progress && progress < 100
				? ifFeature(
						'encore',
						<div className={styles.progressBar}>
							<ProgressBar current={progress} height={4} total={100} width="100px" />
						</div>,

						<div className={styles.progressBar}>
							<JadeProgressBar progress={progress} />
						</div>
					)
				: null}
		</div>
	);
};
