/* @startCleanup encore */
import { createStyles, makeStyles as legacyMakeStyles } from '@mui/styles';
/* @endCleanup encore */
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { dyeColors, makeStyles, ModeOptions } from '~styles';
import type { BlankStateProps } from './types';

export const useStyles = makeStyles<Pick<BlankStateProps, 'level'>>()((theme, props) => {
	const { mode, spacing } = theme;
	const { level } = props;

	return {
		actions: {
			alignItems: 'center',
			display: 'flex',
			gap: spacing(level === 'page' ? 2 : 1.5),
		},
		root: {
			alignItems: 'center',
			display: 'flex',
			flexDirection: 'column',
			gap: spacing(level === 'page' ? 3 : 2),
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
		},
		text: {
			alignItems: 'center',
			display: 'flex',
			flexDirection: 'column',
			gap: spacing(1),
			textAlign: 'center',
		},
	};
});

/* @startCleanup encore */
export const useJadeStyles = legacyMakeStyles(theme => {
	const { palette, mode, typography } = theme;

	return createStyles({
		footer: {
			marginTop: 22,
		},
		icon: {
			display: 'flex',
			fill: palette.grey[400],
		},
		root: {
			alignItems: 'center',
			cursor: 'default',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
			textAlign: 'center',
			userSelect: 'none',
		},
		subtitle: {
			color: palette.grey[700],
			fontSize: ifFeature('encore', undefined, typography.large.fontSize),
			marginTop: ifFeature('encore', '8px', '6px'),
			maxWidth: 512,
		},
		title: {
			color: ifFeature('encore', palette.grey[800], palette.grey[700]),
			margin: ifFeature('encore', '24px 0 0 0', '20px 0 0 0'),
		},
	});
});
/* @endCleanup encore */
