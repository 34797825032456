import { WidthBreakpoints } from '../types';

export class Resizer {
	private _breakpoints: WidthBreakpoints | undefined;

	private _element: HTMLElement | null;

	private _slidesToShow = 0;

	private _observer: ResizeObserver | undefined;

	private _onResize: ((slidesToShow: number) => void) | null;

	private _createObserver(): void {
		if (!this._element) {
			return;
		}

		this._observer = new window.ResizeObserver(entries => {
			for (const entry of entries) {
				if (!this._breakpoints) {
					return;
				}
				const currentWidth = entry.borderBoxSize[0].inlineSize;
				const sortedBreakpoints = Object.keys(this._breakpoints)
					.map(Number)
					.sort((a, b) => b - a);
				const breakpoint = Number(sortedBreakpoints.find(point => point <= currentWidth) || '0');
				const toShow = this._breakpoints[breakpoint] || 1;
				if (toShow !== this._slidesToShow && typeof this._onResize === 'function') {
					this._slidesToShow = toShow;
					this._onResize(toShow);
				}
			}
		});

		this._observer?.observe(this._element);
	}

	private _update(): void {
		if (!this._element && this._observer) {
			this.disconnect();
		} else if (!this._breakpoints && this._observer) {
			this.disconnect();
		} else if (this._element && this._breakpoints && !this._observer) {
			this._createObserver();
		}
	}

	setElement(element: HTMLElement | null): void {
		this._element = element;
		this._update();
	}

	setBreakpoints(breakpoints: WidthBreakpoints | undefined): void {
		const shouldUpdate = (!this._breakpoints && breakpoints) || (this._breakpoints && !breakpoints);
		this._breakpoints = breakpoints;
		if (shouldUpdate) {
			this._update();
		}
	}

	setOnResize(onResize: (slidesToShow: number) => void): void {
		this._onResize = onResize;
	}

	disconnect(): void {
		if (this._observer) {
			this._observer.disconnect();
			this._observer = undefined;
		}
	}
}
