/* startCleanup encore */
import { pageWidths as jadePageWidths } from '../../definitions/json/layout.json';
/* endCleanup encore */
import { pageCapsule, pageWidths } from '../../definitions/json/encore-layout.json';
import { Layout } from '../types';
/* startCleanup encore */
import { ifFeature } from '@bamboohr/utils/lib/feature';
/* endCleanup encore */

export const getLayout = (): Layout => {
	return ifFeature(
		'encore',
		{
			pageCapsule,
			pageWidths,
		},
		{
			pageCapsule: { padding: 0 },
			pageWidths: jadePageWidths,
		}
	);
};
