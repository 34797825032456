import { ifFeature } from '@bamboohr/utils/lib/feature';
import { PickersDay } from '@mui/x-date-pickers';
import { styled } from '@mui/material';

export const StyledPickersDay = styled(PickersDay)(({
	theme: { borderRadiuses, constructs, palette, mixins, spacing, typography },
}) => {
	return {
		height: ifFeature('encore', spacing(5), spacing(4)),
		width: spacing(5),
		borderRadius: ifFeature('encore', borderRadiuses[300], 2),
		margin: 0,
		// @startCleanup encore
		color: ifFeature('encore', undefined, palette.text.primary),
		// @endCleanup encore
		backgroundColor: 'transparent',
		boxSizing: 'border-box',
		// @startCleanup encore
		fontSize: ifFeature('encore', undefined, typography.medium?.fontSize),
		// @endCleanup encore
		fontWeight: ifFeature('encore', 500),
		'&.MuiPickersDay-today': {
			border: ifFeature('encore', mixins.border(1, constructs.border.neutral.weak), mixins.border(1, palette.gray[400])),
			fontWeight: ifFeature('encore', 600),
			'&:hover': {
				border: ifFeature('encore', 'none'),
			},
			'&.Mui-error': {
				borderColor: ifFeature('encore', constructs.border.error.medium),
				color: ifFeature('encore', constructs.text.error.medium),
			},
			'&.Mui-disabled': {
				borderColor: ifFeature('encore', constructs.border.neutral.medium),
				color: ifFeature('encore', constructs.text.neutral.weak),
				'&.Mui-selected.Mui-error': {
					color: ifFeature('encore', constructs.text.neutral.weak, palette.gray[500]),
					background: ifFeature('encore', 'transparent'),
					fontWeight: ifFeature('encore', 400),
				},
			},
		},
		'&:focus': {
			backgroundColor: 'transparent',
		},
		'&:hover': {
			backgroundColor: ifFeature('encore', constructs.surface.primary.weak, mixins.alpha(palette.primary.lighter, 0.1)),
			// @startCleanup encore
			border: ifFeature('encore', undefined, mixins.border(1, palette.primary.light)),
			// @endCleanup encore
			color: ifFeature('encore', constructs.text.primary.strong, palette.grey[1000]),
			fontWeight: ifFeature('encore', 600),
			zIndex: 2,
		},
		'&.Mui-active': {
			'&:hover': {
				backgroundColor: ifFeature('encore', constructs.surface.primary.weak, mixins.alpha(palette.primary.lighter, 0.9)),
				border: ifFeature('encore', 'none'),
			},
			color: ifFeature('encore', constructs.text.primary.strong, palette.primary.contrastText),
			fontWeight: ifFeature('encore', 600),
			backgroundColor: ifFeature('encore', constructs.surface.primary.weak, mixins.alpha(palette.primary.lighter, 0.9)),
			border: ifFeature('encore', mixins.border(1, constructs.border.primary.strong), mixins.border(1, palette.primary.light)),
			'&.Mui-selected': {
				backgroundColor: ifFeature('encore', constructs.surface.primary.strong),
				color: ifFeature('encore', constructs.text.neutral.forcedWhite),
			},
			'&.Mui-error': {
				backgroundColor: ifFeature('encore', constructs.surface.error.strong, palette.error.main),
				color: ifFeature('encore', constructs.text.neutral.forcedWhite),
				border: ifFeature('encore', mixins.border(1, constructs.border.error.medium)),
			},
		},
		'&.FabricCalendarDay-marked': {
			'&:hover': {
				backgroundColor: mixins.alpha(palette.primary.lighter, 0.9),
			},
			border: ifFeature('encore', mixins.border(1, constructs.border.primary.medium), mixins.border(1, palette.primary.light)),
			color: ifFeature('encore', constructs.text.primary.strong),
			fontWeight: ifFeature('encore', 600),
			'&.Mui-active': {
				color: ifFeature('encore', constructs.text.neutral.inverted),
				fontWeight: ifFeature('encore', 700),
				backgroundColor: ifFeature('encore', constructs.surface.primary.strong),
			},
			'&.Mui-error': {
				backgroundColor: ifFeature('encore', constructs.surface.error.strong, palette.error.main),
				color: ifFeature('encore', constructs.text.neutral.forcedWhite),
				'&.Mui-disabled': {
					border: ifFeature('encore', mixins.border(1, constructs.border.error.medium)),
				},
			},
		},
		'&.FabricCalendarDay-inRange': {
			backgroundColor: ifFeature('encore', constructs.surface.primary.weak, mixins.alpha(palette.primary.lightest, 0.1)),
			border: ifFeature('encore', undefined, mixins.border(1, mixins.alpha(palette.primary.lightest, 0.5))),
			color: ifFeature('encore', constructs.text.primary.strong),
			'&.MuiPickersDay-today': {
				backgroundColor: ifFeature('encore', constructs.surface.primary.weak, mixins.alpha(palette.primary.lighter, 0.2)),
				border: ifFeature('encore', mixins.border(1, constructs.border.primary.medium), mixins.border(1, palette.gray[400])),
			},
			'&.Mui-error': {
				color: ifFeature('encore', constructs.text.error.strong),
				border: mixins.border(1, palette.error.main),
				backgroundColor: mixins.alpha(palette.error.main, 0.1),
			},
		},
		'&.Mui-focused': {
			backgroundColor: ifFeature('encore', constructs.surface.primary.strong, `${palette.primary.main} !important`),
			border: mixins.border(1, palette.primary.light),
			color: `${palette.primary.contrastText} !important`,
			fontWeight: ifFeature('encore', 600),
			zIndex: 2,
			'&.Mui-error': {
				backgroundColor: mixins.alpha(palette.error.main, 0.2),
				borderColor: ifFeature('encore', constructs.surface.error.strong, palette.error.main),
			},
		},
		'&.Mui-selected': {
			'&:hover': {
				backgroundColor: ifFeature('encore', constructs.surface.primary.weak, mixins.alpha(palette.primary.lighter, 0.9)),
				color: ifFeature('encore', constructs.text.primary.strong),
			},
			// @startCleanup encore
			border: ifFeature('encore', undefined, mixins.border(1, palette.primary.light)),
			// @endCleanup encore
			fontWeight: ifFeature('encore', 700),
			backgroundColor: ifFeature('encore', constructs.surface.primary.strong),
			'&.Mui-error': {
				backgroundColor: ifFeature('encore', constructs.surface.error.strong, palette.error.main),
				color: ifFeature('encore', constructs.text.neutral.forcedWhite),
			},
		},
		'&.Mui-disabled': {
			color: ifFeature('encore', constructs.text.neutral.weak),
			backgroundColor: ifFeature('encore', constructs.surface.neutral.xxWeak),
			// @startCleanup encore
			'&.Mui-selected.Mui-error, &.FabricCalendarDay-marked.Mui-error': {
				color: ifFeature('encore', constructs.text.neutral.weak),
				backgroundColor: ifFeature('encore', constructs.surface.error.weak),
				fontWeight: ifFeature('encore', 600),
				border: ifFeature('encore', 'none'),
			},
			// @endCleanup encore
		},
	};
});
