import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = ({ constructs, mixins, palette }: Theme) =>
	createStyles({
		root: {
			borderRadius: '2px',
			'& .MuiButtonGroup-grouped:hover': {
				boxShadow: ifFeature(
					'encore',
					`1px 1px 0px 2px ${mixins.alpha(constructs.surface.neutral.xxStrong, 0.03)}`,
					'0 2px 2px 0 rgba(0, 0, 0, 0.1)'
				),
			},
		},
		grouped: {
			minWidth: '0',
		},
		groupedHorizontal: {
			'&:not(:first-child) .MuiButton-root': {
				borderTopLeftRadius: 0,
				borderBottomLeftRadius: 0,
			},
			'&:not(:last-child) .MuiButton-root': {
				borderTopRightRadius: 0,
				borderBottomRightRadius: 0,
			},
			'&&.Mui-disabled': {
				'&:not(:first-child), &:not(:first-child) .MuiButton-root': {
					marginLeft: ifFeature('encore', '0', '-1px'),
					'$altStyling &': {
						borderLeftColor: 'transparent',
					},
				},
				'&&': ifFeature('encore', {
					borderColor: 'transparent',
					marginRight: '1px',
				}),
			},
		},
		groupedContainedSecondary: ifFeature(
			'encore',
			{
				'&&:not(.Mui-disabled):not(:last-child), &:not(.Mui-disabled):not(:last-child) MuiButton-root .MuiButton-grouped': {
					borderRightWidth: '1px',
					marginRight: '-1px',
				},
			},
			{}
		),
		groupedOutlined: ifFeature(
			'encore',
			{
				'&&:not(.Mui-disabled):not(:last-child)': {
					borderRightWidth: '1px',
				},
			},
			{}
		),
		groupedContainedHorizontal: {
			'&:not(.Mui-disabled):not(:last-child), &:not(.Mui-disabled):not(:last-child) .MuiButton-root': {
				marginRight: '1px',
				/* @startCleanup encore */
				border: ifFeature('encore', undefined, 'none'),
				/* @endCleanup encore */
			},
		},
		altStyling: {
			'& .Mui-disabled.MuiButtonGroup-grouped': {
				'&, &:not(:last-child)': {
					borderRightColor: mixins.alpha?.(palette.background.default, 0.5),
				},
			},
			'& .MuiButtonGroup-grouped:hover': {
				boxShadow: ifFeature('encore', `1px 1px 0 1px ${mixins.alpha?.(constructs.border.neutral.weak, 0.2)}`),
			},
		},
	});
