import { ifFeature } from '@bamboohr/utils/lib/feature';
import { makeStyles } from '@mui/styles';

interface styleProps {
	swatchColor?: string | null;
	swatchWidth: number;
	orientation: 'horizontal' | 'vertical';
}

export const useStyles = makeStyles(({ palette, typography }) => ({
	root: {
		'& button': {
			border: 'none',
			fontWeight: ifFeature('encore', typography.fontWeightMedium, typography.fontWeightRegular),
			height: 'auto',
			fontSize: ifFeature('encore', typography.small.fontSize),
			lineHeight: ifFeature('encore', typography.small.lineHeight, typography.fabricLineHeight('medium')),
			justifyContent: ifFeature('encore', 'flex-end'),
			minWidth: ifFeature('encore', 0),
		},
	},
	swatch: (props: styleProps) => ({
		display: 'flex',
		alignItems: 'center',
		'&:before': {
			content: '""',
			display: 'flex',
			flexShrink: 0,
			alignItems: 'center',
			justifyContent: 'center',
			width: props.swatchWidth,
			height: 20,
			marginRight: 8,
			borderRadius: ifFeature('encore', 4, 3),
			backgroundColor: props.swatchColor || palette.gray[400],
		},
	}),
	percentage: {
		marginLeft: 8,
		color: ifFeature('encore', palette.gray[600], palette.gray[800]),
	},
	value: {
		display: 'flex',
		flexGrow: 1,
		justifyContent: 'flex-end',
		marginLeft: 32,
	},
	totalRow: {
		fontWeight: ifFeature('encore', typography.fontWeightSemibold, typography.fontWeightBold),
		'& p': {
			fontWeight: ifFeature('encore', typography.fontWeightSemibold, typography.fontWeightBold),
		},
		paddingTop: ({ orientation }: styleProps) => (orientation === 'vertical' ? 8 : 0),
	},
	total: {
		marginLeft: ifFeature('encore', undefined, (props: styleProps) => props.swatchWidth + 8),
		fontSize: ifFeature('encore', typography.small.fontSize),
		lineHeight: ifFeature('encore', typography.small.lineHeight),
	},
}));
