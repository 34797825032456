import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => {
	const { palette } = theme;

	return createStyles({
		root: {
			display: 'flex',
			fill: palette.common.white,
		},
	});
});
