import React, { forwardRef, Ref } from 'react';
import { Box as MUIBox } from '@mui/material';
import { LayoutBoxComponentType, LayoutBoxProps } from './layout-box.types';
import { formatBaseProps } from '~utils';
import { makeStyles, ModeOptions, dyeColors } from '~styles';

const useStyles = makeStyles<Pick<LayoutBoxProps, 'objectFit' | 'overflowWrap'>>()(({ mode }, { objectFit, overflowWrap }) => ({
	root: {
		objectFit,
		outline: mode === ModeOptions.Dye ? `1px solid ${dyeColors.main}` : undefined,
		overflowWrap,
	},
}));

function LayoutBoxComponent<C extends keyof JSX.IntrinsicElements = 'div'>(props: LayoutBoxProps<C>, ref: Ref<HTMLElement>) {
	const { children, objectFit = 'fill', overflowWrap, ...rest } = props;
	const { classes } = useStyles({ objectFit, overflowWrap });
	return (
		// casting for performance
		<MUIBox className={classes.root} ref={ref} {...formatBaseProps(rest as object)}>
			{children}
		</MUIBox>
	);
}

// passing generic type params for performance
export const LayoutBox = forwardRef<Element, LayoutBoxProps>(LayoutBoxComponent) as LayoutBoxComponentType;
