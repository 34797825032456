import { createStyles, makeStyles } from '@mui/styles';
import { dyeColors, ModeOptions } from '~styles';
import { AvatarListOptionStyleProps } from './types';
import { Theme } from '@mui/material';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const useStyles = makeStyles<Theme, AvatarListOptionStyleProps>(theme => {
	const { palette, spacing, typography, mode } = theme;

	return ifFeature(
		'encore',
		createStyles({
			root: {
				outline: mode === ModeOptions.Dye ? `2px solid ${dyeColors.main}` : undefined,
				padding: 0,
			},
			text: {
				display: 'flex',
				flexDirection: 'column',
				gap: spacing(0.25),
				margin: 0,
			},
			textPrimary: {
				...typography.medium,
				color: ({ disabled }) => (disabled ? palette.gray[600] : palette.gray[1000]),
			},
			textSecondary: {
				...typography.xsmall,
				color: palette.gray[600],
			},
		}),
		createStyles({
			root: {
				backgroundColor: ({ highlighted }: AvatarListOptionStyleProps) => (highlighted ? palette.primary.main : 'transparent'),
				outline: mode === ModeOptions.Dye ? `2px solid ${dyeColors.main}` : undefined,
				padding: spacing(1, 1, 1, 2),
				'& .MuiListItemAvatar-root': {
					minWidth: 0,
				},
				'& .MuiListItemText-root': {
					margin: 0,
				},
			},
			avatar: {
				backgroundColor: ({ highlighted, isStringAvatar }: AvatarListOptionStyleProps) => {
					if (isStringAvatar) {
						return highlighted ? palette.primary.contrastText : palette.primary.main;
					}
					return 'transparent';
				},
				color: ({ highlighted }: AvatarListOptionStyleProps) =>
					highlighted ? palette.primary.main : palette.primary.contrastText,
				fill: ({ highlighted }: AvatarListOptionStyleProps) => {
					if (mode === ModeOptions.Dye) {
						return dyeColors.main;
					}
					if (highlighted) {
						return palette.primary.contrastText;
					}
					return palette.primary.main;
				},
				height: 32,
				marginRight: 10,
				width: 32,
			},
			textPrimary: {
				color: ({ highlighted }: AvatarListOptionStyleProps) =>
					highlighted ? palette.primary.contrastText : palette.grey[1000],
				fontSize: 15,
				lineHeight: '22px',
			},
			textSecondary: {
				color: ({ highlighted }: AvatarListOptionStyleProps) =>
					highlighted ? palette.primary.contrastText : palette.grey[700],
				fontSize: 13,
				lineHeight: '22px',
			},
		})
	);
});
