import { makeStyles } from '@mui/styles';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export const useStyles = makeStyles(({ palette, spacing, typography }) => ({
	root: {},
	groupLabel: {
		color: palette.gray[700],
		fontSize: typography.teenie?.fontSize,
		lineHeight: typography.teenie?.lineHeight,
		// @startCleanup encore
		marginLeft: ifFeature('encore', undefined, 10),
		// @endCleanup encore
		margin: ifFeature('encore', spacing(1, 2)),
	},
	groupEnd: {
		paddingBottom: ifFeature('encore', 0, 5),
	},
	groupSeparator: {
		// @startCleanup encore
		borderTop: ifFeature('encore', undefined, `1px solid ${palette.gray[300]}`),
		// @endCleanup encore
		padding: ifFeature('encore', `4px ${spacing(1)}`),
	},
	groupStart: {
		paddingTop: ifFeature('encore', 0, spacing(1)),
	},
}));
