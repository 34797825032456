/* eslint-disable react/forbid-component-props */
import { PickersDayProps } from '@mui/x-date-pickers';
import composeRefs from '@seznam/compose-react-refs';
import classNames from 'classnames';
import { findLast, isBoolean } from 'lodash';
import React, { forwardRef, MouseEvent, RefObject, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { tabbable } from 'tabbable';
import { GridRow } from '~components/grid-row';
import { ScreenReaderOnlyText } from '~components/screen-reader-only-text';
import { useDatePickerUtils, useWeeksInMonth } from '../../hooks';
import { DAY_MEMOIZE_MODE_FULL_TIMEOUT_MILLISECONDS, navigateWithKeyboard } from '../../shared.domain';
import {
	DatePickerCalendarDayMemoizeMode,
	DatePickerCalendarDayOnMouseEnterParam,
	DatePickerCalendarRangeProps,
	DatePickerCalendarRangeStyleProps,
	DatePickerDate,
	DatePickerDateType,
	MonthValue,
	SelectMonthYearOnChangeParam,
} from '../../types';
import {
	getDateFromYearMonth,
	getDateInfo,
	getDisplayMonthAbbreviations,
	getIsDateValid,
	getRangeYears,
	isNavigationKey,
} from '../../utils';
import { Calendar, DatePickerCalendarDayMemoized } from '../date-picker-calendar-day';
import { DatePickerHeader } from '../date-picker-header';
import {
	getCalendarDayState,
	getDatePickerCalendarRangeOnChangeParam,
	getInitialDateFromRange,
	getIsDateSelectable,
	getIsLeftArrowButtonDisabled,
	getIsRightArrowButtonDisabled,
	getUpdatedRange,
	getViewDate,
} from './date-picker-calendar-range.domain';
import { useStyles } from './date-picker-calendar-range.styles';
import { getClosestEnabledAndVisibleDate, getDerivedMaxDate, getDerivedMinDate } from './domain';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Flex } from '~components/flex';

function DatePickerCalendarRangeComponent(
	{
		autofocus = false,
		className,
		classes = {},
		disabled = false,
		endDate,
		getDateDisabled,
		maxDate: maxDateProp,
		maxRangeSpanYears,
		minDate: minDateProp,
		onChange,
		onMenuClose,
		onMenuOpen,
		renderDate,
		startDate,
		targetSelectionType,
		validateDisabledDatesWithinRange = false,
	}: DatePickerCalendarRangeProps,
	ref: RefObject<HTMLDivElement>
): JSX.Element {
	const refIsUnmounted = useRef(false);
	const refStartDateSnapshot = useRef<DatePickerDate>(startDate);
	const refEndDateSnapshot = useRef<DatePickerDate>(endDate);
	const refDayMemoizeTimeoutId = useRef<NodeJS.Timeout>();
	const refRoot = useRef<HTMLDivElement>(null);
	const utils = useDatePickerUtils();

	const [viewDate, setViewDate] = useState<DatePickerDate>(() => getViewDate(utils, startDate, endDate, targetSelectionType));

	const maxDate = useMemo(
		() =>
			getDerivedMaxDate(utils, {
				endDate,
				maxDate: maxDateProp,
				maxRangeSpanYears,
				startDate,
				targetSelectionType,
			}),
		[endDate, maxDateProp, maxRangeSpanYears, startDate, targetSelectionType, utils]
	);
	const minDate = useMemo(
		() =>
			getDerivedMinDate(utils, {
				endDate,
				maxRangeSpanYears,
				minDate: minDateProp,
				startDate,
				targetSelectionType,
			}),
		[endDate, maxRangeSpanYears, minDateProp, startDate, targetSelectionType, utils]
	);

	const disableBeforeStartDate = getIsDateValid(utils, startDate) && targetSelectionType === 'end';
	const disableAfterEndDate = getIsDateValid(utils, endDate) && targetSelectionType === 'start';

	const getClosestFocusableDate = useCallback(
		(viewDate: DatePickerDate, focusedDate: DatePickerDate) => {
			return getClosestEnabledAndVisibleDate(
				utils,
				viewDate,
				utils.getNextMonth(viewDate as DatePickerDateType),
				targetSelectionType,
				{
					getDateDisabled,
					maxDate,
					minDate,
				},
				focusedDate
			);
		},
		// We don't want to put the getDateDisabled prop in the dependency array
		// to avoid possible infinite render loops
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[maxDate, minDate, targetSelectionType, utils]
	);

	const getInitialFocusDate = useCallback(() => {
		const initialDate = getInitialDateFromRange(utils, startDate, endDate, targetSelectionType);
		const closestFocusedDate = getClosestFocusableDate(viewDate, initialDate);
		return closestFocusedDate;
	}, [endDate, getClosestFocusableDate, startDate, targetSelectionType, utils, viewDate]);

	function focus() {
		if (!refRoot.current) {
			return;
		}

		const tabbableElements = tabbable(refRoot.current);
		const calendarDayElement = findLast(tabbableElements, element => element.hasAttribute('data-calendar-day'));

		if (calendarDayElement) {
			setHasFocus(true);
			return;
		}

		tabbableElements[0]?.focus();
	}

	const [focusedDate, setFocusedDate] = useState<DatePickerDate>(getInitialFocusDate);
	const [hoveredDate, setHoveredDate] = useState<DatePickerDate>(focusedDate);

	const [justSelected, setJustSelected] = useState(false);
	const [dayMemoizeMode, setDayMemoizeMode] = useState<DatePickerCalendarDayMemoizeMode>('full');
	const [hasFocus, setHasFocus] = useState(autofocus);

	const viewDateSecondary = utils.getNextMonth(viewDate as DatePickerDateType);
	const viewDateWeeksCount = utils.getWeekArray(viewDate as DatePickerDateType).length;
	const viewDateSecondaryWeeksCount = utils.getWeekArray(viewDateSecondary).length;
	const styleProps: DatePickerCalendarRangeStyleProps = {
		maxWeekRowsCount: Math.max(viewDateWeeksCount, viewDateSecondaryWeeksCount),
	};
	const styles = useStyles(styleProps);

	useLayoutEffect(() => {
		setViewDate(getViewDate(utils, startDate, endDate, targetSelectionType));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [targetSelectionType]);

	useEffect(() => {
		if (autofocus) {
			focus();
		}
	}, [autofocus]);

	const years = getRangeYears(utils, minDate, maxDate);

	const leftArrowButtonDisabled = getIsLeftArrowButtonDisabled(
		utils,
		viewDate,
		disabled,
		minDate,
		startDate,
		disableBeforeStartDate
	);
	const rightArrowButtonDisabled = getIsRightArrowButtonDisabled(
		utils,
		viewDateSecondary,
		disabled,
		maxDate,
		endDate,
		disableAfterEndDate
	);
	const displayMonths = getDisplayMonthAbbreviations(utils);
	const viewDateYear = utils.getYear(viewDate as DatePickerDateType);
	const viewDateMonth = (utils.getMonth(viewDate as DatePickerDateType) + 1) as MonthValue;
	const viewDateSecondaryYear = utils.getYear(viewDateSecondary);
	const viewDateSecondaryMonth = (utils.getMonth(viewDateSecondary) + 1) as MonthValue;

	const handleDayMouseEnterMemoized = useCallback(
		(e, param: DatePickerCalendarDayOnMouseEnterParam) => {
			const { date } = param;

			if (validateDisabledDatesWithinRange) {
				if (refDayMemoizeTimeoutId.current) {
					clearTimeout(refDayMemoizeTimeoutId.current);
					refDayMemoizeTimeoutId.current = undefined;
				}

				refDayMemoizeTimeoutId.current = setTimeout(() => {
					if (refIsUnmounted.current) {
						return;
					}

					setDayMemoizeMode('full');
					refDayMemoizeTimeoutId.current = undefined;
				}, DAY_MEMOIZE_MODE_FULL_TIMEOUT_MILLISECONDS);

				setDayMemoizeMode('quick');
			}

			setHoveredDate(date);
			setJustSelected(false);
		},
		[validateDisabledDatesWithinRange]
	);
	const handleDayMouseLeaveMemoized = useCallback(() => {
		setHoveredDate(focusedDate);
	}, [focusedDate]);

	useLayoutEffect(() => {
		return () => {
			refIsUnmounted.current = true;
		};
	}, []);

	/**
	 * This function updates the hovered state and focused state to match the `newActiveDate` value.
	 * @param newActiveDate The date that is becoming active
	 */
	function updateActiveDate(newActiveDate: DatePickerDate) {
		setHoveredDate(newActiveDate);
		setFocusedDate(newActiveDate);
	}

	function updateRange(date: DatePickerDate, state?: string | boolean, justSelected?: boolean): void {
		const isFinished = state === 'finish' || state === true;
		if (!isFinished) {
			return;
		}

		if (
			!getIsDateSelectable(
				utils,
				date,
				disabled,
				refStartDateSnapshot.current,
				refEndDateSnapshot.current,
				targetSelectionType,
				minDate,
				maxDate,
				maxRangeSpanYears,
				getDateDisabled,
				disableBeforeStartDate,
				disableAfterEndDate
			)
		) {
			return;
		}

		const { startDate: start, endDate: end } = getUpdatedRange(
			utils,
			date,
			refStartDateSnapshot.current,
			refEndDateSnapshot.current,
			targetSelectionType
		);

		const param = getDatePickerCalendarRangeOnChangeParam(start, end);
		onChange(param);
		setJustSelected(isBoolean(justSelected) ? justSelected : true);
	}

	function snapshotDates(): void {
		refStartDateSnapshot.current = startDate;
		refEndDateSnapshot.current = endDate;
	}

	function handleKeydown(event: React.KeyboardEvent) {
		if (event.key === ' ' || event.key === 'Enter') {
			if (focusedDate) {
				event.preventDefault();

				snapshotDates();
				updateRange(focusedDate, true);
			}
			return;
		}

		if (isNavigationKey(event)) {
			event.preventDefault();

			const newDate = navigateWithKeyboard({
				activeDate: hoveredDate,
				getDateDisabled,
				keyboardKey: event.key,
				maxDate,
				minDate,
				shiftKeyPressed: event.shiftKey,
				utils,
			});

			if (
				!utils.isSameMonth(newDate as DatePickerDateType, viewDate as DatePickerDateType) &&
				!utils.isSameMonth(newDate as DatePickerDateType, viewDateSecondary)
			) {
				if (utils.isBefore(newDate as DatePickerDateType, viewDate as DatePickerDateType)) {
					setViewDate(utils.startOfMonth(newDate as DatePickerDateType));
				} else {
					setViewDate(utils.startOfMonth(utils.addMonths(newDate as DatePickerDateType, -1)));
				}
			}

			updateActiveDate(newDate);
			setJustSelected(false);
		}
	}

	function handleClick({ currentTarget }: MouseEvent<HTMLDivElement>) {
		if (!currentTarget.contains(document.activeElement)) {
			focus();
		}
	}

	const handleMonthChange = (newViewDate: DatePickerDate) => {
		const closestFocusedDate = getClosestFocusableDate(newViewDate, focusedDate);

		updateActiveDate(closestFocusedDate);
		setViewDate(newViewDate);
	};

	function handleRenderDate(date: DatePickerDate, dayProps: PickersDayProps<DatePickerDate>, currentMonth: DatePickerDate) {
		if (renderDate) {
			const dateInfo = getDateInfo(utils, date);
			const startDateInfo = getDateInfo(utils, startDate);
			const endDateInfo = getDateInfo(utils, endDate);
			return renderDate(dateInfo, startDateInfo, endDateInfo, dayProps);
		}
		const { outsideCurrentMonth } = dayProps;
		const calendarDayState = getCalendarDayState(
			utils,
			date,
			startDate,
			endDate,
			focusedDate,
			hoveredDate,
			disabled,
			justSelected,
			!outsideCurrentMonth,
			validateDisabledDatesWithinRange,
			dayMemoizeMode,
			targetSelectionType,
			hasFocus,
			minDate,
			maxDate,
			maxRangeSpanYears,
			getDateDisabled,
			disableBeforeStartDate,
			disableAfterEndDate
		);

		const { active, errored, marked, disabled: dayDisabled, selected, focused, highlighted } = calendarDayState;

		return (
			<DatePickerCalendarDayMemoized
				{...dayProps}
				active={active}
				day={date}
				disabled={dayDisabled}
				errored={errored}
				focused={focusedDate && utils.isSameMonth(focusedDate, currentMonth as DatePickerDateType) ? focused : false}
				inRange={highlighted}
				marked={marked}
				onMouseEnter={handleDayMouseEnterMemoized}
				onMouseLeave={handleDayMouseLeaveMemoized}
				selected={selected}
			/>
		);
	}

	function handleSelectMonthYearChange({ month, year }: SelectMonthYearOnChangeParam): void {
		const newViewDate = getDateFromYearMonth(utils, year, month);
		const newFocusedDate = getClosestFocusableDate(newViewDate, focusedDate);

		updateActiveDate(newFocusedDate);
		setViewDate(newViewDate);
	}

	function handleSecondarySelectMonthYearChange({ month, year }: SelectMonthYearOnChangeParam): void {
		const newViewDateSecondary = getDateFromYearMonth(utils, year, month) as DatePickerDateType;
		const newViewDate = utils.getPreviousMonth(newViewDateSecondary);
		const newFocusedDate = getClosestFocusableDate(newViewDate, focusedDate);

		updateActiveDate(newFocusedDate);
		setViewDate(newViewDate);
	}

	function handleArrowClick(event: string): void {
		if (event === 'next-month') {
			handleMonthChange(utils.addMonths(viewDate as DatePickerDateType, 1));
			return;
		}
		handleMonthChange(utils.addMonths(viewDate as DatePickerDateType, -1));
	}

	useEffect(() => {
		updateActiveDate(getInitialFocusDate());
	}, [startDate, endDate, targetSelectionType, getInitialFocusDate]);

	const viewWeeks = useWeeksInMonth(utils, viewDate);
	const secondaryViewWeeks = useWeeksInMonth(utils, viewDateSecondary);
	const weeksToShow = Math.max(viewWeeks, secondaryViewWeeks);

	return (
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
		<div
			className={classNames(styles.root, styles.picker, className, classes.root)}
			onClick={handleClick}
			onMouseDown={snapshotDates}
			ref={composeRefs(ref, refRoot)}
		>
			{ifFeature(
				'encore',
				<div>
					<Flex justifyContent="space-evenly" marginTop={1} marginX={3.4}>
						<DatePickerHeader
							disabled={disabled}
							hideRightArrow={true}
							leftArrowDisabled={leftArrowButtonDisabled}
							maxDate={maxDate}
							minDate={minDate}
							months={displayMonths}
							monthValue={viewDateMonth}
							onArrowClick={handleArrowClick}
							onChange={handleSelectMonthYearChange}
							onMenuClose={onMenuClose}
							onMenuOpen={onMenuOpen}
							years={years}
							yearValue={viewDateYear}
						/>
						<DatePickerHeader
							disabled={disabled}
							hideLeftArrow={true}
							maxDate={maxDate}
							minDate={minDate}
							months={displayMonths}
							monthValue={viewDateSecondaryMonth}
							onArrowClick={handleArrowClick}
							onChange={handleSecondarySelectMonthYearChange}
							onMenuClose={onMenuClose}
							onMenuOpen={onMenuOpen}
							rightArrowDisabled={rightArrowButtonDisabled}
							years={years}
							yearValue={viewDateSecondaryYear}
						/>
					</Flex>
					{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
					<div
						className={styles.calendars}
						onBlur={() => setHasFocus(false)}
						onFocus={() => setHasFocus(true)}
						onKeyDown={handleKeydown}
					>
						<Calendar
							date={viewDate || null}
							maxDate={maxDate /* This must be passed in or CalendarPicker from MUI will still try to navigate. */}
							minDate={minDate /* This must be passed in or CalendarPicker from MUI will still try to navigate. */}
							onChange={updateRange}
							renderDay={(day, _, pickersDayProps) => handleRenderDate(day, pickersDayProps, viewDate)}
							weeksToShow={weeksToShow}
						/>
						{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
						<div className={classNames(styles.endPicker)}>
							<Calendar
								date={viewDateSecondary || null}
								maxDate={maxDate /* This must be passed in or CalendarPicker from MUI will still try to navigate. */}
								minDate={minDate /* This must be passed in or CalendarPicker from MUI will still try to navigate. */}
								onChange={updateRange}
								renderDay={(day, _, pickersDayProps) => handleRenderDate(day, pickersDayProps, viewDateSecondary)}
								weeksToShow={weeksToShow}
							/>
						</div>
					</div>
				</div>,
				<GridRow container margin="none" spacing={0}>
					<GridRow margin="none">
						<GridRow margin="none" marginTop={1} marginX={1}>
							<DatePickerHeader
								disabled={disabled}
								hideRightArrow={true}
								leftArrowDisabled={leftArrowButtonDisabled}
								maxDate={maxDate}
								minDate={minDate}
								months={displayMonths}
								monthValue={viewDateMonth}
								onArrowClick={handleArrowClick}
								onChange={handleSelectMonthYearChange}
								onMenuClose={onMenuClose}
								onMenuOpen={onMenuOpen}
								years={years}
								yearValue={viewDateYear}
							/>
						</GridRow>
						<GridRow justifyContent="end" margin="none">
							<DatePickerHeader
								disabled={disabled}
								hideLeftArrow={true}
								maxDate={maxDate}
								minDate={minDate}
								months={displayMonths}
								monthValue={viewDateSecondaryMonth}
								onArrowClick={handleArrowClick}
								onChange={handleSecondarySelectMonthYearChange}
								onMenuClose={onMenuClose}
								onMenuOpen={onMenuOpen}
								rightArrowDisabled={rightArrowButtonDisabled}
								years={years}
								yearValue={viewDateSecondaryYear}
							/>
						</GridRow>
					</GridRow>
					{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
					<div
						className={styles.calendars}
						onBlur={() => setHasFocus(false)}
						onFocus={() => setHasFocus(true)}
						onKeyDown={handleKeydown}
					>
						<Calendar
							date={viewDate || null}
							maxDate={maxDate /* This must be passed in or CalendarPicker from MUI will still try to navigate. */}
							minDate={minDate /* This must be passed in or CalendarPicker from MUI will still try to navigate. */}
							onChange={updateRange}
							renderDay={(day, _, pickersDayProps) => handleRenderDate(day, pickersDayProps, viewDate)}
							weeksToShow={weeksToShow}
						/>
						{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
						<div className={classNames(styles.endPicker)}>
							<Calendar
								date={viewDateSecondary || null}
								maxDate={maxDate /* This must be passed in or CalendarPicker from MUI will still try to navigate. */}
								minDate={minDate /* This must be passed in or CalendarPicker from MUI will still try to navigate. */}
								onChange={updateRange}
								renderDay={(day, _, pickersDayProps) => handleRenderDate(day, pickersDayProps, viewDateSecondary)}
								weeksToShow={weeksToShow}
							/>
						</div>
					</div>
				</GridRow>
			)}
			<ScreenReaderOnlyText ariaAtomic={true} ariaLive="polite">
				{window.jQuery
					? $.__(
							'Showing %1$s and %2$s',
							utils.format(viewDate as DatePickerDateType, 'monthAndYear'),
							utils.format(viewDateSecondary, 'monthAndYear')
						)
					: `Showing ${utils.format(viewDate as DatePickerDateType, 'monthAndYear')} and ${utils.format(
							viewDateSecondary,
							'monthAndYear'
						)}`}
			</ScreenReaderOnlyText>
		</div>
	);
}

export const DatePickerCalendarRange = forwardRef(DatePickerCalendarRangeComponent);
