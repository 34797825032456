/* eslint-disable react/forbid-component-props */
import classNames from 'classnames';
/* @startCleanup encore */
import { PersonCircle32x32 } from '@bamboohr/grim';
/* @endCleanup encore */
import { isString } from 'lodash';
import {
	ListItem,
	/* @startCleanup encore */
	Avatar as MUIAvatar,
	ListItemAvatar,
	/* @endCleanup encore */
	ListItemText,
} from '@mui/material';
import { Avatar } from '~components/avatar';
import React from 'react';

import { useStyles } from './avatar-list-option.styles';
import { AvatarListOptionProps } from './types';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { LayoutBox } from '~components/layout-box';

export function AvatarListOption({
	avatar,
	avatarAlt,
	avatarSrc,
	classes = {},
	className,
	component = 'li',
	description,
	disabled,
	/* @startCleanup encore */
	highlighted = false,
	/* @endCleanup encore */
	title,
	/* @startCleanup encore */
	variant = 'circular',
	/* @endCleanup encore */
	...rest
}: AvatarListOptionProps): JSX.Element {
	const styles = useStyles({
		disabled,
		/* @startCleanup encore */
		highlighted,
		isStringAvatar: !!avatar && isString(avatar),
		/* @endCleanup encore */
	});

	return (
		<ListItem
			className={ifFeature('encore', styles.root, classNames(styles.root, className, classes.root))}
			component={component}
			disableGutters={true}
			{...rest}
		>
			{ifFeature(
				'encore',
				<LayoutBox flexShrink={0} marginRight={1}>
					<Avatar alt={avatarAlt} size={32} src={avatarSrc}>
						{renderAvatarChildren()}
					</Avatar>
				</LayoutBox>,
				<ListItemAvatar>
					<MUIAvatar
						alt={avatarAlt}
						classes={{ root: classNames(styles.avatar, classes.avatar) }}
						src={avatarSrc}
						variant={variant}
					>
						{renderAvatarChildren()}
					</MUIAvatar>
				</ListItemAvatar>
			)}
			<ListItemText
				className={styles.text}
				primary={title}
				primaryTypographyProps={{
					className: ifFeature('encore', styles.textPrimary, classNames(styles.textPrimary, classes.primaryText)),
				}}
				secondary={description}
				secondaryTypographyProps={{
					className: ifFeature('encore', styles.textSecondary, classNames(styles.textSecondary, classes.secondaryText)),
				}}
			/>
		</ListItem>
	);

	function renderAvatarChildren() {
		if (avatarSrc) {
			return null;
		}

		if (avatar) {
			return avatar;
		}

		return ifFeature('encore', undefined, <PersonCircle32x32 />);
	}
}
