import React, { ReactElement, useImperativeHandle, useRef, useState } from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { useStyles } from './chip.styles';
import { IconV2 } from '~components/icon-v2';
import { ChipProps } from './chip.types';
import { uniqueId } from 'lodash';

export function Chip({
	apiRef,
	ariaLabel = window.jQuery ? $.__('remove') : 'remove',
	biId,
	id,
	label,
	onRemove,
}: ChipProps): ReactElement {
	const { classes } = useStyles({ label, onRemove });
	const [hovered, setHovered] = useState(false);
	const labelID = uniqueId();
	const chipRef = useRef<HTMLButtonElement>(null);

	useImperativeHandle(
		apiRef,
		() => {
			return {
				focus: () => {
					chipRef.current?.focus();
				},
			};
		},
		[]
	);

	return (
		<>
			{ifFeature(
				'encore',
				<div className={hovered ? classes.hover : classes.root} data-bi-id={biId} id={id}>
					<div className={classes.label} id={labelID}>
						{label}
					</div>
					{onRemove && (
						<button
							aria-describedby={labelID}
							aria-label={ariaLabel}
							className={classes.deleteIcon}
							onClick={onRemove}
							onFocus={() => {}}
							onMouseLeave={() => setHovered(false)}
							onMouseOver={() => setHovered(true)}
							ref={chipRef}
							type="button"
						>
							<IconV2 name="circle-x-solid" size={12} />
						</button>
					)}
				</div>,
				'Chip component is only available with Encore enabled.'
			)}
		</>
	);
}
