import { BodyText, Flex, LayoutBox } from '@bamboohr/fabric';
import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import config from '../../config';
import api from '../../services/api';
import BotResponse from '../BotResponse';
import CodeBlock from '../codeBlock/CodeBlock';
import Loading from '../Loading';
import { FeedbackModal } from './feedbackModal.react';
import { CheckIcon, Copy, ThumbsDown, ThumbsUp } from './icons';
import { styles } from './styles';

const grayShade = 'rgb(134,132,141)';
const greenShade = 'rgb(102,188,51)';
const redShade = '#FE4A22';

export const ChatLogRow = (props) => {
	const {
		chat,
		chatId,
		llmLabel,
		chatLogRef,
		currentUser,
		hasPrinted,
		setHasPrinted,
		rating,
		unhelpful,
		incorrect,
		harmful,
		comment,
		userSuggestedAnswer,
	} = props;

	const [isCopied, setIsCopied] = useState(false);
	const [copiedMessageVisible, setCopiedMessageVisible] = useState(false);
	const [isThumbsUp, setIsThumbsUp] = useState(false);
	const [isThumbsDown, setIsThumbsDown] = useState(false);
	const [feedbackModalIsVisible, setFeedbackModalVisible] = useState(false);

	const CHAT_FEEDBACK_ENDPOINT = config.VERSION_ENDPOINT + 'chat/' + chatId;

	const handleCopyClick = () => {
		setIsCopied(true);
		setCopiedMessageVisible(true);
		navigator.clipboard.writeText(chat.botMessage);

		const timer = setTimeout(() => {
			setCopiedMessageVisible(false);
			setIsCopied(false);
		}, 5000);

		return () => clearTimeout(timer);
	};

	const sendFeedback = async (feedback) => {
		api.put(CHAT_FEEDBACK_ENDPOINT, {
			rating: feedback,
			unhelpful: false,
			incorrect: false,
			harmful: false,
			comment: '',
			user_suggested_answer: '',
		});
	};

	useEffect(() => {
		setTimeout(() => {
			if (rating === 'positive') {
				setIsThumbsUp(true);
				setIsThumbsDown(false);
			} else if (rating === 'negative') {
				setIsThumbsUp(false);
				setIsThumbsDown(true);
			} else {
				setIsThumbsUp(false);
				setIsThumbsDown(false);
			}
		}, 100);
	}, [rating]);

	return (
		<div
			className="chatLog"
			ref={chatLogRef}
			id={`navPrompt-${chat.chatPrompt.replace(/[^a-zA-Z0-9]/g, '-')}`}
		>
			<Flex>
				<Flex
					marginX={'40px'}
					marginY="24px"
					justifyContent="center"
					width="100%"
				>
					<LayoutBox maxWidth={'800px'} width={'90%'}>
						<Flex gap="12px" alignItems={'start'}>
							<img
								src={currentUser['picture']}
								alt="Tech Titan Pudgy"
								style={{
									height: '24px',
									width: '24px',
									position: 'relative',
									borderRadius: 150 / 2,
									overflow: 'hidden',
									borderWidth: 3,
								}}
							/>
							<BodyText>Me</BodyText>
						</Flex>
						<LayoutBox marginTop="12px">
							<ReactMarkdown
								className="react-markdown"
								components={{
									code: CodeBlock,
								}}
							>
								{chat.chatPrompt}
							</ReactMarkdown>
						</LayoutBox>
					</LayoutBox>
				</Flex>
			</Flex>

			<div style={styles.botMessageMainContainer}>
				<Flex
					marginX={'40px'}
					paddingY="24px"
					justifyContent="center"
					width="100%"
				>
					<LayoutBox maxWidth={'800px'} width={'90%'}>
						<Flex gap="12px" alignItems={'start'}>
							<img
								src="/images/bamboohrlogo.png"
								alt="BambooHR logo"
								style={styles.avatarImage}
							/>
							<div style={styles.nameContainer}>{llmLabel}</div>
							<div style={styles.buttonContainer}>
								<button
									onClick={() => handleCopyClick()}
									style={styles.iconButton}
								>
									{copiedMessageVisible ? (
										<CheckIcon backgroundFill={'#00000000'} fill={greenShade} />
									) : (
										<Copy fill={isCopied ? greenShade : grayShade} />
									)}
								</button>
								<button
									onClick={() => {
										setIsThumbsUp(!isThumbsUp);
										if (!isThumbsUp) setIsThumbsDown(false);
										setFeedbackModalVisible(false);
										sendFeedback(isThumbsUp ? null : 'positive');
									}}
									style={{
										...styles.iconButton,
									}}
								>
									<ThumbsUp fill={isThumbsUp ? greenShade : grayShade} />
								</button>

								<div>
									{feedbackModalIsVisible && (
										<FeedbackModal
											closeModal={() => setFeedbackModalVisible(false)}
											chatId={chatId}
											unhelpful={unhelpful}
											incorrect={incorrect}
											harmful={harmful}
											comment={comment}
											userSuggestedAnswer={userSuggestedAnswer}
										/>
									)}
									<button
										onClick={() => {
											if (!isThumbsDown) {
												setIsThumbsDown(true);
												setIsThumbsUp(false);
												setFeedbackModalVisible(!feedbackModalIsVisible);
												// Send feedback based on new state
												sendFeedback('negative');
											} else {
												// If thumbs down is active, just deactivate it without showing the modal
												setIsThumbsDown(false);
												sendFeedback(null);
											}
										}}
										style={{
											...styles.iconButton,
										}}
									>
										<ThumbsDown fill={isThumbsDown ? redShade : grayShade} />
									</button>
								</div>
							</div>
						</Flex>
						{chat.botMessage ? (
							<div style={styles.botMessage}>
								<BotResponse
									response={chat.botMessage}
									chatLogRef={chatLogRef}
									hasPrinted={hasPrinted}
									setHasPrinted={setHasPrinted}
									sources={chat.sources}
								/>
							</div>
						) : (
							<Loading />
						)}
					</LayoutBox>
				</Flex>
			</div>
		</div>
	);
};
