import React, { ReactElement, useContext, useMemo } from 'react';
import { format, parseISO } from '@bamboohr/utils/lib/datetime';
import { DayPicker } from 'react-day-picker';
import { DatePickerContext, DatePickerContextValue } from '~components/date-picker';
import { CalendarBaseProps } from './calendar-base.types';
import { useCalendarBaseStyles } from './calendar-base.styles';
import { Day, DayContext } from './day';
import { locales } from './locales';

export function CalendarBase({
	hideNavigation = false,
	month,
	monthsToShow = 1,
	renderDay,
	selectionMode = 'read-only',
}: CalendarBaseProps): ReactElement {
	const { classes } = useCalendarBaseStyles();
	const { locale } = useContext<DatePickerContextValue>(DatePickerContext);
	const monthDate = useMemo(() => parseISO(month) as Date, [month]);

	return (
		<DayPicker
			classNames={{
				day: classes.day,
				hidden: classes.hidden,
				month: classes.month,
				month_caption: classes.monthCaption,
				month_grid: classes.monthGrid,
				months: classes.months,
				root: classes.root,
				weekday: classes.weekday,
				weekdays: classes.weekdays,
				weeks: classes.weeks,
			}}
			components={{
				DayButton: props => {
					const {
						day: { date },
					} = props;
					return (
						<DayContext.Provider value={props}>
							{typeof renderDay === 'function' ? renderDay(date.toISOString()) : <CalendarBase.Day />}
						</DayContext.Provider>
					);
				},
			}}
			disableNavigation={hideNavigation}
			formatters={{
				// Display the 3-letter abbreviation of the month
				formatCaption: date => format(date, 'LLL y', { locale: locales[locale] }),
				// Only display the weekday's first letter
				formatWeekdayName: date => format(date, 'ccccc', { locale: locales[locale] }),
			}}
			hideNavigation={hideNavigation}
			locale={locales[locale]}
			month={monthDate}
			numberOfMonths={monthsToShow}
			onDayClick={selectionMode === 'read-only' ? () => {} : undefined}
		/>
	);
}

CalendarBase.Day = Day;
