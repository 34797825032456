import React, { FC, forwardRef, PropsWithChildren } from 'react';
import { withStyles, WithStyles } from '@mui/styles';
import { SectionHeaderProps } from './types';
import { styles } from './section-header.styles';
import { AdornedText } from '../adorned-text';
import classnames from 'classnames';

/**
 * @deprecated Please use `Headline`, `BodyText`, or `BadgeV2` (coming soon) instead.
 */
export const SectionHeader = withStyles(styles)(
	forwardRef<HTMLElement, SectionHeaderProps & WithStyles<typeof styles>>(
		({ biId, classes, hasMargin = true, icon, label, size = 'medium', subtitle, ...rest }, ref) => {
			return (
				<AdornedText
					adornment={icon}
					alignItems="center"
					biId={biId}
					classes={{ root: classnames(classes.root, { [classes.noMargin]: !hasMargin }) }}
					component="div"
					ref={ref}
					{...rest}
				>
					<div>
						<div className={classnames(classes.label, size === 'large' && classes.labelLarge)}>{label}</div>
						{subtitle ? (
							<div className={classnames(classes.subtitle, size === 'large' && classes.subtitleLarge)}>{subtitle}</div>
						) : null}
					</div>
				</AdornedText>
			);
		}
	)
) as FC<PropsWithChildren<SectionHeaderProps>>;
