// eslint-disable-next-line no-use-before-define
import React, { ReactElement } from 'react';
import { Flex } from '../../flex';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CenteredLabelProps } from '../types';

const useStyles = makeStyles(({ palette, spacing, typography }) => ({
	labelContainer: {
		fontSize: typography.h6.fontSize,
		pointerEvents: 'none',
		color: palette.gray[700],
		'& svg': {
			fill: palette.gray[700],
			marginBottom: spacing(1),
		},
	},
	label: {
		'-webkit-line-clamp': 2,
		display: '-webkit-box',
		overflow: 'hidden',
		'-webkit-box-orient': 'vertical',
		textAlign: 'center',
	},
}));

export const CenteredLabel = ({ label, children, ...rest }: Omit<CenteredLabelProps, 'icon'>): ReactElement => {
	const classes = useStyles();

	return (
		<Flex
			alignItems="center"
			className={classes.labelContainer}
			flexDirection="column"
			height="100%"
			justifyContent="center"
			width="100%"
			{...rest}
		>
			<Flex justifySelf="flex-start">{children}</Flex>
			<Typography className={classes.label} color="inherit">
				{label}
			</Typography>
		</Flex>
	);
};
