import { makeStyles } from '~styles';
import type { LinkProps } from './link.types';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { getEncoreValue } from '~utils';
import type { CSSProperties } from 'react';

export const useStyles = makeStyles<Pick<LinkProps, 'color' | 'inheritFont' | 'size'>>()((theme, params) => {
	const {
		borderRadiuses,
		mixins: { alpha },
		palette,
		typography,
		constructs,
	} = theme;
	const { color, inheritFont, size } = params;

	let fontSize = getEncoreValue<typeof size, Record<NonNullable<typeof size>, CSSProperties>>(size, {
		'extra-small': ifFeature('encore', typography.xsmall!, typography.teenie),
		small: typography.small,
		medium: typography.medium,
		large: typography.large,
	});

	if (inheritFont) {
		fontSize = {
			fontSize: 'inherit',
			lineHeight: 'inherit',
		};
	}

	const getLinkColor = (color: string | undefined) => {
		switch (color) {
			case 'muted':
				return ifFeature('encore', constructs.text.link.muted, palette.gray[700]);
			default:
				return ifFeature(
					'encore',
					inheritFont ? 'inherit' : constructs.text.link.idle,
					inheritFont ? 'inherit' : palette.info.main
				);
		}
	};

	const colorValue = getLinkColor(color);

	return {
		root: {
			...fontSize,
			position: 'relative',
			textDecoration: inheritFont ? 'inherit' : 'none',
			// only apply interactive styles if `href` is present
			'&:any-link': {
				color: colorValue,
				fill: colorValue,
				fontFamily: inheritFont ? 'inherit' : typography.fontFamily,
				fontStyle: inheritFont ? 'inherit' : 'normal',
				fontWeight: inheritFont ? 'inherit' : typography.fontWeightMedium,
				...ifFeature('encore', {
					'&:after': {
						borderRadius: borderRadiuses[200],
						bottom: -4,
						content: "''",
						display: 'block',
						left: -8,
						position: 'absolute',
						right: -8,
						top: -4,
						transition: 'all 75ms',
					},
				}),
				'&:hover': {
					textDecoration: 'underline',
				},
				'&:hover, &:focus-visible': {
					color: palette.primary.main,
					fill: palette.primary.main,
				},
				...ifFeature(
					'encore',
					{
						'&:focus-visible:after': {
							boxShadow: `0 0 0 2px ${alpha(constructs.border.primary.medium, 0.8)}`,
						},
					},
					{
						'&:focus-visible': {
							borderRadius: 2,
							boxShadow: `0 0 0 2px ${palette.primary.lightest}`,
						},
					}
				),
			},
		},
		inverted: {
			'&:any-link': {
				// @startCleanup encore
				fontWeight: ifFeature('encore', undefined, typography.fontWeightBold),
				// @endCleanup encore
				textDecoration: ifFeature('encore', 'underline'),
				'&:hover, &:focus-visible': {
					textDecoration: ifFeature('encore', 'none', 'underline'),
				},
				'&, &:hover, &:focus-visible': {
					color: ifFeature('encore', constructs.text.neutral.inverted, palette.common.white),
					fill: ifFeature('encore', constructs.text.neutral.inverted, palette.common.white),
				},
				...ifFeature(
					'encore',
					{
						'&:focus-visible:after': {
							boxShadow: `0 0 0 2px ${alpha(constructs.border.neutral.inverted, 0.8)}`,
						},
						'&:hover:after': {
							boxShadow: `0 0 0 2px ${alpha(constructs.border.neutral.inverted, 0.3)}`,
						},
					},
					{
						'&:focus-visible': {
							borderRadius: 2,
							boxShadow: `0 0 0 2px ${palette.common.white}`,
						},
					}
				),
			},
		},
	};
});
