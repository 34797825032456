import React, { FC, PropsWithChildren } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { makeStyles } from '@mui/styles';
import { CrossFaderProps } from './types';
import classnames from 'classnames';

const useStyles = makeStyles({
	crossfader: {
		position: 'relative',
		'& > div': {
			position: 'absolute',
		},
	},
	alignRight: {
		'& > div': {
			right: 0,
		},
	},
	alignBottom: {
		'& > div': {
			bottom: 0,
		},
	},
});

/**
 * Fades in the currently shown component while fading out the previously shown component.
 * Components that are not visible will be unmounted, after the fade out animation finishes.
 * One thing to note is that if components higher up in the component hierarchy remount during the transition the `CrossFader` component will be unable to animate the transition.
 */

export const CrossFader: FC<PropsWithChildren<CrossFaderProps>> = ({
	show,
	components,
	alignHorizontal = 'left',
	alignVertical = 'top',
	width = 'inherit',
	height = 'inherit',
}) => {
	const classes = useStyles({ width, height });
	const appliedClasses = classnames(classes.crossfader, {
		[classes.alignRight]: alignHorizontal === 'right',
		[classes.alignBottom]: alignVertical === 'bottom',
	});
	const dimensionStyles = {
		width,
		height,
	};
	return (
		<div className={appliedClasses} role="region" style={dimensionStyles}>
			<AnimatePresence initial={false}>
				{Object.entries(components).map(
					([key, component]) =>
						key === show && (
							<motion.div animate={{ opacity: 1 }} exit={{ opacity: 0 }} initial={{ opacity: 0 }} key={key}>
								{component}
							</motion.div>
						)
				)}
			</AnimatePresence>
		</div>
	);
};
