import React from 'react';
import { LlmSelector } from './LlmSelector';
import FileUploader from './FileUploader';
import config from '../config';
import { BodyText, Flex, Headline, Link, List } from '@bamboohr/fabric';

type IntroSectionProps = {
	handleSubmit: (event: any) => void;
	setNewLlmValue: (value: string) => void;
	setLlmConfig: (config: any) => void;
	setModes: (modes: any) => void;
	setForm: (form: any) => void;
	setInputPrompt: (prompt: string) => void;
	llmId: string;
};

const IntroSection = ({
	handleSubmit,
	setNewLlmValue,
	setLlmConfig,
	setModes,
	setForm,
	setInputPrompt,
	llmId,
}: IntroSectionProps) => {
	return (
		<Flex flexDirection="column" alignItems="center" marginTop={'40px'}>
			<Flex
				flexDirection="column"
				alignItems="center"
				gap={'20px'}
				marginY="20px"
			>
				<Headline color="neutral-inverted" size="medium">
					BambooHR AI Sandbox
				</Headline>
				<img
					src="/images/pudgy.png"
					height="164px"
					width="164px"
					alt="pudgy holding a glowing orb of energy"
				/>
			</Flex>

			<Flex width={'65%'} alignItems="flex-start" flexDirection="column">
				<BodyText size="small">
					Remember that AI is still learning, and it uses large language models
					which:
				</BodyText>

				<Flex marginLeft="20px" textAlign={'left'} justifyContent={'center'}>
					<List size="small">
						<List.Item>
							Are trained on only public, historical internet and may not be
							current
						</List.Item>
						<List.Item> May hallucinate "facts" </List.Item>
						<List.Item> May reflect harmful biases </List.Item>
					</List>
				</Flex>

				<Flex alignItems={'start'} flexDirection={'column'} gap="15px">
					<BodyText justify="start" size="small">
						<span style={{ fontWeight: 'bold' }}>
							Co-pilot, not auto-pilot!
						</span>{' '}
						You are responsible for evaluating and editing any content you will
						use. Always use AI for good and according to the{' '}
						<Link
							href={config.INTERNAL_POLICY_URL}
							target="_blank"
							rel="noreferrer"
						>
							policy.
						</Link>
					</BodyText>

					<BodyText size="small">
						This sandbox is a work in progress. Any feedback is welcome via{' '}
						<Link href={config.SLACK_URL} target="_blank" rel="noreferrer">
							#working-with-ai{' '}
						</Link>
					</BodyText>

					{llmId === 'mini-rag' ? (
						<FileUploader
							handleSubmit={handleSubmit}
							setForm={setForm}
							setInputPrompt={setInputPrompt}
						/>
					) : null}
					<Flex alignItems="center">
						<BodyText size="small">Chat with</BodyText>
						<LlmSelector
							setNewLlmValue={setNewLlmValue}
							setLlmConfig={setLlmConfig}
							setModes={setModes}
						/>
					</Flex>
				</Flex>
			</Flex>
		</Flex>
	);
};

export default IntroSection;
