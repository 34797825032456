import React, { cloneElement, ReactElement, useRef, useState } from 'react';
import { ChevronLeft8x13, ChevronRight8x13 } from '@bamboohr/grim';
import { Button } from '@fabric/button';
import { clsx } from '~styles';
import { Resizer, changeIndex, useResizer } from './utils';
import { useStyles } from './carousel.styles';
import { CarouselProps, CustomButton } from './types';
import { CarouselBase } from './carousel-base';
import { ifFeature } from '@bamboohr/utils/lib/feature';

const defaultNextButton = () => (
	<Button
		aria-label={window.jQuery ? $.__('Next') : 'Next'}
		color="secondary"
		icon={ifFeature('encore', 'chevron-right-solid', <ChevronRight8x13 />)}
		type="button"
		variant="outlined"
	/>
);

const defaultPrevButton = () => (
	<Button
		aria-label={window.jQuery ? $.__('Previous') : 'Previous'}
		color="secondary"
		icon={ifFeature('encore', 'chevron-left-solid', <ChevronLeft8x13 />)}
		type="button"
		variant="outlined"
	/>
);

export function Carousel({
	ariaLabelledBy,
	containerRole,
	nextButton = defaultNextButton(),
	prevButton = defaultPrevButton(),
	singlePageSlideAlignment,
	slideGap,
	slides,
	widthBreakpoints = { 0: 1 },
}: CarouselProps): ReactElement {
	const [currentIndex, setCurrentIndex] = useState(0);
	const [slidesToShow, setSlidesToShow] = useState(1);
	const carouselBaseRef = useRef<HTMLDivElement>(null);
	const isFirstPage = currentIndex <= 0;
	const isLastPage = currentIndex >= slides.length - slidesToShow;
	const isSinglePage = slides.length <= slidesToShow;
	const { classes } = useStyles({ isSinglePage });

	useResizer({
		elementRef: carouselBaseRef,
		breakpoints: widthBreakpoints,
		onResize: (numToShow: number) => {
			changeIndex({
				difference: 0,
				numSlides: slides.length,
				slidesToShow: numToShow,
				currentIndex,
				setCurrentIndex,
			});
			setSlidesToShow(numToShow);
		},
	});

	return (
		<div className={classes.wrapper}>
			{!isSinglePage && (
				<div className={clsx(classes.prevButton, classes.button, { [classes.hideButton]: isFirstPage })}>
					{cloneElement(prevButton, {
						onClick: evt => {
							changeIndex({
								difference: -slidesToShow,
								numSlides: slides.length,
								currentIndex,
								setCurrentIndex,
							});
							// preserve original onClick for custom buttons
							return (prevButton as CustomButton)?.props?.onClick?.(evt);
						},
						disabled: isFirstPage,
						tabIndex: isFirstPage ? -1 : 0,
					})}
				</div>
			)}
			{!isSinglePage && (
				<div className={clsx(classes.nextButton, classes.button, { [classes.hideButton]: isLastPage })}>
					{cloneElement(nextButton, {
						onClick: evt => {
							changeIndex({
								difference: slidesToShow,
								numSlides: slides.length,
								currentIndex,
								setCurrentIndex,
							});
							// preserve original onClick for custom buttons
							return (nextButton as CustomButton)?.props?.onClick?.(evt);
						},
						disabled: isLastPage,
						tabIndex: isLastPage ? -1 : 0,
					})}
				</div>
			)}

			<div className={classes.baseContainer}>
				<CarouselBase
					ariaLabelledBy={ariaLabelledBy}
					containerRef={carouselBaseRef}
					containerRole={containerRole}
					currentIndex={currentIndex}
					singlePageSlideAlignment={isSinglePage ? singlePageSlideAlignment : 'center'}
					slideGap={slideGap}
					slides={slides}
					slidesToShow={slidesToShow}
				/>
			</div>
		</div>
	);
}

Carousel.Base = CarouselBase;
Carousel.Resizer = Resizer;
Carousel.changeIndex = changeIndex;
Carousel.useResizer = useResizer;
